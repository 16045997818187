import React, { useState, useEffect, useRef } from 'react';

import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';

import ApiService from '../../../service/ApiService';
import ModifyKPI from './partials/ModifyKPI';
import { Toast } from 'primereact/toast';

const KPIManager = (props) => {
    const [dataValues, setDataValues] = useState(null);
    const [editKPIVisible, setEditKPIVisible] = useState(null);
    const [currentEditId, setCurrentEditId] = useState(null);
    const toast = useRef(null);

    useEffect(() => {
        const apiService = new ApiService();

        //This is from a generic JSON just an example
        apiService.getAllKpis().then((dataValues) => {
            setDataValues(dataValues);
        });

    }, []);

    const editData = (rowData) => {
        setCurrentEditId(rowData.id);
        setEditKPIVisible(true);
    };

    const editButton = (rowData) => {
        return <div className="editButton">
            <Button type="button" icon="pi pi-pencil" label="Edit" className="p-button p-button-outlined" onClick={() => editData(rowData)} />
        </div>;
    };

    const actionButtons = (rowData) => {
        return <>
            {editButton(rowData)}
        </>
    };

    const getYesNoButton = (flag) => {
        if (flag) {
            return <Button style={{ width: "60px", background: "#689F38", border: "none" }} icon="pi pi-check" />

        } else {
            return <Button style={{ width: "60px", background: "#D32F2F", border: "none" }} icon="pi pi-times" />
        }
    };


    const enabledTemplate = (rowData) => {
        return getYesNoButton(rowData.enabled);
    };

    const negativeKPITemplate = (rowData) => {
        return getYesNoButton(rowData.negativeKPI);
    };

    const refreshPage = () => {
        const apiService = new ApiService();

        //This is from a generic JSON just an example
        apiService.getAllKpis().then((dataValues) => {
            setDataValues(dataValues);
        });
    };

    const typeTemplate = (type) => {
        switch (type) {
            case "currency":
                return "£";
            case "percentage":
                return "%";
        }
        return "Number";
    };
    const dpTemplate = (dp) => {
        if (!dp) {
            return 0 + " D.P.";
        }
        return dp + " D.P.";
    };
    const dvFormatTemplate = (rowData) => {
        switch (rowData.actualValueFormat) {
            case ">":
                return "Actual Value > Over Value";
            case ">>":
                return "Actual Value >> Over Value";
            case "/":
                return "Actual Value / Over Value";
        }
        return "Actual Value";
    };
    const dvTypeTemplate = (rowData) => {
        return typeTemplate(rowData.actualValueType);
    };
    const dvDPTemplate = (rowData) => {
        return dpTemplate(rowData.actualValueDP);
    };
    const dTypeTemplate = (rowData) => {
        return typeTemplate(rowData.dialValueType);
    };
    const dDPTemplate = (rowData) => {
        return dpTemplate(rowData.dialValueDP);
    };


    return (
        <>
            <Toast ref={toast} position="top-left" />
            <div className="col-12">
                <div className="grid p-fluid">
                    <div className="card">
                        <div className="header-card">
                            <h3>Manage your KPI's</h3>

                            <div className="rhs-row-header">
                                <Button label="Help" className="p-button-outlined" />
                            </div>

                        </div>
                        <div className="dataValuesTable">

                            <div className="col-1 col-offset-11">
                                <div className="grid p-fluid">
                                    <Button label="Refresh" className="p-button-outlined" icon="pi pi-refresh" onClick={(e) => refreshPage()} />
                                </div>
                            </div>
                            <DataTable value={dataValues} tableStyle={{ minWidth: '50rem', width: '100%' }}>
                                <Column field="name" header="Display Name"></Column>
                                <Column field="tooltip" header="Tooltip"></Column>
                                <Column field="industryAverage" header="Industry Average"></Column>
                                <Column field="actualValueFormat" body={dvFormatTemplate} header="Display Value Format"></Column>
                                <Column field="actualValueType" body={dvTypeTemplate} header="Display Value Type"></Column>
                                <Column field="actualValueDP" body={dvDPTemplate} header="Display Value D.P"></Column>
                                <Column field="dialValueType" body={dTypeTemplate} header="Dial Value Type"></Column>
                                <Column field="dialValueDP" body={dDPTemplate} header="Dial Value D.P"></Column>
                                <Column field="enabled" body={enabledTemplate} header="Enabled"></Column>

                                <Column style={{ minWidth: '50px' }} body={actionButtons}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog header="Modify KPI" visible={editKPIVisible} style={{ width: '30vw', minWidth: '300px' }} onHide={() => setEditKPIVisible(false)}>
                <ModifyKPI id={currentEditId} refreshPage={refreshPage} setEditKPIVisible={setEditKPIVisible} toast={toast} />
            </Dialog>

        </>
    );
}

export default KPIManager;