import React, { useState, useEffect, useRef } from 'react';

import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { confirmDialog } from 'primereact/confirmdialog';

import ApiService from '../../../service/ApiService';

const Manager = (props) => {
    const [dataValues, setDataValues] = useState(null);

    useEffect(() => {
        const apiService = new ApiService();

        //This is from a generic JSON just an example
        apiService.getAllReports().then((dataValues) => {
            setDataValues(dataValues.reports);
        });

    }, []);

    const deleteButton = (rowData) => {
        return <div className="deleteButton">
            <Button type="button" icon="pi pi-times" label="Delete" className="p-button p-button-outlined" onClick={() => deleteData(rowData)} />
        </div>;
    };

    const deleteData = (data) => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => acceptFunc(data),
            reject
        });
    }

    const acceptFunc = async (rowData) => {
        var apiService = new ApiService();
        await apiService.deleteReport(rowData.reportId);
        refreshPage();
    };

    const reject = () => {
        return false;
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag value={rowData.status} severity={getSeverity(rowData.status)} />;
    };

    const getSeverity = (status) => {
        switch (status) {
            case 'UPLOAD FAILED':
            case 'DELETING':
                return 'danger';

            case 'UPLOAD SUCCESSFUL':
                return 'success';

            case 'PROCESSING DATA':
            case 'REUPLOADING':
                return 'warning';
        }
    };

    const refreshPage = () => {
        const apiService = new ApiService();

        //This is from a generic JSON just an example
        apiService.getAllReports().then((dataValues) => {
            setDataValues(dataValues.reports);
        });
    };

    useEffect(() => {
        const timer = setInterval(async () => {
            refreshPage();
        }, 10000);
        return () => clearInterval(timer);
    }, []);


    return (
        <>
            <div className="col-12">
                <div className="grid p-fluid">
                    <div className="card">
                        <div className="header-card">
                            <h3>Manage your reports</h3>

                            <div className="rhs-row-header" style={{ width: "unset" }}>
                                {props.canUploadReport ? <>
                                    <Button label="Add new report" icon="pi pi-plus" className="custombtn no-right-margin" onClick={(e) => props.routeUpload(e, "/components/pages/report-manager/addnewreport", "upload-report")} />
                                </> : <></>}
                            </div>

                        </div>
                        <div className="dataValuesTable">

                            <div className="col-1 col-offset-11">
                                <div className="grid p-fluid">
                                    <Button label="Refresh" className="p-button-outlined" icon="pi pi-refresh" onClick={(e) => refreshPage(e)} />
                                </div>
                            </div>
                            <DataTable value={dataValues} tableStyle={{ minWidth: '50rem', width: '100%' }}>
                                <Column field="reportName" header="Name"></Column>
                                <Column field="status" body={statusBodyTemplate} header="Status"></Column>
                                <Column field="dateRange" header="Range (start-end year)"></Column>
                                <Column field="recordsProcessed" header="Gift Processed"></Column>
                                <Column style={{ minWidth: '50px' }} body={deleteButton}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Manager;