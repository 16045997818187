import React, { useState, useEffect, useRef } from 'react';
import ApiService from "../../service/ApiService";
import { useCookies } from 'react-cookie';
import { Chart } from 'primereact/chart';

const AverageValueCustomChart = (props) => {

    const [data, setData] = useState(null);
    const [options, setOptions] = useState(null);
    const [ClientDataId] = useCookies(['ClientDataId']);

    var getData = () => {
        var apiService = new ApiService();
        apiService.getTrendDataFromReportForYear(ClientDataId, 18, 1, props.year).then(d => {
            d = d.splice(1);
            var labels = d.map(function (itm) {
                return itm.title.replace("Donors and Gifts ", "");
            });
            var values = d.map(function (itm) {
                return itm.items[0].value;
            });
            var dta = {
                labels: labels,
                datasets: [{
                    data: values,
                    backgroundColor: [
                        '#e9cfc0',
                        '#edc6ae',
                        '#efb693',
                        '#f79b63',
                        '#f17f39'
                    ]
                }]
            };
            
            setData(dta);
        });
    };

    useEffect(() => {
        setOptions({
            ...props.options,
            maintainAspectRatio: false,
            aspectRatio: 1,
        });
        getData();
    }, [props.year]);

    return (
        <Chart style={{ position: 'relative', width: '95%', height: "100%" }} options={options} type="doughnut" data={data} />
    );
}

export default AverageValueCustomChart;