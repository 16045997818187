import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';

const TableData = (props) => {
    return (
        <>

            <table className="p-datatable-table" role="table">
                <tbody className="p-datatable-tbody">
                    {props.data.rows.map((row, indexRow) => (
                        <tr role="row" key={"row_" + indexRow}>
                            {row.cells.map((cell, index) => (
                                <td role="cell" key={"row_" + indexRow + "cell_" + index} colSpan={cell.colSpan} className={row.cells.length == 1 && cell.colSpan > 1 ? "text-center" : ""}>
                                    <span key={"row_" + indexRow + "cell_" + index + "_sp"} className={cell.colour}>
                                        {row.isHeaderRow ? <strong>{cell.data}</strong> : <>{cell.data}</>}
                                    </span>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}

export default TableData;