import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'primereact/button';
import InputTextComponent from '../../../../partials/form/fields/InputTextComponent';
import PasswordComponent from '../../../../partials/form/fields/PasswordComponent';
import DropdownComponent from '../../../../partials/form/fields/DropdownComponent';
import ApiService from '../../../../service/ApiService';
import InputSwitchComponent from '../../../../partials/form/fields/InputSwitchComponent';
import InputTextareaComponent from '../../../../partials/form/fields/InputTextareaComponent';

const AddNewClient = (props) => {
    const [performValidation, setPerformValidation] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        organisationName: '',
        email: '',
        privilegeLevel: '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        maxUploadCount: '',
        userIsLockedOut: false,
        lockoutText: ''
    });

    const [formErrors, setFormErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        privilegeLevel: '',
        password: '',
        confirmPassword: '',
        organisationName: ''
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        setPerformValidation(true);
        if (validateForm()) {
            var apiService = new ApiService();
            if (!formData.edit) {
                apiService.createUser(formData).then((data) => {
                    if (data.success) {
                        props.refreshPage();
                        props.setAddClientVisible(false);
                        props.toast.current.show({ severity: 'success', summary: 'Client Created Successfully.', detail: 'A new client has been added.' });
                    } else {
                        props.toast.current.show({ severity: 'error', summary: 'Client Creation Failed.', detail: data.message });

                    }
                });
            } else {
                apiService.updateUser(formData).then((data) => {
                    if (data.success) {
                        props.refreshPage();
                        props.setAddClientVisible(false);
                        props.toast.current.show({ severity: 'success', summary: 'Client Updated Successfully.', detail: '' });
                    } else {
                        props.toast.current.show({ severity: 'error', summary: 'Client Update Failed.', detail: data.message });

                    }
                });

            }
        }
    };

    useEffect(() => {
        if (props.edit == true && props.id) {
            var apiService = new ApiService();
            apiService.getUser(props.id).then((data) => {
                var d = { ...data, edit: true };
                setFormData(d);
            });
        }
    }, [props.id]);

    useEffect(() => {
        if (performValidation) {
            validateForm();
        }

    }, [performValidation, formData]);

    const validateForm = () => {
        let isValid = true;
        const errors = {
            firstName: '',
            lastName: '',
            email: '',
            privilegeLevel: '',
            password: '',
            confirmPassword: '',
            organisationName: ''
        };

        if (!formData.organisationName || formData.organisationName.trim() === '') {
            isValid = false;
            errors.organisationName = 'Organisation Name is required';
        }
        if (!formData.firstName || formData.firstName.trim() === '') {
            isValid = false;
            errors.firstName = 'First Name is required';
        }
        if (!formData.lastName || formData.lastName.trim() === '') {
            isValid = false;
            errors.lastName = 'Last Name is required';
        }
        if (!formData.email || formData.email.trim() === '') {
            isValid = false;
            errors.email = 'Email is required';
        }
        if (!formData.edit || (formData.password && formData.password.trim() !== '') || (formData.confirmPassword && formData.confirmPassword.trim() !== '')) {
            if (!formData.password || formData.password.trim() === '') {
                isValid = false;
                errors.password = 'Password is required';
            }
            if (!formData.confirmPassword || formData.confirmPassword.trim() === '') {
                isValid = false;
                errors.confirmPassword = 'Confirm Password is required';
            }
            if (formData.password !== formData.confirmPassword) {
                isValid = false;
                errors.password = 'Passwords do not match';
                errors.confirmPassword = 'Passwords do not match';
            }
        }

        setFormErrors(errors);
        return isValid;
    };

    const privLevels = [
        { name: 'None', code: '' },
        { name: 'Global Admin', code: 'GLOBAL_ADMIN' }
    ];

    const passwordRequired = !formData.edit || (formData.password && formData.password.trim() !== '') || (formData.confirmPassword && formData.confirmPassword.trim() !== '');

    const inputChanged = (value, field) => {
        var d = { ...formData };
        d[field] = value;
        setFormData(d);
    };

    return (
        <>
            <form onSubmit={handleSubmit} className="p-fluid custom-form">
                <h6>General</h6>
                <hr />
                <InputTextComponent
                    id="organisationName"
                    value={formData.organisationName}
                    onChange={(value) => inputChanged(value, "organisationName")}
                    aria-describedby="organisationName-help"
                    className={formErrors.organisationName ? "p-invalid block" : "block"}
                    required
                />
                {formErrors.organisationName && <small id="organisationName-help" className="p-error block">{formErrors.organisationName}</small>}

                <InputTextComponent
                    id="firstName"
                    value={formData.firstName}
                    onChange={(value) => inputChanged(value, "firstName")}
                    aria-describedby="firstName-help"
                    className={formErrors.firstName ? "p-invalid block" : "block"}
                    required
                />
                {formErrors.firstName && <small id="firstName-help" className="p-error block">{formErrors.firstName}</small>}

                <InputTextComponent
                    id="lastName"
                    value={formData.lastName}
                    onChange={(value) => inputChanged(value, "lastName")}
                    aria-describedby="lastName-help"
                    className={formErrors.lastName ? "p-invalid block" : "block"}
                    required
                />
                {formErrors.lastName && <small id="lastName-help" className="p-error block">{formErrors.lastName}</small>}

                <InputTextComponent
                    id="email"
                    value={formData.email}
                    onChange={(value) => inputChanged(value, "email")}
                    aria-describedby="email-help"
                    className={formErrors.email ? "p-invalid block" : "block"}
                    required
                />
                {formErrors.email && <small id="email-help" className="p-error block">{formErrors.email}</small>}

                <InputTextComponent
                    id="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={(value) => inputChanged(value, "phoneNumber")}
                    aria-describedby="phoneNumber-help"
                    className="block"
                />

                {formErrors.phoneNumber && <small id="phoneNumber-help" className="p-error block">{formErrors.phoneNumber}</small>}

                <h6>Password</h6>
                <hr />
                <PasswordComponent
                    id="password"
                    value={formData.password}
                    onChange={(value) => inputChanged(value, "password")}
                    passwordRequired={passwordRequired}
                    formErrors={formErrors}
                    autoComplete="new-password"
                />

                <PasswordComponent
                    id="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={(value) => inputChanged(value, "confirmPassword")}
                    passwordRequired={passwordRequired}
                    formErrors={formErrors}
                    autoComplete="new-password"
                />

                <h6>Permissions</h6>
                <hr />
                <InputTextComponent
                    id="maxUploadCount"
                    value={formData.maxUploadCount}
                    onChange={(value) => inputChanged(value, "maxUploadCount")}
                    aria-describedby="maxUploadCount-help"
                    type="number"
                    className="block"
                />

                <DropdownComponent
                    id="privilegeLevel"
                    value={formData.privilegeLevel}
                    options={privLevels}
                    onChange={(value) => inputChanged(value, "privilegeLevel")}
                    aria-describedby="privilegeLevel-help"
                    className={formErrors.privilegeLevel ? "p-invalid" : ""}
                />

                {formErrors.privilegeLevel && <small id="privilegeLevel-help" className="p-error block">{formErrors.privilegeLevel}</small>}

                <InputSwitchComponent
                    id="userIsLockedOut"
                    checked={formData.userIsLockedOut}
                    onChange={(value) => inputChanged(value, "userIsLockedOut")}
                    aria-describedby="userIsLockedOut-help"
                />

                <InputTextareaComponent
                    id="lockoutText"
                    value={formData.lockoutText}
                    onChange={(value) => inputChanged(value, "lockoutText")}
                    aria-describedby="lockoutText-help"
                    className="block"
                />


                <div className="field grid">
                    <div className="col-fixed" style={{ width: '150px' }}></div>
                    <div className="input" style={{ flex: '1' }}>
                        <Button type="submit" label="Submit" className="mt-2 mb-2 btn-custom" style={{ width: '150px', float: 'right' }} />
                    </div>
                </div>
            </form>
        </>
    );
};

export default AddNewClient;