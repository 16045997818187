import { Button } from 'primereact/button';

const StatPanel = (props) => {

    return (
        <div className="surface-card shadow-2 p-3 border-round" style={{ height: '100%' }} >
            <div className="flex justify-content-between mb-3">
                <div>
                    <span className="block text-500 font-medium mb-3">{props.data.tableName}</span>
                    <div className={"text-900 font-medium text-xl " + props.data.valueClass}>{props.data.value}</div>
                </div>
                {props.data.showReset ?
                    <div>
                        <Button label="Reset" className="p-button-outlined" onClick={(e) => props.resetTable(e)} />
                    </div>
                    :
                    <div className={"flex align-items-center justify-content-center border-round " + props.data.iconBgClass} style={{ width: '2.5rem', height: '2.5rem' }}>
                        <i className={props.data.icon + " pi"}></i>
                    </div>}
            </div>
            {props.data.showIncrease ?
                <span className={"font-medium " + props.data.increaseClass}>{props.data.increase}</span>
                : <></>}
        </div>
    );
};

export default StatPanel;