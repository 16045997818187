import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import ApiService from '../../../../service/ApiService';
import { SelectButton } from 'primereact/selectbutton';


import  InputTextComponent  from '../../../../partials/form/fields/InputTextComponent';
import  InputTextareaComponent  from '../../../../partials/form/fields/InputTextareaComponent';
import  InputSwitchComponent  from '../../../../partials/form/fields/InputSwitchComponent';
import  DropdownComponent  from '../../../../partials/form/fields/DropdownComponent';
import  ColorPickerComponent  from '../../../../partials/form/fields/ColorPickerComponent';



const ModifyKPIForm = (props) => {
    const [performValidation, setPerformValidation] = useState(false);
    const [altTab, setAltTab] = useState(false);
    const [formData, setFormData] = useState({
        id: '',
        tooltip: '',
        chartName: '',
        name: '',
        industryAverage: '',
        negativeKPI: false,
        enabled: false,
        displaySlider: false,
        sliderMin: '',
        sliderMax: '',
        lowerBand: '',
        upperBand: '',
        actualValueType: '',
        actualValueFormat: '',
        actualValueFormatDashboard: '',
        actualValueDP: 0,
        dialValueType: '',
        dialValueDP: 0,
        insight: '',
        lineChartColour: '#B2BEB5',
        annualComparisonType: '',
        industryComparisonType: '',
        subKPI1Icon: '',
        subKPI2Icon: '',
        altSubKPI1Icon: '',
        altSubKPI2Icon: '',


        altDialValueDP: 0,
        altActualValueDP: 0,
        altActualValueFormat: '',
        altActualValueFormatDashboard: '',
        altActualValueType: '',
        altChartName: '',
        altDialValueType: '',
        altEnabled: false,
        altTooltip: '',
        altLowerBand: '',
        altUpperBand: '',
        altInsight: '',

        altAnnualComparisonType: '',
        altIndustryComparisonType: '',
        altIndustryValue: '',
    });
    const [formErrors, setFormErrors] = useState({
        name: ''
    });


    useEffect(() => {
        if (props.id) {
            var apiService = new ApiService();
            apiService.getKpi(props.id).then((data) => {
                var d = { ...data };
                if (d.dialValueType == null) {
                    d.dialValueType = '';
                }
                if (d.actualValueType == null) {
                    d.actualValueType = '';
                }
                if (d.actualValueFormat == null) {
                    d.actualValueFormat = '';
                }
                if (d.annualComparisonType == null) {
                    d.annualComparisonType = '';
                }
                if (d.industryComparisonType == null) {
                    d.industryComparisonType = '';
                }
                if (d.actualValueFormatDashboard == null) {
                    d.actualValueFormatDashboard = '';
                }
                if (d.lineChartColour == null) {
                    d.lineChartColour = '#B2BEB5';
                }

                if (d.altDialValueType == null) {
                    d.altDialValueType = '';
                }
                if (d.altActualValueType == null) {
                    d.altActualValueType = '';
                }
                if (d.altActualValueFormat == null) {
                    d.altActualValueFormat = '';
                }
                if (d.altActualValueFormatDashboard == null) {
                    d.altActualValueFormatDashboard = '';
                }
                if (d.insight == null) {
                    d.insight = '';
                }
                if (d.altInsight == null) {
                    d.altInsight = '';
                }
                if (d.altAnnualComparisonType == null) {
                    d.altAnnualComparisonType = '';
                }
                if (d.altIndustryComparisonType == null) {
                    d.altIndustryComparisonType = '';
                }
                setFormData(d);
            });
        }
    }, [props.id]);

    useEffect(() => {
        if (performValidation) {
            validateForm();
        }

    }, [performValidation, formData]);


    const handleSubmit = (event) => {
        event.preventDefault();
        setPerformValidation(true);
        if (validateForm()) {
            var apiService = new ApiService();

            var d = { ...formData };
            if (!formData.industryAverage || formData.industryAverage === '') {
                delete d["industryAverage"];
                setFormData(d);
            }
            if (!formData.lowerBand || formData.lowerBand === '') {
                delete d["lowerBand"];
                setFormData(d);
            }
            if (!formData.upperBand || formData.upperBand === '') {
                delete d["upperBand"];
                setFormData(d);
            }
            if (!formData.sliderMax || formData.sliderMax === '') {
                delete d["sliderMax"];
                setFormData(d);
            }
            if (!formData.sliderMin || formData.sliderMin === '') {
                delete d["sliderMin"];
                setFormData(d);
            }
            if (!formData.actualValueDP || formData.actualValueDP === '') {
                delete d["actualValueDP"];
                setFormData(d);
            }
            if (!formData.dialValueDP || formData.dialValueDP === '') {
                delete d["dialValueDP"];
                setFormData(d);
            }
            if (!formData.lineChartColour || formData.lineChartColour === '') {
                delete d["lineChartColour"];
                setFormData(d);
            }
            if (!formData.subKPI1Icon || formData.subKPI1Icon === '') {
                delete d["subKPI1Icon"];
                setFormData(d);
            }
            if (!formData.subKPI2Icon || formData.subKPI2Icon === '') {
                delete d["subKPI2Icon"];
                setFormData(d);
            }

            if (!formData.altLowerBand || formData.altLowerBand === '') {
                delete d["altLowerBand"];
                setFormData(d);
            }
            if (!formData.altUpperBand || formData.altUpperBand === '') {
                delete d["altUpperBand"];
                setFormData(d);
            }
            if (!formData.altActualValueDP || formData.altActualValueDP === '') {
                delete d["altActualValueDP"];
                setFormData(d);
            }
            if (!formData.altDialValueDP || formData.altDialValueDP === '') {
                delete d["altDialValueDP"];
                setFormData(d);
            }
            if (!formData.annualComparisonType || formData.annualComparisonType === '') {
                delete d["annualComparisonType"];
                setFormData(d);
            }
            if (!formData.industryComparisonType || formData.industryComparisonType === '') {
                delete d["industryComparisonType"];
                setFormData(d);
            }
            if (!formData.altIndustryComparisonType || formData.altIndustryComparisonType === '') {
                delete d["altIndustryComparisonType"];
                setFormData(d);
            }
            if (!formData.altAnnualComparisonType || formData.altAnnualComparisonType === '') {
                delete d["altAnnualComparisonType"];
                setFormData(d);
            }
            if (!formData.altIndustryValue || formData.altIndustryValue === '') {
                delete d["altIndustryValue"];
                setFormData(d);
            }
            if (!formData.altSubKPI1Icon || formData.altSubKPI1Icon === '') {
                delete d["altSubKPI1Icon"];
                setFormData(d);
            }
            if (!formData.altSubKPI2Icon || formData.altSubKPI2Icon === '') {
                delete d["altSubKPI2Icon"];
                setFormData(d);
            }



            apiService.updateKpi(d).then((data) => {
                if (data.success) {
                    props.refreshPage();
                    props.setEditKPIVisible(false);
                    props.toast.current.show({ severity: 'success', summary: 'KPI Updated Successfully.', detail: '' });
                } else {
                    props.toast.current.show({ severity: 'error', summary: 'KPI Update Failed.', detail: data.message });
                }
            });
        }
    };

    const inputChanged = (value, field) => {
        var d = { ...formData };
        d[field] = value;
        setFormData(d);
    };

    const validateForm = () => {
        let isValid = true;
        const errors = {
            name: ''
        };

        if (!formData.name || formData.name.trim() === '') {
            isValid = false;
            errors.firstName = 'Display Name is required';
        }

        setFormErrors(errors);
        return isValid;
    };

    const valueDisplayTypes = [
        { name: 'Number', code: '' },
        { name: 'Currency', code: 'currency' },
        { name: 'Percentage', code: 'percentage' }
    ];

    const valueFormats = [
        { name: 'Actual Value', code: '' },
        { name: 'Actual Value >> Over Value', code: '>>' },
        { name: 'Actual Value > Over Value', code: '>' },
        { name: 'Actual Value / Over Value', code: '/' }
    ];

    const comparisonTypes = [
        { name: 'Difference', code: '' },
        { name: 'Increase', code: 'INC' }
    ];

    const iconList = [
        {
            "name": "Please select...",
            "code": ""
        },
        {
            "name": "pi-align-center",
            "code": "pi-align-center"
        },
        {
            "name": "pi-align-justify",
            "code": "pi-align-justify"
        },
        {
            "name": "pi-align-left",
            "code": "pi-align-left"
        },
        {
            "name": "pi-align-right",
            "code": "pi-align-right"
        },
        {
            "name": "pi-amazon",
            "code": "pi-amazon"
        },
        {
            "name": "pi-android",
            "code": "pi-android"
        },
        {
            "name": "pi-angle-double-down",
            "code": "pi-angle-double-down"
        },
        {
            "name": "pi-angle-double-left",
            "code": "pi-angle-double-left"
        },
        {
            "name": "pi-angle-double-right",
            "code": "pi-angle-double-right"
        },
        {
            "name": "pi-angle-double-up",
            "code": "pi-angle-double-up"
        },
        {
            "name": "pi-angle-down",
            "code": "pi-angle-down"
        },
        {
            "name": "pi-angle-left",
            "code": "pi-angle-left"
        },
        {
            "name": "pi-angle-right",
            "code": "pi-angle-right"
        },
        {
            "name": "pi-angle-up",
            "code": "pi-angle-up"
        },
        {
            "name": "pi-apple",
            "code": "pi-apple"
        },
        {
            "name": "pi-arrow-circle-down",
            "code": "pi-arrow-circle-down"
        },
        {
            "name": "pi-arrow-circle-left",
            "code": "pi-arrow-circle-left"
        },
        {
            "name": "pi-arrow-circle-right",
            "code": "pi-arrow-circle-right"
        },
        {
            "name": "pi-arrow-circle-up",
            "code": "pi-arrow-circle-up"
        },
        {
            "name": "pi-arrow-down",
            "code": "pi-arrow-down"
        },
        {
            "name": "pi-arrow-down-left",
            "code": "pi-arrow-down-left"
        },
        {
            "name": "pi-arrow-down-right",
            "code": "pi-arrow-down-right"
        },
        {
            "name": "pi-arrow-left",
            "code": "pi-arrow-left"
        },
        {
            "name": "pi-arrow-right",
            "code": "pi-arrow-right"
        },
        {
            "name": "pi-arrow-right-arrow-left",
            "code": "pi-arrow-right-arrow-left"
        },
        {
            "name": "pi-arrow-up",
            "code": "pi-arrow-up"
        },
        {
            "name": "pi-arrow-up-left",
            "code": "pi-arrow-up-left"
        },
        {
            "name": "pi-arrow-up-right",
            "code": "pi-arrow-up-right"
        },
        {
            "name": "pi-arrows-alt",
            "code": "pi-arrows-alt"
        },
        {
            "name": "pi-arrows-h",
            "code": "pi-arrows-h"
        },
        {
            "name": "pi-arrows-v",
            "code": "pi-arrows-v"
        },
        {
            "name": "pi-at",
            "code": "pi-at"
        },
        {
            "name": "pi-backward",
            "code": "pi-backward"
        },
        {
            "name": "pi-ban",
            "code": "pi-ban"
        },
        {
            "name": "pi-bars",
            "code": "pi-bars"
        },
        {
            "name": "pi-bell",
            "code": "pi-bell"
        },
        {
            "name": "pi-bitcoin",
            "code": "pi-bitcoin"
        },
        {
            "name": "pi-bolt",
            "code": "pi-bolt"
        },
        {
            "name": "pi-book",
            "code": "pi-book"
        },
        {
            "name": "pi-bookmark",
            "code": "pi-bookmark"
        },
        {
            "name": "pi-bookmark-fill",
            "code": "pi-bookmark-fill"
        },
        {
            "name": "pi-box",
            "code": "pi-box"
        },
        {
            "name": "pi-briefcase",
            "code": "pi-briefcase"
        },
        {
            "name": "pi-building",
            "code": "pi-building"
        },
        {
            "name": "pi-calculator",
            "code": "pi-calculator"
        },
        {
            "name": "pi-calendar",
            "code": "pi-calendar"
        },
        {
            "name": "pi-calendar-minus",
            "code": "pi-calendar-minus"
        },
        {
            "name": "pi-calendar-plus",
            "code": "pi-calendar-plus"
        },
        {
            "name": "pi-calendar-times",
            "code": "pi-calendar-times"
        },
        {
            "name": "pi-camera",
            "code": "pi-camera"
        },
        {
            "name": "pi-car",
            "code": "pi-car"
        },
        {
            "name": "pi-caret-down",
            "code": "pi-caret-down"
        },
        {
            "name": "pi-caret-left",
            "code": "pi-caret-left"
        },
        {
            "name": "pi-caret-right",
            "code": "pi-caret-right"
        },
        {
            "name": "pi-caret-up",
            "code": "pi-caret-up"
        },
        {
            "name": "pi-cart-plus",
            "code": "pi-cart-plus"
        },
        {
            "name": "pi-chart-bar",
            "code": "pi-chart-bar"
        },
        {
            "name": "pi-chart-line",
            "code": "pi-chart-line"
        },
        {
            "name": "pi-chart-pie",
            "code": "pi-chart-pie"
        },
        {
            "name": "pi-check",
            "code": "pi-check"
        },
        {
            "name": "pi-check-circle",
            "code": "pi-check-circle"
        },
        {
            "name": "pi-check-square",
            "code": "pi-check-square"
        },
        {
            "name": "pi-chevron-circle-down",
            "code": "pi-chevron-circle-down"
        },
        {
            "name": "pi-chevron-circle-left",
            "code": "pi-chevron-circle-left"
        },
        {
            "name": "pi-chevron-circle-right",
            "code": "pi-chevron-circle-right"
        },
        {
            "name": "pi-chevron-circle-up",
            "code": "pi-chevron-circle-up"
        },
        {
            "name": "pi-chevron-down",
            "code": "pi-chevron-down"
        },
        {
            "name": "pi-chevron-left",
            "code": "pi-chevron-left"
        },
        {
            "name": "pi-chevron-right",
            "code": "pi-chevron-right"
        },
        {
            "name": "pi-chevron-up",
            "code": "pi-chevron-up"
        },
        {
            "name": "pi-circle",
            "code": "pi-circle"
        },
        {
            "name": "pi-circle-fill",
            "code": "pi-circle-fill"
        },
        {
            "name": "pi-clock",
            "code": "pi-clock"
        },
        {
            "name": "pi-clone",
            "code": "pi-clone"
        },
        {
            "name": "pi-cloud",
            "code": "pi-cloud"
        },
        {
            "name": "pi-cloud-download",
            "code": "pi-cloud-download"
        },
        {
            "name": "pi-cloud-upload",
            "code": "pi-cloud-upload"
        },
        {
            "name": "pi-code",
            "code": "pi-code"
        },
        {
            "name": "pi-cog",
            "code": "pi-cog"
        },
        {
            "name": "pi-comment",
            "code": "pi-comment"
        },
        {
            "name": "pi-comments",
            "code": "pi-comments"
        },
        {
            "name": "pi-compass",
            "code": "pi-compass"
        },
        {
            "name": "pi-copy",
            "code": "pi-copy"
        },
        {
            "name": "pi-credit-card",
            "code": "pi-credit-card"
        },
        {
            "name": "pi-database",
            "code": "pi-database"
        },
        {
            "name": "pi-delete-left",
            "code": "pi-delete-left"
        },
        {
            "name": "pi-desktop",
            "code": "pi-desktop"
        },
        {
            "name": "pi-directions",
            "code": "pi-directions"
        },
        {
            "name": "pi-directions-alt",
            "code": "pi-directions-alt"
        },
        {
            "name": "pi-discord",
            "code": "pi-discord"
        },
        {
            "name": "pi-dollar",
            "code": "pi-dollar"
        },
        {
            "name": "pi-download",
            "code": "pi-download"
        },
        {
            "name": "pi-eject",
            "code": "pi-eject"
        },
        {
            "name": "pi-ellipsis-h",
            "code": "pi-ellipsis-h"
        },
        {
            "name": "pi-ellipsis-v",
            "code": "pi-ellipsis-v"
        },
        {
            "name": "pi-envelope",
            "code": "pi-envelope"
        },
        {
            "name": "pi-eraser",
            "code": "pi-eraser"
        },
        {
            "name": "pi-euro",
            "code": "pi-euro"
        },
        {
            "name": "pi-exclamation-circle",
            "code": "pi-exclamation-circle"
        },
        {
            "name": "pi-exclamation-triangle",
            "code": "pi-exclamation-triangle"
        },
        {
            "name": "pi-external-link",
            "code": "pi-external-link"
        },
        {
            "name": "pi-eye",
            "code": "pi-eye"
        },
        {
            "name": "pi-eye-slash",
            "code": "pi-eye-slash"
        },
        {
            "name": "pi-facebook",
            "code": "pi-facebook"
        },
        {
            "name": "pi-fast-backward",
            "code": "pi-fast-backward"
        },
        {
            "name": "pi-fast-forward",
            "code": "pi-fast-forward"
        },
        {
            "name": "pi-file",
            "code": "pi-file"
        },
        {
            "name": "pi-file-edit",
            "code": "pi-file-edit"
        },
        {
            "name": "pi-file-excel",
            "code": "pi-file-excel"
        },
        {
            "name": "pi-file-export",
            "code": "pi-file-export"
        },
        {
            "name": "pi-file-import",
            "code": "pi-file-import"
        },
        {
            "name": "pi-file-pdf",
            "code": "pi-file-pdf"
        },
        {
            "name": "pi-file-word",
            "code": "pi-file-word"
        },
        {
            "name": "pi-filter",
            "code": "pi-filter"
        },
        {
            "name": "pi-filter-fill",
            "code": "pi-filter-fill"
        },
        {
            "name": "pi-filter-slash",
            "code": "pi-filter-slash"
        },
        {
            "name": "pi-flag",
            "code": "pi-flag"
        },
        {
            "name": "pi-flag-fill",
            "code": "pi-flag-fill"
        },
        {
            "name": "pi-folder",
            "code": "pi-folder"
        },
        {
            "name": "pi-folder-open",
            "code": "pi-folder-open"
        },
        {
            "name": "pi-forward",
            "code": "pi-forward"
        },
        {
            "name": "pi-gift",
            "code": "pi-gift"
        },
        {
            "name": "pi-github",
            "code": "pi-github"
        },
        {
            "name": "pi-globe",
            "code": "pi-globe"
        },
        {
            "name": "pi-google",
            "code": "pi-google"
        },
        {
            "name": "pi-hashtag",
            "code": "pi-hashtag"
        },
        {
            "name": "pi-heart",
            "code": "pi-heart"
        },
        {
            "name": "pi-heart-fill",
            "code": "pi-heart-fill"
        },
        {
            "name": "pi-history",
            "code": "pi-history"
        },
        {
            "name": "pi-home",
            "code": "pi-home"
        },
        {
            "name": "pi-hourglass",
            "code": "pi-hourglass"
        },
        {
            "name": "pi-id-card",
            "code": "pi-id-card"
        },
        {
            "name": "pi-image",
            "code": "pi-image"
        },
        {
            "name": "pi-images",
            "code": "pi-images"
        },
        {
            "name": "pi-inbox",
            "code": "pi-inbox"
        },
        {
            "name": "pi-info",
            "code": "pi-info"
        },
        {
            "name": "pi-info-circle",
            "code": "pi-info-circle"
        },
        {
            "name": "pi-instagram",
            "code": "pi-instagram"
        },
        {
            "name": "pi-key",
            "code": "pi-key"
        },
        {
            "name": "pi-language",
            "code": "pi-language"
        },
        {
            "name": "pi-link",
            "code": "pi-link"
        },
        {
            "name": "pi-linkedin",
            "code": "pi-linkedin"
        },
        {
            "name": "pi-list",
            "code": "pi-list"
        },
        {
            "name": "pi-lock",
            "code": "pi-lock"
        },
        {
            "name": "pi-lock-open",
            "code": "pi-lock-open"
        },
        {
            "name": "pi-map",
            "code": "pi-map"
        },
        {
            "name": "pi-map-marker",
            "code": "pi-map-marker"
        },
        {
            "name": "pi-megaphone",
            "code": "pi-megaphone"
        },
        {
            "name": "pi-microphone",
            "code": "pi-microphone"
        },
        {
            "name": "pi-microsoft",
            "code": "pi-microsoft"
        },
        {
            "name": "pi-minus",
            "code": "pi-minus"
        },
        {
            "name": "pi-minus-circle",
            "code": "pi-minus-circle"
        },
        {
            "name": "pi-mobile",
            "code": "pi-mobile"
        },
        {
            "name": "pi-money-bill",
            "code": "pi-money-bill"
        },
        {
            "name": "pi-moon",
            "code": "pi-moon"
        },
        {
            "name": "pi-palette",
            "code": "pi-palette"
        },
        {
            "name": "pi-paperclip",
            "code": "pi-paperclip"
        },
        {
            "name": "pi-pause",
            "code": "pi-pause"
        },
        {
            "name": "pi-paypal",
            "code": "pi-paypal"
        },
        {
            "name": "pi-pencil",
            "code": "pi-pencil"
        },
        {
            "name": "pi-percentage",
            "code": "pi-percentage"
        },
        {
            "name": "pi-phone",
            "code": "pi-phone"
        },
        {
            "name": "pi-play",
            "code": "pi-play"
        },
        {
            "name": "pi-plus",
            "code": "pi-plus"
        },
        {
            "name": "pi-plus-circle",
            "code": "pi-plus-circle"
        },
        {
            "name": "pi-pound",
            "code": "pi-pound"
        },
        {
            "name": "pi-power-off",
            "code": "pi-power-off"
        },
        {
            "name": "pi-prime",
            "code": "pi-prime"
        },
        {
            "name": "pi-print",
            "code": "pi-print"
        },
        {
            "name": "pi-qrcode",
            "code": "pi-qrcode"
        },
        {
            "name": "pi-question",
            "code": "pi-question"
        },
        {
            "name": "pi-question-circle",
            "code": "pi-question-circle"
        },
        {
            "name": "pi-reddit",
            "code": "pi-reddit"
        },
        {
            "name": "pi-refresh",
            "code": "pi-refresh"
        },
        {
            "name": "pi-replay",
            "code": "pi-replay"
        },
        {
            "name": "pi-reply",
            "code": "pi-reply"
        },
        {
            "name": "pi-save",
            "code": "pi-save"
        },
        {
            "name": "pi-search",
            "code": "pi-search"
        },
        {
            "name": "pi-search-minus",
            "code": "pi-search-minus"
        },
        {
            "name": "pi-search-plus",
            "code": "pi-search-plus"
        },
        {
            "name": "pi-send",
            "code": "pi-send"
        },
        {
            "name": "pi-server",
            "code": "pi-server"
        },
        {
            "name": "pi-share-alt",
            "code": "pi-share-alt"
        },
        {
            "name": "pi-shield",
            "code": "pi-shield"
        },
        {
            "name": "pi-shopping-bag",
            "code": "pi-shopping-bag"
        },
        {
            "name": "pi-shopping-cart",
            "code": "pi-shopping-cart"
        },
        {
            "name": "pi-sign-in",
            "code": "pi-sign-in"
        },
        {
            "name": "pi-sign-out",
            "code": "pi-sign-out"
        },
        {
            "name": "pi-sitemap",
            "code": "pi-sitemap"
        },
        {
            "name": "pi-slack",
            "code": "pi-slack"
        },
        {
            "name": "pi-sliders-h",
            "code": "pi-sliders-h"
        },
        {
            "name": "pi-sliders-v",
            "code": "pi-sliders-v"
        },
        {
            "name": "pi-sort",
            "code": "pi-sort"
        },
        {
            "name": "pi-sort-alpha-down",
            "code": "pi-sort-alpha-down"
        },
        {
            "name": "pi-sort-alpha-down-alt",
            "code": "pi-sort-alpha-down-alt"
        },
        {
            "name": "pi-sort-alpha-up",
            "code": "pi-sort-alpha-up"
        },
        {
            "name": "pi-sort-alpha-up-alt",
            "code": "pi-sort-alpha-up-alt"
        },
        {
            "name": "pi-sort-alt",
            "code": "pi-sort-alt"
        },
        {
            "name": "pi-sort-alt-slash",
            "code": "pi-sort-alt-slash"
        },
        {
            "name": "pi-sort-amount-down",
            "code": "pi-sort-amount-down"
        },
        {
            "name": "pi-sort-amount-down-alt",
            "code": "pi-sort-amount-down-alt"
        },
        {
            "name": "pi-sort-amount-up",
            "code": "pi-sort-amount-up"
        },
        {
            "name": "pi-sort-amount-up-alt",
            "code": "pi-sort-amount-up-alt"
        },
        {
            "name": "pi-sort-down",
            "code": "pi-sort-down"
        },
        {
            "name": "pi-sort-numeric-down",
            "code": "pi-sort-numeric-down"
        },
        {
            "name": "pi-sort-numeric-down-alt",
            "code": "pi-sort-numeric-down-alt"
        },
        {
            "name": "pi-sort-numeric-up",
            "code": "pi-sort-numeric-up"
        },
        {
            "name": "pi-sort-numeric-up-alt",
            "code": "pi-sort-numeric-up-alt"
        },
        {
            "name": "pi-sort-up",
            "code": "pi-sort-up"
        },
        {
            "name": "pi-spinner",
            "code": "pi-spinner"
        },
        {
            "name": "pi-star",
            "code": "pi-star"
        },
        {
            "name": "pi-star-fill",
            "code": "pi-star-fill"
        },
        {
            "name": "pi-step-backward",
            "code": "pi-step-backward"
        },
        {
            "name": "pi-step-backward-alt",
            "code": "pi-step-backward-alt"
        },
        {
            "name": "pi-step-forward",
            "code": "pi-step-forward"
        },
        {
            "name": "pi-step-forward-alt",
            "code": "pi-step-forward-alt"
        },
        {
            "name": "pi-stop",
            "code": "pi-stop"
        },
        {
            "name": "pi-stop-circle",
            "code": "pi-stop-circle"
        },
        {
            "name": "pi-stopwatch",
            "code": "pi-stopwatch"
        },
        {
            "name": "pi-sun",
            "code": "pi-sun"
        },
        {
            "name": "pi-sync",
            "code": "pi-sync"
        },
        {
            "name": "pi-table",
            "code": "pi-table"
        },
        {
            "name": "pi-tablet",
            "code": "pi-tablet"
        },
        {
            "name": "pi-tag",
            "code": "pi-tag"
        },
        {
            "name": "pi-tags",
            "code": "pi-tags"
        },
        {
            "name": "pi-telegram",
            "code": "pi-telegram"
        },
        {
            "name": "pi-th-large",
            "code": "pi-th-large"
        },
        {
            "name": "pi-thumbs-down",
            "code": "pi-thumbs-down"
        },
        {
            "name": "pi-thumbs-down-fill",
            "code": "pi-thumbs-down-fill"
        },
        {
            "name": "pi-thumbs-up",
            "code": "pi-thumbs-up"
        },
        {
            "name": "pi-thumbs-up-fill",
            "code": "pi-thumbs-up-fill"
        },
        {
            "name": "pi-ticket",
            "code": "pi-ticket"
        },
        {
            "name": "pi-times",
            "code": "pi-times"
        },
        {
            "name": "pi-times-circle",
            "code": "pi-times-circle"
        },
        {
            "name": "pi-trash",
            "code": "pi-trash"
        },
        {
            "name": "pi-truck",
            "code": "pi-truck"
        },
        {
            "name": "pi-twitter",
            "code": "pi-twitter"
        },
        {
            "name": "pi-undo",
            "code": "pi-undo"
        },
        {
            "name": "pi-unlock",
            "code": "pi-unlock"
        },
        {
            "name": "pi-upload",
            "code": "pi-upload"
        },
        {
            "name": "pi-user",
            "code": "pi-user"
        },
        {
            "name": "pi-user-edit",
            "code": "pi-user-edit"
        },
        {
            "name": "pi-user-minus",
            "code": "pi-user-minus"
        },
        {
            "name": "pi-user-plus",
            "code": "pi-user-plus"
        },
        {
            "name": "pi-users",
            "code": "pi-users"
        },
        {
            "name": "pi-verified",
            "code": "pi-verified"
        },
        {
            "name": "pi-video",
            "code": "pi-video"
        },
        {
            "name": "pi-vimeo",
            "code": "pi-vimeo"
        },
        {
            "name": "pi-volume-down",
            "code": "pi-volume-down"
        },
        {
            "name": "pi-volume-off",
            "code": "pi-volume-off"
        },
        {
            "name": "pi-volume-up",
            "code": "pi-volume-up"
        },
        {
            "name": "pi-wallet",
            "code": "pi-wallet"
        },
        {
            "name": "pi-whatsapp",
            "code": "pi-whatsapp"
        },
        {
            "name": "pi-wifi",
            "code": "pi-wifi"
        },
        {
            "name": "pi-window-maximize",
            "code": "pi-window-maximize"
        },
        {
            "name": "pi-window-minimize",
            "code": "pi-window-minimize"
        },
        {
            "name": "pi-wrench",
            "code": "pi-wrench"
        },
        {
            "name": "pi-youtube",
            "code": "pi-youtube"
        }
    ];



    const selectedIconTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <i className={"mr-2 pi " + option.code} style={{ width: '18px' }}></i>
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const iconOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <i className={"mr-2 pi " + option.code} style={{ width: '18px' }}></i>
                <div>{option.name}</div>
            </div>
        );
    };

    return (
        <form onSubmit={handleSubmit} className="p-fluid custom-form">
            <SelectButton options={["Main", "Alternate"]} value={altTab ? "Alternate" : "Main"} onChange={(e) => setAltTab(e.value == "Alternate")} />
            {altTab ? <>

                <h6>General</h6>
                <hr />

                <InputSwitchComponent id="altEnabled" checked={formData.altEnabled} onChange={(value) => inputChanged(value, 'altEnabled')} />

                {formData.altEnabled ? (
                    <>
                        <InputTextComponent
                            id="chartName"
                            value={formData.chartName}
                            onChange={(value) => inputChanged(value, 'chartName')}
                        />
                        {formErrors.chartName && <small id="name-help" className="p-error block">{formErrors.chartName}</small>}

                        <InputTextComponent
                            id="altChartName"
                            value={formData.altChartName}
                            onChange={(value) => inputChanged(value, 'altChartName')}
                        />
                        {formErrors.altChartName && <small id="name-help" className="p-error block">{formErrors.altChartName}</small>}

                        <InputTextareaComponent
                            id="altTooltip"
                            value={formData.altTooltip}
                            onChange={(value) => inputChanged(value, 'altTooltip')}
                        />

                        <InputTextareaComponent
                            id="altInsight"
                            value={formData.altInsight}
                            onChange={(value) => inputChanged(value, 'altInsight')}
                        />

                        <InputTextComponent
                            id="altIndustryValue"
                            value={formData.altIndustryValue}
                            onChange={(value) => inputChanged(value, 'altIndustryValue')}
                        />

                        <h6>Banding</h6>
                        <hr />

                        <InputTextComponent
                            id="altLowerBand"
                            value={formData.altLowerBand}
                            onChange={(value) => inputChanged(value, 'altLowerBand')}
                        />

                        <InputTextComponent
                            id="altUpperBand"
                            value={formData.altUpperBand}
                            onChange={(value) => inputChanged(value, 'altUpperBand')}
                        />

                        <h6>Dial Formatting</h6>
                        <hr />

                        <InputTextComponent
                            id="altDialValueDP"
                            value={formData.altDialValueDP}
                            onChange={(value) => inputChanged(value, 'altDialValueDP')}
                        />

                        <DropdownComponent
                            id="altDialValueType"
                            value={formData.altDialValueType}
                            options={valueDisplayTypes}
                            optionLabel="name"
                            optionValue="code"
                            onChange={(value) => inputChanged(value, 'altDialValueType')}
                        />

                        <h6>Display Value Formatting</h6>
                        <hr />

                        <DropdownComponent
                            id="altActualValueFormat"
                            value={formData.altActualValueFormat}
                            options={valueFormats}
                            optionLabel="name"
                            optionValue="code"
                            onChange={(value) => inputChanged(value, 'altActualValueFormat')}
                        />

                        {formData.id <= 5 ? (
                            <DropdownComponent
                                id="altActualValueFormatDashboard"
                                value={formData.altActualValueFormatDashboard}
                                options={valueFormats}
                                optionLabel="name"
                                optionValue="code"
                                onChange={(value) => inputChanged(value, 'altActualValueFormatDashboard')}
                            />
                        ) : null}

                        <InputTextComponent
                            id="altActualValueDP"
                            value={formData.altActualValueDP}
                            onChange={(value) => inputChanged(value, 'altActualValueDP')}
                        />

                        <DropdownComponent
                            id="altActualValueType"
                            value={formData.altActualValueType}
                            options={valueDisplayTypes}
                            optionValue="code"
                            optionLabel="name"
                            onChange={(value) => inputChanged(value, 'altActualValueType')}
                        />

                        {formData.id >= 22 && formData.id <= 26 && (
                            <>
                                <DropdownComponent
                                    id="altSubKPI1Icon"
                                    value={formData.altSubKPI1Icon}
                                    options={iconList}
                                    optionValue="code"
                                    optionLabel="name"
                                    valueTemplate={selectedIconTemplate}
                                    itemTemplate={iconOptionTemplate}
                                    onChange={(value) => inputChanged(value, 'altSubKPI1Icon')}
                                />

                                <DropdownComponent
                                    id="altSubKPI2Icon"
                                    value={formData.altSubKPI2Icon}
                                    options={iconList}
                                    optionValue="code"
                                    optionLabel="name"
                                    valueTemplate={selectedIconTemplate}
                                    itemTemplate={iconOptionTemplate}
                                    onChange={(value) => inputChanged(value, 'altSubKPI2Icon')}
                                />
                            </>
                        )}

                        <h6>Comparison</h6>
                        <hr />

                        <DropdownComponent
                            id="altAnnualComparisonType"
                            value={formData.altAnnualComparisonType}
                            options={comparisonTypes}
                            optionValue="code"
                            optionLabel="name"
                            onChange={(value) => inputChanged(value, 'altAnnualComparisonType')}
                        />

                        <DropdownComponent
                            id="altIndustryComparisonType"
                            value={formData.altIndustryComparisonType}
                            options={comparisonTypes}
                            optionValue="code"
                            optionLabel="name"
                            onChange={(value) => inputChanged(value, 'altIndustryComparisonType')}
                        />
                    </>) : <></>}
            </> :
                <>
                    <h6>General</h6>
                    <hr />
                    <InputTextComponent id="name" value={formData.name} onChange={(value) => inputChanged(value, 'name')} />
                    <InputTextareaComponent id="tooltip" value={formData.tooltip} onChange={(value) => inputChanged(value, 'tooltip')} />
                    <InputTextareaComponent id="insight" value={formData.insight} onChange={(value) => inputChanged(value, 'insight')} />
                    <InputTextComponent id="industryAverage" value={formData.industryAverage} onChange={(value) => inputChanged(value, 'industryAverage')} />
                    <InputSwitchComponent id="negativeKPI" checked={formData.negativeKPI} onChange={(value) => inputChanged(value, 'negativeKPI')} />

                    <h6>Interactivity</h6>
                    <hr />
                    <InputSwitchComponent id="displaySlider" checked={formData.displaySlider} onChange={(value) => inputChanged(value, 'displaySlider')} />
                    {formData.displaySlider && (
                        <>
                            <InputTextComponent id="sliderMin" value={formData.sliderMin} onChange={(value) => inputChanged(value, 'sliderMin')} />
                            <InputTextComponent id="sliderMax" value={formData.sliderMax} onChange={(value) => inputChanged(value, 'sliderMax')} />
                        </>
                    )}

                    <h6>Banding</h6>
                    <hr />
                    <InputTextComponent id="lowerBand" value={formData.lowerBand} onChange={(value) => inputChanged(value, 'lowerBand')} />
                    <InputTextComponent id="upperBand" value={formData.upperBand} onChange={(value) => inputChanged(value, 'upperBand')} />

                    {/* Formatting Section */}
                    <h6>Dial Formatting</h6>
                    <hr />
                    <InputTextComponent id="dialValueDP" value={formData.dialValueDP} onChange={(value) => inputChanged(value, 'dialValueDP')} />
                    <DropdownComponent
                        id="dialValueType"
                        value={formData.dialValueType}
                        options={valueDisplayTypes}
                        onChange={(value) => inputChanged(value, 'dialValueType')}
                    />

                    <h6>Display Value Formatting</h6>
                    <hr />
                    <DropdownComponent
                        id="actualValueFormat"
                        value={formData.actualValueFormat}
                        options={valueFormats}
                        onChange={(value) => inputChanged(value, 'actualValueFormat')}
                    />
                    {formData.id <= 5 && (
                        <DropdownComponent
                            id="actualValueFormatDashboard"
                            value={formData.actualValueFormatDashboard}
                            options={valueFormats}
                            onChange={(value) => inputChanged(value, 'actualValueFormatDashboard')}
                        />
                    )}
                    <InputTextComponent id="actualValueDP" value={formData.actualValueDP} onChange={(value) => inputChanged(value, 'actualValueDP')} />
                    <DropdownComponent
                        id="actualValueType"
                        value={formData.actualValueType}
                        options={valueDisplayTypes}
                        onChange={(value) => inputChanged(value, 'actualValueType')}
                    />

                    {formData.id >= 22 && formData.id <= 26 && (
                        <>
                            <DropdownComponent
                                id="subKPI1Icon"
                                value={formData.subKPI1Icon}
                                options={iconList}
                                onChange={(value) => inputChanged(value, 'subKPI1Icon')}
                            />
                            <DropdownComponent
                                id="subKPI2Icon"
                                value={formData.subKPI2Icon}
                                options={iconList}
                                onChange={(value) => inputChanged(value, 'subKPI2Icon')}
                            />
                        </>
                    )}

                    <h6>Line Chart</h6>
                    <hr />
                    <ColorPickerComponent
                        id="lineChartColour"
                        value={formData.lineChartColour}
                        onChange={(value) => inputChanged(value, 'lineChartColour')}
                    />

                    <h6>Comparison</h6>
                    <hr />
                    <DropdownComponent
                        id="annualComparisonType"
                        value={formData.annualComparisonType}
                        options={comparisonTypes}
                        onChange={(value) => inputChanged(value, 'annualComparisonType')}
                    />
                    <DropdownComponent
                        id="industryComparisonType"
                        value={formData.industryComparisonType}
                        options={comparisonTypes}
                        onChange={(value) => inputChanged(value, 'industryComparisonType')}
                    />
                </>}

            <div className="field grid">
                <div className="col-fixed" style={{ width: '150px' }}></div>
                <div className="input" style={{ flex: '1' }}>
                    <Button type="submit" label="Submit" className="mt-2 mb-2 btn-custom" style={{ width: '150px', float: 'right' }} />
                </div>
            </div>
        </form>
    );
};

export default ModifyKPIForm;