import KpiPage from './KpiPage';

const GrowthInGivingPage = (props) => {
    const year = 2022; // Example year
    const pageId = 72; // Example page ID


    return (
        <KpiPage year={year} pageId={pageId} pageIdentifier={props.pageIdentifier} hideAnnualComparison={true} />
    );
};

export default GrowthInGivingPage;