import React from 'react';
import { InputSwitch } from 'primereact/inputswitch';

function InputSwitchComponent({ id, checked, onChange }) {
    return (
        <div className="field grid">
            <label htmlFor={id} className="col-fixed mb-0" style={{ width: '150px' }}>{id}</label>
            <div className="input" style={{ flex: '1' }}>
                <InputSwitch
                    id={id}
                    checked={checked}
                    onChange={(e) => onChange(e.value)}
                    className="p-checkbox p-component"
                />
            </div>
        </div>
    );
}

export default InputSwitchComponent;