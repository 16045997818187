import axios from 'axios';
import FormData from 'form-data';

export default class ApiService {
    //Structure
    addClientId(cookies, str, delimiter = '&') {
        str += delimiter + "ClientDataId=" + cookies["ClientDataId"];
        return str;
    }

    getAllUsers() {
        return axios.get('/api/admin/clients/getall').then(res => res.data);
    }
    getUser(id) {
        return axios.get('/api/admin/clients/get?id=' + id).then(res => res.data);
    }

    createUser(formData) {
        return axios.post('/api/admin/clients/create', formData).then(res => res.data);
    }

    updateUser(formData) {
        return axios.post('/api/admin/clients/update', formData).then(res => res.data);
    }

    deleteUser(id) {
        return axios.post('/api/admin/clients/delete?id=' + id).then(res => res.data);
    }

    // Properties
    getAllProperties() {
        return axios.get('/api/admin/properties/get/all').then(res => res.data);
    }
    getPageDataProperties(id) {
        return axios.get('/api/GetPageDataProperties?id=' + id).then(res => res.data);
    }
    updateProperty(formData) {
        return axios.post('/api/admin/properties/update', formData).then(res => res.data);
    }
    // KPI's
    getAllKpis() {
        return axios.get('/api/admin/kpis/getall').then(res => res.data);
    }
    getKpi(id) {
        return axios.get('/api/admin/kpis/get?id=' + id).then(res => res.data);
    }
    updateKpi(formData) {
        return axios.post('/api/admin/kpis/update', formData).then(res => res.data);
    }

    // General

    getTopBar(cookies) {
        return axios.get(this.addClientId(cookies, '/api/gettopbar', '?')).then(res => res.data);
    }

    getSideBar() {
        return axios.get('/api/getsideBar').then(res => res.data);
    }

    //Report Pages
    canUploadReport() {
        return axios.get('/api/CanUploadReport').then(res => res.data);
    }

    getReport(cookies, year, pageId) {

        return axios.get(this.addClientId(cookies, '/api/GetReport?yr=' + year + "&pageId=" + pageId)).then(res => res.data);
    }


    getYears(cookies) {
        return axios.get(this.addClientId(cookies, '/api/GetYears', '?')).then(res => res.data);
    }

    getTrendData(cookies, chartTypeId) {
        return axios.get(this.addClientId(cookies, '/api/GetTrendData?chartTypeId=' + chartTypeId)).then(res => res.data);
    }

    getMonthlyTrendData(cookies, chartTypeId, type, year) {
        return axios.get(this.addClientId(cookies, '/api/GetMonthlyTrendData?chartTypeId=' + chartTypeId + "&year=" + year + "&type=" + type)).then(res => res.data);
    }

    getTrendDataFromReport(cookies, reportPageId, type) {
        return axios.get(this.addClientId(cookies, '/api/GetTrendDataFromReport?reportPageId=' + reportPageId + '&type=' + type)).then(res => res.data);
    }

    getTrendDataFromReportForYear(cookies, reportPageId, type, year) {
        return axios.get(this.addClientId(cookies, '/api/GetTrendDataFromReportForYear?reportPageId=' + reportPageId + '&type=' + type + '&year=' + year)).then(res => res.data);
    }

    getTrendDataFromGiftValue(cookies, kpiId) {
        return axios.get(this.addClientId(cookies, '/api/GetHistoricalValueReport?kpiId=' + kpiId)).then(res => res.data);
    }
    getForecastFigures(cookies, year) {
        return axios.get(this.addClientId(cookies, '/api/GetForecastFigures?yr=' + year)).then(res => res.data);
    }

    getPerformanceTable(cookies, year) {
        return axios.get(this.addClientId(cookies, '/api/GetPerformanceTable?yr=' + year)).then(res => res.data);
    }

    //Manager Reports
    getAllReports() {
        return axios.get('/api/GetAllReports').then(res => res.data);
    }
    deleteReport(id) {
        return axios.post('/api/DeleteReport?id=' + id).then(res => res.data);
    }

    uploadReport(formData) {
        const form = new FormData();

        for (const key in formData) {
            form.append(key, formData[key]);
        }

        try {
            return axios.post('/api/UploadReport', form, {
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
                },
            }).then(res => res.data);

        } catch (error) {
            // Handle error
            console.error('Error uploading report:', error);
            throw error;
        }
    }


    //

    getTopButtons(id) {
        return axios.get('assets/data-example/kpis/kpi-topbutton.json').then(res => res.data);
    }

}
