import React, { useState, useEffect, useRef } from 'react';
import Cookies from "js-cookie";
import axios from 'axios';


import { BrowserRouter, Router, Switch, Route, useHistory } from "react-router-dom";
import { AuthContext, AuthService } from './service/AuthService';
import PrivateRoute from './components/auth/PrivateRoute';
import AdminRoute from './components/auth/AdminRoute';
import AppConfig from './AppConfig'
import AppTopbar from './AppTopbar';
import { PanelMenu } from 'primereact/panelmenu';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

import ApiService from './service/ApiService';

import AcquisitionPage from './components/pages/kpis/AcquisitionPage';
import AttritionPage from './components/pages/kpis/AttritionPage';
import DashboardPage from './components/pages/kpis/DashboardPage';
import FrequencyPage from './components/pages/kpis/FrequencyPage';
import GainAndLossPage from './components/pages/kpis/GainAndLossPage';
import GrowthInGivingPage from './components/pages/kpis/GrowthInGivingPage';
import RetentionPage from './components/pages/kpis/RetentionPage';
import ValuePage from './components/pages/kpis/ValuePage';
import LoginPage from './components/auth/LoginPage';

import AcquisitionVsAttritionPage from './components/pages/annual-trends/AcquisitionVsAttritionPage';
import Generic3GraphicsHandler from './components/generic-handler/handler/Generic3GraphicsHandler';
import GenericSingleGraphicHandler from './components/generic-handler/handler/GenericSingleGraphicHandler';
import GenericSingleGraphic from './components/generic-handler/GenericSingleGraphic';
import Generic3Graphics from './components/generic-handler/Generic3Graphics';

import AddNewReport from './components/pages/report-manager/AddNewReport';
import Manager from './components/pages/report-manager/Manager';

import ClientManager from './components/pages/admin/ClientManager';
import KPIManager from './components/pages/admin/KPIManager';
import PageManager from './components/pages/admin/PageManager';
import { Toast } from 'primereact/toast';
import GiftValueTrends from './components/custom-charts/GiftValueTrends';
import FrequencyTrends from './components/custom-charts/FrequencyTrends';

import TestingPage from './components/pages/TestingPage';
import MonthlyRevenue from './components/pages/annual-trends/MonthlyRevenue';
import PropTrendsReport from './components/report/PropTrendsReport';

const App = () => {
    const [menuItems, setMenuItems] = useState([]);
    const [menuItemClicked, setMenuItemClicked] = useState(null);
    const [uploadDashboard, setUploadDashboard] = useState(false);
    const [currentSidebar, setCurrentSidebar] = useState(null);
    const [adminDashboard, setAdminDashboard] = useState(false);
    const [itemClass, setItemClass] = useState(false);
    var [privLevel, setPrivLevel] = useState(Cookies.get("priv_level"));
    var [seenDesktopNotification, setSeenDesktopNotification] = useState(false);
    var [canUploadReport, setCanUploadReport] = useState(false);
    const toast = useRef(null);
    const history = useHistory();

    const [seed, setSeed] = useState(1);
    const reset = () => {
        setSeed(Math.random());
    }

    const checkAuthenticated = async () => {
        const response = await axios.get('/api/auth/isauthenticated');
        const { data } = response;
        setIsAuthenticated(data);
        return data;
    };



    const [isAuthenticated, setIsAuthenticated] = useState(
        (async () => {
            await checkAuthenticated()
        })()
    );

    useEffect(() => {
        const timer = setInterval(async () => {
            if (!await checkAuthenticated()) {
                history.push('/account/login');
            } else {
                getCanUploadReport();
            }
        }, 3000);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        Cookies.set("seen_desktop_notification", seenDesktopNotification);
    }, [seenDesktopNotification]);


    const getCanUploadReport = async () => {
        const apiService = new ApiService();
        if (!isAuthenticated) {
            return;
        }
        setCanUploadReport(await apiService.canUploadReport());
    };


    const getSidebarItems = async () => {
        const apiService = new ApiService();
        if (!isAuthenticated) {
            return;
        }
        var items = await apiService.getSideBar();

        if (items != null) {
            let formattedMenu = items;

            formattedMenu.map(parent => {
                if (parent.items.length !== 0) {
                    parent.items.map(child => {
                        if (child.items.length !== 0) {
                            child.items.map(grandchild => {
                                if (grandchild.command !== null) {
                                    grandchild.command = (e) => hadleMenuSideClick(e, grandchild.reportID);
                                }

                                return formattedMenu;
                            });
                        }
                        else {
                            if (child.command !== null) {
                                child.command = (e) => hadleMenuSideClick(e, child.reportID);
                            }
                        }

                        return formattedMenu;
                    });
                }
                else {
                    if (parent.command !== null) {
                        parent.command = (e) => hadleMenuSideClick(e, parent.reportID);
                    }
                }

                return formattedMenu;
            });

            setMenuItems(formattedMenu);
        }
    };

    const pLvl = Cookies.get("priv_level");
    if (privLevel !== pLvl) {
        setPrivLevel(pLvl);
    }

    useEffect(() => {
        if (isAuthenticated) {
            getSidebarItems();
            getCanUploadReport();
        }
    }, [isAuthenticated, canUploadReport]);

    useEffect(() => {
        setCurrentSidebar(getSidebar());

    }, [privLevel, isAuthenticated, uploadDashboard, adminDashboard, menuItems]);

    const setClientDataId = (id) => {
        Cookies.set("ClientDataId", id);
    };

    const hadleMenuSideClick = (e, reportId) => {
        var newReport = false;

        if (Cookies.get("ClientDataId") != reportId) {
            setClientDataId(reportId);
            newReport = true;
        }
        setMenuItemClicked(e.item.id);
        let url = e.item.target;
        let elements = document.querySelectorAll('.p-menuitem');
        let classes = e.item.className;

        elements.forEach((element) => {
            element.classList.remove('activenavigation');
        });

        e.item.className += " activenavigation";

        var origUrl = history.location.pathname;
        history.push(url);

        if (origUrl == url && newReport) {
            //history.go(0);
            reset();
        }
    }

    document.addEventListener('click', function (e) {
        var target = e.target;
        if (target.parentNode !== null) {
            if (target.parentNode.classList.contains("main-item")) {
                const elements = document.querySelectorAll('.p-menuitem');

                elements.forEach((element) => {
                    element.classList.remove('activenavigation');
                });
            }
        }

    }, false);

    const routeUpload = (e, url, item) => {
        setSidebar("reports");
        setItemClass(item);
        history.push(url);
    }

    const routeAdmin = (e, url, item) => {
        setSidebar("admin");
        setItemClass(item);
        history.push(url);
    }

    const backToReports = (e) => {
        e.preventDefault();
        if (isAuthenticated) {
            getSidebarItems();
        }
        setSidebar("kpi");
    }

    const btnPercent = (name = "KPI") => {
        return {
            name: name,
            value: "1"
        }
    };
    const btnIncome = (name = "Income") => {
        return {
            name: name,
            value: "2"
        }
    };
    const btnActual = (name = "Number of Gifts") => {
        return {
            name: name,
            value: "0"
        }
    };

    const allButtonOptions = [btnPercent(), btnIncome(), btnActual()];
    const percentActualIncomeButtons = [btnPercent(), btnActual("Income")];
    const percentActualButtons = [btnPercent(), btnActual()];
    const giftValueButtons = [
        {
            name: 'Number of Gifts',
            value: "0"
        },
        {
            name: 'Total £\'s',
            value: "1"
        },
        {
            name: 'Average £ Size',
            value: "2"
        }
    ];

    const reportManagerSidebar = <>
        <div className="layout-menu-container">
            <div className="panelTopUpload">
                <a href="#" onClick={(e) => backToReports(e)}><i className="pi pi-arrow-circle-left"></i> Back to Reports</a>
                <div className="panelTopUload-report">
                    <h4>Report Manager</h4>
                    <div className="panel-item">
                        <Button label="Manager" className={itemClass === "manager" ? "active" : ""} icon="pi pi-list" onClick={(e) => routeUpload(e, "/components/pages/report-manager/manager", "manager")} />
                    </div>
                    {canUploadReport ?
                        <div className="panel-item">
                            <Button label="Upload Report" className={itemClass === "upload-report" ? "active" : ""} icon="pi pi-plus" onClick={(e) => routeUpload(e, "/components/pages/report-manager/addnewreport", "upload-report")} />
                        </div>
                        : <></>}
                </div>
            </div>

            <div className="panelBtm">
                <Button label="Contact Support" className="link-standard" onClick={() => window.location.href = "mailto:help@dreamscape.solutions"}></Button>
            </div>
        </div>
    </>;

    const kpiSidebar = <>
        <div className="layout-menu-container">
            {
                menuItems != null ?
                    <div className="panelTop" id="panelTop">
                        <PanelMenu model={menuItems} />
                    </div>

                    : ""
            }

            <div className="panelBtm">
                {privLevel == "GLOBAL_ADMIN" ?
                    <Button label="Admin" icon="pi pi-cog" className="link" onClick={(e) => setSidebar("admin")} />
                    : <></>}
                {canUploadReport ?
                <Button label="Add new report" icon="pi pi-times" className="link" onClick={(e) => routeUpload(e, "/components/pages/report-manager/addnewreport", "upload-report")} />
                : <></>}
                <Button label="Manage Reports" className="link-standard" href="#" onClick={(e) => routeUpload(e, "/components/pages/report-manager/manager", "manager")}></Button>
                <Button label="Contact Support" className="link-standard" onClick={() => window.location.href = "mailto:help@dreamscape.solutions"}></Button>
            </div>
        </div>
    </>;

    const adminSidebar = <>
        <div className="layout-menu-container">
            <div className="panelTopUpload">
                <a href="#" onClick={(e) => backToReports(e)}><i className="pi pi-arrow-circle-left"></i> Back to Reports</a>
                <div className="panelTopUload-report">
                    <h4>Administration</h4>
                    <div className="panel-item">
                        <Button label="Client Management" className={itemClass === "admin_clients" ? "active" : ""} icon="pi pi-list" onClick={(e) => routeAdmin(e, "/components/pages/admin/clients", "admin_clients")} />
                    </div>
                    <div className="panel-item">
                        <Button label="KPI Management" className={itemClass === "admin_kpis" ? "active" : ""} icon="pi pi-list" onClick={(e) => routeAdmin(e, "/components/pages/admin/kpis", "admin_kpis")} />
                    </div>
                    <div className="panel-item">
                        <Button label="Page Management" className={itemClass === "admin_pages" ? "active" : ""} icon="pi pi-list" onClick={(e) => routeAdmin(e, "/components/pages/admin/pages", "admin_pages")} />
                    </div>
                </div>
            </div>

            <div className="panelBtm">
                <Button label="Contact Support" className="link-standard" onClick={() => window.location.href = "mailto:help@dreamscape.solutions"}></Button>
            </div>
        </div>
    </>;


    const getSidebar = () => {
        if (!isAuthenticated) {
            return <></>;
        }

        if (uploadDashboard) {
            return reportManagerSidebar;
        }

        if (adminDashboard) {
            return adminSidebar;
        }

        return kpiSidebar;
    };

    const setSidebar = (type) => {
        switch (type) {
            case "kpi":
                setUploadDashboard(false);
                setAdminDashboard(false);
                break;
            case "reports":
                setUploadDashboard(true);
                setAdminDashboard(false);
                break;
            case "admin":
                setUploadDashboard(false);
                setAdminDashboard(true);
                break;
        }
    };

    return (
        <AuthService isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} privLevel={privLevel}>
            <div className="layout-wrapper custom-template-fft layout-topbar-indigo">
                <Toast ref={toast} />
                <Dialog visible={!seenDesktopNotification && window.innerWidth < 1366} style={{ width: '50vw', minWidth: '300px', maxWidth: '100%', paddingBottom: '30px', backgroundColor: '#fff' }} onHide={() => setSeenDesktopNotification(true)} header="Attention: Desktop App Compatibility Notice">
                    <p>The FFT is optimised for desktop use and is not currently compatible with mobile or tablet devices. For the best experience, please access the app from a desktop computer.</p>
                    <p>We apologize for any inconvenience this may cause. If you have any questions or need further assistance, please contact our support team at <a href="mailto:help@dreamscape.solutions">help@dreamscape.solutions</a></p>
                    <p><strong>Thank you for using the Fundraising Fitness Test!</strong></p>
                </Dialog>
                {isAuthenticated ? <AppTopbar /> : <></>}

                {currentSidebar}

                <div className={isAuthenticated ? "layout-main" : ""} key={seed}>
                    <div className="layout-content">
                        <BrowserRouter>
                            <Router history={history}>
                                <Switch>
                                    <PrivateRoute exact path="/" >
                                        <Manager canUploadReport={canUploadReport} routeUpload={routeUpload} />
                                    </PrivateRoute>
                                    <Route path="/account/login">
                                        <LoginPage />
                                    </Route>
                                    <Route path="/testing">
                                        <TestingPage />
                                    </Route>

                                    {/* KPIs */}
                                    <PrivateRoute path="/components/pages/kpis/acquisition"><AcquisitionPage pageIdentifier="KPIS_ACQUISITION" /></PrivateRoute>
                                    <PrivateRoute path="/components/pages/kpis/attrition"><AttritionPage pageIdentifier="KPIS_ATTRITION" /></PrivateRoute>
                                    <PrivateRoute path="/components/pages/kpis/dashboard"><DashboardPage pageIdentifier="KPIS_DASHBOARD" /></PrivateRoute>
                                    <PrivateRoute path="/components/pages/kpis/frequency"><FrequencyPage pageIdentifier="KPIS_AVERAGE_FREQUENCY" /></PrivateRoute>
                                    <PrivateRoute path="/components/pages/kpis/gainandloss"><GainAndLossPage pageIdentifier="KPIS_GAINS___LOSSES" /></PrivateRoute>
                                    <PrivateRoute path="/components/pages/kpis/growthingiving"><GrowthInGivingPage pageIdentifier="KPIS_GROWTH_IN_GIVING" /></PrivateRoute>
                                    <PrivateRoute path="/components/pages/kpis/retention"><RetentionPage pageIdentifier="KPIS_RETENTION" /></PrivateRoute>
                                    <PrivateRoute path="/components/pages/kpis/value"><ValuePage pageIdentifier="KPIS_AVERAGE_VALUE" /></PrivateRoute>

                                    {/* Annual Trends */}
                                    <PrivateRoute path="/components/pages/annual-trends/majorkpis"><GenericSingleGraphic pageIdentifier="TRENDS_MAJOR_KPIS" key="major" combineCharts={true} dataIsMultiple={true} reportPageId="14" title="Major KPI Trends" buttonOptions={allButtonOptions} /></PrivateRoute>
                                    <PrivateRoute path="/components/pages/annual-trends/acquisitionvsattrition"><AcquisitionVsAttritionPage pageIdentifier="TRENDS_ACQUISITION_VS_ATTRITION" /></PrivateRoute>
                                    <PrivateRoute path="/components/pages/annual-trends/acquisition"><GenericSingleGraphic pageIdentifier="TRENDS_ACQUISITION" key="acquisition" dataIsMultiple={true} reportPageId="15" title="Acquisition Trends" buttonOptions={allButtonOptions} /></PrivateRoute>
                                    <PrivateRoute path="/components/pages/annual-trends/retention"><GenericSingleGraphic pageIdentifier="TRENDS_RETENTION" key="retention" dataIsMultiple={true} reportPageId="16" title="Retention Trends" buttonOptions={allButtonOptions} /></PrivateRoute>
                                    <PrivateRoute path="/components/pages/annual-trends/attrition"><GenericSingleGraphic pageIdentifier="TRENDS_ATTRITION" key="attrition" dataIsMultiple={true} reportPageId="17" title="Attrition Trends" buttonOptions={allButtonOptions} /></PrivateRoute>
                                    <PrivateRoute path="/components/pages/annual-trends/averagevalue"><GiftValueTrends pageIdentifier="TRENDS_VALUE" key="averagevalue" reportPageId="18" title="Gift Value Trends" type="1" buttonOptions={percentActualIncomeButtons} /></PrivateRoute>
                                    <PrivateRoute path="/components/pages/annual-trends/frequency"><FrequencyTrends pageIdentifier="TRENDS_FREQUENCY" key="frequency" reportPageId="19" title="Average Frequency Trends" buttonOptions={percentActualButtons} /></PrivateRoute>

                                    {/* Historical Values */}
                                    <PrivateRoute path="/components/pages/historical-values/totalrevenue"><GenericSingleGraphic pageIdentifier="HISTORY_TOTAL_REVENUE" chartTypeId="31" key="totalrevenue" /></PrivateRoute>
                                    <PrivateRoute path="/components/pages/historical-values/monthlyrevenue"><MonthlyRevenue pageIdentifier="HISTORY_MONTHLY_REVENUE" key="monthlyrevenue" /></PrivateRoute>
                                    <PrivateRoute path="/components/pages/historical-values/propincometrends"><PropTrendsReport pageIdentifier="PROP_INCOME_TRENDS" key="proptrends" /></PrivateRoute>
                                    <PrivateRoute path="/components/pages/historical-values/averagedonorvalue"><GenericSingleGraphic pageIdentifier="HISTORY_AVERAGE_DONOR_VALUE" chartTypeId="4" key="avgdnrvalue" /></PrivateRoute>
                                    <PrivateRoute path="/components/pages/historical-values/numberofgifts"><GenericSingleGraphic pageIdentifier="HISTORY_NUMBER_OF_GIFTS" chartTypeId="37" key="noofgifts" /></PrivateRoute>
                                    <PrivateRoute path="/components/pages/historical-values/giftsunder100"><GenericSingleGraphic pageIdentifier="HISTORY_GIFTS_UNDER_100" title="Gifts Under £100" chartTypeId="22" useGiftValue={true} dataIsMultiple={true} key="giftsunder100" /></PrivateRoute>
                                    <PrivateRoute path="/components/pages/historical-values/gifts100200"><GenericSingleGraphic pageIdentifier="HISTORY_GIFTS_BETWEEN_100_250" title="Gifts £100 - £250" chartTypeId="23" useGiftValue={true} dataIsMultiple={true} key="gifts100200" /></PrivateRoute>
                                    <PrivateRoute path="/components/pages/historical-values/gifts2001k"><GenericSingleGraphic pageIdentifier="HISTORY_GIFTS_BETWEEN_250_1K" title="Gifts £250 - £1k" chartTypeId="24" useGiftValue={true} dataIsMultiple={true} key="gifts2001k" /></PrivateRoute>
                                    <PrivateRoute path="/components/pages/historical-values/gifts1k5k"><GenericSingleGraphic pageIdentifier="HISTORY_GIFTS_BETWEEN_1K_5K" title="Gifts £1k - £5k" chartTypeId="25" useGiftValue={true} dataIsMultiple={true} key="gifts1k5k" /></PrivateRoute>
                                    <PrivateRoute path="/components/pages/historical-values/giftsover5k"><GenericSingleGraphic pageIdentifier="HISTORY_GIFTS_OVER_5K" title="Gifts over 5k" chartTypeId="26" useGiftValue={true} dataIsMultiple={true} key="giftsover5k" /></PrivateRoute>

                                    {/* New Reports */}
                                    <PrivateRoute path="/components/pages/report-manager/addnewreport"><AddNewReport getSidebarItems={getSidebarItems} toast={toast} /></PrivateRoute>
                                    <PrivateRoute path="/components/pages/report-manager/manager"><Manager canUploadReport={canUploadReport} routeUpload={routeUpload} /></PrivateRoute>

                                    {/* Admin */}
                                    <AdminRoute path="/components/pages/admin/clients" exact>
                                        <ClientManager />
                                    </AdminRoute>
                                    <AdminRoute path="/components/pages/admin/kpis" exact>
                                        <KPIManager />
                                    </AdminRoute>
                                    <AdminRoute path="/components/pages/admin/pages" exact>
                                        <PageManager />
                                    </AdminRoute>
                                </Switch>
                            </Router>
                        </BrowserRouter>
                    </div>
                </div>

                <div className="layout-content-mask"></div>
            </div>
        </AuthService>
    );

}

export default App;
