import React, { useState, useEffect } from 'react';

import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { SelectButton } from 'primereact/selectbutton';
import { Fieldset } from 'primereact/fieldset';

import { Chart } from 'primereact/chart';

import ApiService from '../../service/ApiService';

import { useCookies } from 'react-cookie';

const GiftValueTrends = (props) => {

    const [title, setTitle] = useState(null);
    const [mainGraph, setMainGraph] = useState(null);
    const [stackGraph, setStackGraph] = useState(null);
    const [graphOptions, setGraphOptions] = useState(null);
    const [stackOptions, setStackOptions] = useState(null);
    const [graphOptionsArray, setGraphOptionsArray] = useState([]);
    const [buttonOptionsGeneric, setButtonOptionsGeneric] = useState(null);
    const [buttonOption, setButtonOption] = useState(props.type ?? "1");
    const [ClientDataId] = useCookies(['ClientDataId']);
    const [showHelpPane, setShowHelpPane] = useState(false);
    const apiService = new ApiService();
    const [pageProperties, setPageProperties] = useState({
        disableForecasting: true,
        helpText: '',
        chartTexts: []
    });


    const [trendLineOptions, setTrendLineOptions] = useState({
        borderColor: "#455ED1",
        borderWidth: "2",
        hoverWidth: "2",
        elements: {
            point: {
                pointStyle: 'line'
            }
        },
        order: 2,
    });


    const renderHTML = (rawHTML) => React.createElement("p", { dangerouslySetInnerHTML: { __html: rawHTML } });

    useEffect(() => {
        apiService.getPageDataProperties(props.pageIdentifier).then((data) => setPageProperties(data));
    }, [props.pageIdentifier]);


    const getTrendLineData = (data) => {

        // Calculate the sum of x and y values
        const sumX = data.reduce((sum, _, index) => sum + index, 0);
        const sumY = data.reduce((sum, value) => sum + value, 0);

        // Calculate the mean of x and y values
        const meanX = sumX / data.length;
        const meanY = sumY / data.length;

        // Calculate the differences from the mean
        const diffX = data.map((_, index) => index - meanX);
        const diffY = data.map(value => value - meanY);

        // Calculate the slope (m)
        const numerator = diffX.reduce((sum, value, index) => sum + value * diffY[index], 0);
        const denominator = diffX.reduce((sum, value) => sum + value * value, 0);
        const slope = numerator / denominator;

        // Calculate the y-intercept (b)
        const intercept = meanY - slope * meanX;

        return data.map((_, index) => slope * index + intercept);
    };
    function hexToRgbA(hex, alpha) {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
        }
        throw new Error('Bad Hex');
    }
    const createDataSet = (items) => {
        var graphOptionsAry = graphOptionsArray;

        var multipleObjs = [];
        for (var i = 0; i < 1; i++) {

            graphOptionsAry.push({
                custom: {
                    format: items[i].format,
                    dp: items[i].decimalPlaces ?? 0,
                }
            });

            var tlD = getTrendLineData(items[i].items.map(a => a.value));

            var trendLine = {};
            if (!props.hideTrendline) {
                trendLine = {
                    ...trendLineOptions,
                    label: "Trend Line",
                    data: tlD,
                    fill: false,
                    backgroundColor: "#fff",
                    tension: 0
                };
            }
            var ds = [];
            if (!props.hideTrendline) {
                ds.push(trendLine);
            }
            ds.push({
                data: items[i].items.map(a => a.value),
                borderColor: items[i].lineColour ?? "#B2BEB5",
                label: items[i].title,
                tension: .4,
                pointRadius: 5,
                pointHoverRadius: 9,
                order: 1,
            });
            multipleObjs.push({
                name: items[i].title,
                chart: {
                    datasets: ds,
                    labels: items[i].items.map(a => a.text)
                }
            });
        }
        setGraphOptionsArray(graphOptionsAry);
        return multipleObjs;
    };
    const createDataSetStacked = (items) => {

        const colours = [
            '#1e1e3c',
            '#5e56c4',
            '#a1a6fa',
            '#b5ebe8',
            '#eebad7'
        ];
        var obj = {
            labels: items[1].items.map(a => a.text)
        };

        var ds = [];

        for (var i = 1; i < items.length; i++) {


            ds.push({
                data: items[i].items.map(a => a.value),
                backgroundColor: colours[i - 1],
                label: items[i].title.replace("Donors and Gifts ", ""),
                tension: .4,
                order: 1
            });
        }
        obj.datasets = ds;
        return obj;
    };

    function initButtons() {
        if (props.buttonOptions) {
            setButtonOptionsGeneric(props.buttonOptions);
            if (buttonOption == null || props.buttonOptions.filter(x => x.value == buttonOption).length == 0) {
                setButtonOption(props.buttonOptions[0].value);
            }
        }
    }

    useEffect(async () => {


        var apiService = new ApiService();
        setMainGraph(null);
        setStackGraph(null);
        setGraphOptionsArray([]);

        apiService.getTrendDataFromReport(ClientDataId, props.reportPageId, buttonOption).then(td => {
            setTitle(props.title);
            setMainGraph(createDataSet(td));
            setStackGraph(createDataSetStacked(td));
            initButtons();
        });

        setGraphOptions({
            maintainAspectRatio: true,
            aspectRatio: 1.77,
            borderWidth: 4,
            pointBackgroundColor: (context) => {
                return context.dataset.borderColor;
            },
            backgroundColor: (context) => {
                const ctx = context.chart.ctx;
                const gradient = ctx.createLinearGradient(0, 0, 0, 500);
                //gradient.addColorStop(0, hexToRgbA(context.dataset.borderColor, 0.8));
                //gradient.addColorStop(1, hexToRgbA(context.dataset.borderColor, 0));
                gradient.addColorStop(0, hexToRgbA("#fff", 0));
                gradient.addColorStop(1, hexToRgbA("#fff", 0));
                return gradient;
            },
            fill: true,
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            if (!this._chart.config._config.options) {
                                return label;
                            }
                            var item = this._chart.config._config.options.custom;
                            if (!item) {
                                return label;
                            }

                            let label = "" + context.dataset.label || '';

                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed.y !== null) {
                                switch (item.format?.toLowerCase()) {
                                    case "percentage":
                                        label += context.parsed.y.toLocaleString('en-GB', { maximumFractionDigits: 2 }) + '%';
                                        break;
                                    case "currency":
                                        label += "£" + context.parsed.y.toLocaleString('en-GB', { maximumFractionDigits: 2 });
                                        break;
                                    default:
                                        label += context.parsed.y.toLocaleString('en-GB', { maximumFractionDigits: 2 });
                                        break;


                                }
                            }
                            return label;
                        }
                    }
                }
            },
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        callback: function (label, index, labels) {
                            if (!this.chart.config._config.options) {
                                return label;
                            }
                            var item = this.chart.config._config.options.custom;
                            if (!item) {
                                return label;
                            }
                            switch (item.format?.toLowerCase()) {
                                case "percentage":
                                    return label.toFixed(item.dp ?? 0) + '%';
                                case "currency":
                                    if (label >= 1000000) {
                                        return "£" + (label / 1000000).toLocaleString('en-GB', { maximumFractionDigits: 3 }) + "m";
                                    }
                                    if (label >= 1000) {
                                        return "£" + (label / 1000).toLocaleString('en-GB', { maximumFractionDigits: 3 }) + "k";
                                    }
                                    return "£" + label.toLocaleString('en-GB', { maximumFractionDigits: item.dp });

                            }
                            return label.toLocaleString('en-GB', { maximumFractionDigits: item.dp });
                        }
                    }
                }
            }
        });

        setStackOptions({
            maintainAspectRatio: true,
            aspectRatio: 1.77,
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            console.log(context);
                            let label = "" + context.dataset.label || '';

                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed.y !== null) {
                                if (buttonOption == "1") {
                                    label += context.parsed.y.toFixed(0) + '%';
                                } else {
                                    label += "£" + context.parsed.y.toLocaleString('en-GB', { maximumFractionDigits: 2 });
                                }
                            }
                            return label;
                        }
                    }     
                }
            },
            scales: {
                x: {
                    stacked: true
                },
                y: {
                    stacked: true,
                    beginAtZero: true,
                    ticks: {
                        callback: function (label, index, labels) {
                            if (buttonOption == "1") {
                                return label.toFixed(0) + '%';
                            } else {
                                if (label >= 1000000) {
                                    return "£" + (label / 1000000).toLocaleString('en-GB', { maximumFractionDigits: 3 }) + "m";
                                }
                                if (label >= 1000) {
                                    return "£" + (label / 1000).toLocaleString('en-GB', { maximumFractionDigits: 3 }) + "k";
                                }
                                return "£" + label.toLocaleString('en-GB', { maximumFractionDigits: 0 });
                            }

                        }
                    }
                }
            }
        });

    }, [buttonOption]);


    return (
        <>
            {/*header*/}
            <div className="col-12">
                <div className="grid p-fluid grid-row-header">
                    <div className="card">
                        {
                            title != null ?
                                <h3>{title}</h3>
                                : ""
                        }

                        <div className="rhs-row-header">
                            {buttonOptionsGeneric != null ? (
                                <SelectButton
                                    value={buttonOption}
                                    onChange={(e) => setButtonOption(e.value)}
                                    options={buttonOptionsGeneric}
                                    optionLabel="name"
                                />
                            ) : (
                                ''
                            )}
                            {pageProperties != null && pageProperties.helpText != '' && pageProperties.helpText != null &&
                                <Button label="Help" className="p-button-outlined mr-2 mb-2" onClick={() => setShowHelpPane(true)} />
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/*row data*/}
            {
                mainGraph != null ?
                    mainGraph.map((item, index) => {
                        return <div key={index}>
                            <div className="grid">
                                <div className="col-6">
                                    {pageProperties != null && pageProperties.chartTexts != null && pageProperties.chartTexts.length > index ?
                                        <Fieldset legend={pageProperties.chartTexts[index].title} toggleable collapsed={!pageProperties.chartTexts[index].openByDefault}>
                                            {renderHTML(pageProperties.chartTexts[index].text)}
                                        </Fieldset>
                                        : <></>}
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-6">
                                    <div className="p-fluid data-graphic">
                                        <div className="card">
                                            <h4><strong>{item.name}</strong></h4>
                                            <Chart type="line" key={"graph_" + props.chartTypeId + "_" + props.reportPageId + "_" + index} options={{ ...graphOptions, ...graphOptionsArray[index] }} data={item.chart} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                    : <></>
            }
            {
                stackGraph != null ?
                    <>
                        <div className="grid">
                            <div className="col-6">
                                {pageProperties != null && pageProperties.chartTexts != null && pageProperties.chartTexts.length > 1 ?
                                    <Fieldset legend={pageProperties.chartTexts[1].title} toggleable collapsed={!pageProperties.chartTexts[1].openByDefault}>
                                        {renderHTML(pageProperties.chartTexts[1].text)}
                                    </Fieldset>
                                    : <></>}
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-6">
                                <div className="p-fluid data-graphic">
                                    <div className="card">
                                        <h4><strong></strong></h4>
                                        <Chart type="bar" options={stackOptions} data={stackGraph} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    : <></>
            }
            <Sidebar visible={showHelpPane} position="right" onHide={() => setShowHelpPane(false)} className="p-sidebar-md">
                <h2>Help</h2>
                {renderHTML(pageProperties.helpText)}
            </Sidebar>
        </>
    );
}

export default GiftValueTrends;