import React, { useRef, useState, useEffect } from 'react';

import { useHistory } from "react-router-dom";
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import ApiService from '../../../service/ApiService';
import TableData from '../../report/TableData';
import { Dropdown } from 'primereact/dropdown';

const AddNewReport = (props) => {
    const fileUpload = useRef(null);
    const [showUploadDialog, setShowUploadDialog] = useState(null);
    const [performValidation, setPerformValidation] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const history = useHistory();
    const [formData, setFormData] = useState({
        reportTitle: '',
        monthsOffset: 12,
        file: null
    });

    const [formErrors, setFormErrors] = useState({
        reportTitle: '',
        file: '',
        monthsOffset: ''
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        setPerformValidation(true);
        if (validateForm()) {
            setSubmitDisabled(true);
            var apiService = new ApiService();
            apiService.uploadReport(formData).then((data) => {
                // Wait 2 seconds before redirecting to the report manager page
                props.toast.current.show({ severity: 'success', summary: 'Report Uploaded Successfully.', detail: 'You will be redirected in a moment. Please wait...' });
                setTimeout(() => {
                    history.push('/components/pages/report-manager/manager');
                    setSubmitDisabled(false);
                }, 1000);
                setShowUploadDialog(false);
            });
        }
    };

    useEffect(() => {
        if (performValidation) {
            validateForm();
        }

        console.log(formData);
    }, [performValidation, formData]);

    const validateForm = () => {
        let isValid = true;
        const errors = {
            reportTitle: '',
            file: ''
        };

        if (!formData.reportTitle || formData.reportTitle.trim() === '') {
            isValid = false;
            errors.reportTitle = 'Report Title is required';
        }

        if (!formData.file) {
            isValid = false;
            errors.file = 'Report File is required';
        }

        setFormErrors(errors);
        return isValid;
    };

    const inputChanged = (value, field) => {
        var d = { ...formData };
        d[field] = value;
        setFormData(d);
    };

    const fileUploadHandler = (event) => {
        const file = event.files[0];
        // Set the file name as the report title and add the file to the form data
        var d = { ...formData };
        d.file = file;
        d.reportTitle = d.reportTitle == '' || !d.reportTitle ? file.name : d.reportTitle;
        setFormData(d);

        // Reset the file upload to allow the same file to be uploaded again
        fileUpload.current.clear();

    };

    return (
        <>
            <div className="col-12">
                <div className="grid p-fluid">
                    <div className="card">
                        <div className="header-card">
                            <h3>Upload a report</h3>

                            {/*<div className="rhs-row-header">
                                <Button label="Help" className="p-button-outlined mr-2 mb-2" />
                            </div>*/}

                        </div>
                        <h4><strong>Data Upload Instructions</strong></h4>
                        <p>To create a new FFT report, please upload a CSV spreadsheet of your chosen dataset. Please note, Excel sheets are not accepted.
                            <br />
                            <br />
                            Your CSV file should only include the following three data fields, in the order presented:
                            <ol>
                                <li>Constituent ID number</li>
                                <li>Date of gift</li>
                                <li>Value of gift</li>
                            </ol>
                            <strong>For example:</strong>
                            <br />
                            <br />
                            <div className="grid-row-tables">
                                <div className="col-12 md:col-8">
                                    <div className="table-custom">
                                        <div className="p-datatable p-component p-datatable-responsive-stack" data-scrollselectors=".p-datatable-wrapper" pr_id_1="">
                                            <div className="p-datatable-wrapper">
                                                <TableData data={{
                                                    rows: [
                                                        {
                                                            isHeaderRow: true,
                                                            cells: [
                                                                { data: 'Constituent ID number' },
                                                                { data: 'Date of gift' },
                                                                { data: 'Value of gift' }
                                                            ]
                                                        },
                                                        {
                                                            cells: [
                                                                { data: '102546' },
                                                                { data: '08/06/2021' },
                                                                { data: '50.25' }
                                                            ]
                                                        },
                                                        {
                                                            cells: [
                                                                { data: '109642' },
                                                                { data: '12/11/2020' },
                                                                { data: '350' }
                                                            ]
                                                        },
                                                        {
                                                            cells: [
                                                                { data: '102546' },
                                                                { data: '22/09/2022' },
                                                                { data: '7.64' }
                                                            ]
                                                        },
                                                        {
                                                            cells: [
                                                                { data: '109642' },
                                                                { data: '16/04/2023' },
                                                                { data: '1050' }
                                                            ]
                                                        }
                                                    ]
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            If you wish, you can <a href="/Example-CSV-Dataset.csv" target="_blank">download an example CSV file</a> here.
                            <br />
                            <br />
                            Please remove header rows from your CSV file before uploading and ensure that your "Value of gift" column is set to "General" format, rather than number or currency.
                            <br />
                            <br />
                            Finally, it is recommended that you omit lottery, legacy, grants, and trust forms of income to accurately compare your KPIs with the industry averages.
                        </p>
                        <h4><strong>Data Processing Agreement</strong></h4>
                        <p><a href="/FFT 2.0 DPA.pdf" target="_blank">Click here</a> to review and download the FFT data processing agreement.</p>

                        <div className="col-4">
                            <div className="grid p-fluid">
                                <Button label="Upload" className="p-button mt-2 mr-2 mb-2 btn-custom" onClick={() => setShowUploadDialog(true)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog header="Upload Report" visible={showUploadDialog} style={{ width: '30vw', minWidth: '300px' }} onHide={() => setShowUploadDialog(false)}>
                <form onSubmit={handleSubmit} className="p-fluid custom-form">
                    <div className="field grid">
                        <label htmlFor="reportTitle" className="col-fixed mb-0 required" style={{ width: '150px' }}>Report Title</label>
                        <div className="input" style={{ flex: '1' }}>
                            <InputText
                                id="reportTitle"
                                value={formData.reportTitle}
                                onChange={(e) => inputChanged(e.target.value, "reportTitle")}
                                aria-describedby="reportTitle-help"
                                maxLength="50"
                                className={formErrors.reportTitle ? "p-invalid block" : "block"}
                            />
                            {formErrors.reportTitle && <small id="reportTitle-help" className="p-error block">{formErrors.reportTitle}</small>}
                        </div>
                    </div>
                    <div className="field grid">
                        <label htmlFor="monthsOffset" className="col-fixed mb-0 required" style={{ width: '150px' }}>Year End</label>
                        <div className="input" style={{ flex: '1' }}>
                            <Dropdown
                                id="monthsOffset"
                                value={formData.monthsOffset}
                                onChange={(e) => inputChanged(e.value, "monthsOffset")}
                                aria-describedby="monthsOffset-help"
                                maxLength="50"
                                options={[{
                                    name: "January",
                                    value: 1
                                },
                                {
                                    name: "February",
                                    value: 2
                                },
                                {
                                    name: "March",
                                    value: 3
                                },
                                {
                                    name: "April",
                                    value: 4
                                },
                                {
                                    name: "May",
                                    value: 5
                                },
                                {
                                    name: "June",
                                    value: 6
                                },
                                {
                                    name: "July",
                                    value: 7
                                },
                                {
                                    name: "August",
                                    value: 8
                                },
                                {
                                    name: "September",
                                    value: 9
                                },
                                {
                                    name: "October",
                                    value: 10
                                },
                                {
                                    name: "Novemeber",
                                    value: 11
                                },
                                {
                                    name: "December",
                                    value: 12
                                }]}
                                optionLabel="name"
                                placeholder="Select"
                                className={formErrors.monthsOffset ? "p-invalid" : ""}
                            />
                            {formErrors.monthsOffset && <small id="monthsOffset-help" className="p-error block">{formErrors.monthsOffset}</small>}
                        </div>
                    </div>
                    <div className="field grid">
                        <label htmlFor="reportFile" className="col-fixed mb-0 required" style={{ width: '150px' }}>File</label>
                        <div className="input" style={{ flex: '1' }}>
                            <FileUpload className="custom-uploader" ref={fileUpload} name="file" mode="basic" customUpload auto onSelect={fileUploadHandler} onCancel={() => setShowUploadDialog(false)} multiple={false} accept="text/csv" maxFileSize={6000000} emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} />
                            {formData.file && <><small><br />{formData.file.name} <a href="#" onClick={(e) => { e.preventDefault(); inputChanged(null, "file"); }}>(Remove)</a></small></>}
                            {formErrors.file && <small id="file-help" className="p-error block">{formErrors.file}</small>}
                        </div>
                    </div>
                    <div className="field grid">
                        <label htmlFor="isPartialReport" className="col-fixed mb-0 required" style={{ width: '150px' }}>Partial Report?</label>
                        <div className="input" style={{ flex: '1' }}>
                            <p>Use for granular reports that do not contain gift records each month.</p>
                            <InputSwitch
                                id="isPartialReport"
                                checked={formData.isPartialReport}
                                onChange={(e) => inputChanged(e.value, "isPartialReport")}
                                aria-describedby="isPartialReport-help"
                                maxLength="50"
                                className={formErrors.isPartialReport ? "p-invalid block" : "block"}
                            />
                            {formErrors.reportTitle && <small id="isPartialReport-help" className="p-error block">{formErrors.isPartialReport}</small>}
                        </div>
                    </div>
                    <div className="field grid">
                        <div className="col-fixed" style={{ width: '150px' }}></div>
                        <div className="input" style={{ flex: '1' }}>
                            <Button type="submit" disabled={submitDisabled} label="Submit" className="mt-2 mb-2 btn-custom" style={{ width: '150px', float: 'right' }} />
                        </div>
                    </div>
                </form>
            </Dialog>
        </>
    );
}

export default AddNewReport;