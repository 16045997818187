import React, { useState, useEffect, useRef } from 'react';

import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { confirmDialog } from 'primereact/confirmdialog';

import ApiService from '../../../service/ApiService';
import AddNewClient from './partials/AddNewClient';
import { Toast } from 'primereact/toast';

const ClientManager = (props) => {
    const [dataValues, setDataValues] = useState(null);
    const [addClientVisible, setAddClientVisible] = useState(null);
    const [editClientVisible, setEditClientVisible] = useState(null);
    const [currentEditId, setCurrentEditId] = useState(null);
    const toast = useRef(null);

    useEffect(() => {
        const apiService = new ApiService();

        //This is from a generic JSON just an example
        apiService.getAllUsers().then((dataValues) => {
            setDataValues(dataValues);
        });

    }, []);

    const editData = (rowData) => {
        setCurrentEditId(rowData.id);
        setEditClientVisible(true);
    };

    const deleteButton = (rowData) => {
        return <div className="deleteButton">
            <Button disabled={rowData.privilegeLevel == "GLOBAL_ADMIN"} type="button" icon="pi pi-times" label="Delete" className="p-button p-button-outlined" onClick={() => deleteData(rowData)} />
        </div>;
    };
    const editButton = (rowData) => {
        return <div className="editButton">
            <Button type="button" icon="pi pi-pencil" label="Edit" className="p-button p-button-outlined" onClick={() => editData(rowData)} />
        </div>;
    };

    const actionButtons = (rowData) => {
        return <>
            {deleteButton(rowData)}
            {editButton(rowData)}
        </>
    };

    const getYesNoButton = (flag) => {
        if (flag) {
            return <Button style={{ width: "60px", background: "#689F38", border: "none" }} icon="pi pi-check" />

        } else {
            return <Button style={{ width: "60px", background: "#D32F2F", border: "none" }} icon="pi pi-times" />
        }
    };


    const dateCreatedTemplate = (rowData) => {
        return new Date(rowData.dateCreated).toLocaleString();
    };

    const userEnabledTemplate = (rowData) => {
        return getYesNoButton(rowData.enabled);
    };
    const isAdminTemplate = (rowData) => {
        return getYesNoButton(rowData.privilegeLevel == "GLOBAL_ADMIN");
    };


    const deleteData = (data) => {
        confirmDialog({
            header: "Confirm Delete",
            message: 'Do you want to delete this client? All data will be removed.',
            icon: 'pi pi-exclamation-triangle',
            accept: () => acceptFunc(data),
            reject
        });
    }

    const acceptFunc = async (rowData) => {
        var apiService = new ApiService();
        await apiService.deleteUser(rowData.id);
        refreshPage();
       toast.current.show({ severity: 'success', summary: 'Client Deleted Successfully.', detail: '' });
    };

    const reject = () => {
        return false;
    };

    const refreshPage = () => {
        const apiService = new ApiService();

        //This is from a generic JSON just an example
        apiService.getAllUsers().then((dataValues) => {
            setDataValues(dataValues);
        });
    };


    return (
        <>
            <Toast ref={toast} position="top-left" />
            <div className="col-12">
                <div className="grid p-fluid">
                    <div className="card">
                        <div className="header-card">
                            <h3>Manage your clients</h3>

                            <div className="rhs-row-header">
                                <Button label="Add new client" icon="pi pi-plus" className="custombtn" onClick={() => setAddClientVisible(true)} />
                                <Button label="Help" className="p-button-outlined" />
                            </div>

                        </div>
                        <div className="dataValuesTable">

                            <div className="col-1 col-offset-11">
                                <div className="grid p-fluid">
                                    <Button label="Refresh" className="p-button-outlined" icon="pi pi-refresh" onClick={(e) => refreshPage()} />
                                </div>
                            </div>
                            <DataTable value={dataValues} tableStyle={{ minWidth: '50rem', width: '100%' }}>
                                <Column field="organisationName" header="Organisation Name"></Column>
                                <Column field="firstName" header="First Name"></Column>
                                <Column field="lastName" header="Last Name"></Column>
                                <Column field="email" header="Email Address"></Column>
                                <Column field="privilegeLevel" body={isAdminTemplate} header="Is Admin?"></Column>
                                <Column field="enabled" body={userEnabledTemplate} header="Enabled"></Column>
                                <Column field="dateCreated" body={dateCreatedTemplate} header="Created"></Column>
                                <Column style={{ minWidth: '50px' }} body={actionButtons}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog header="Add Client" visible={addClientVisible} style={{ width: '30vw', minWidth: '300px' }} onHide={() => setAddClientVisible(false)}>
                <AddNewClient refreshPage={refreshPage} setAddClientVisible={setAddClientVisible} toast={toast} />
            </Dialog>
            <Dialog header="Edit Client" visible={editClientVisible} style={{ width: '30vw', minWidth: '300px' }} onHide={() => setEditClientVisible(false)}>
                <AddNewClient edit={true} id={currentEditId} refreshPage={refreshPage} setAddClientVisible={setEditClientVisible} toast={toast} />
            </Dialog>

        </>
    );
}

export default ClientManager;