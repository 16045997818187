import React, { useEffect, useState } from 'react';
import { DataView } from 'primereact/dataview';



function ChartsComponent({ id, items, onChange, setCurrentGraph }) {

    const [charts, setCharts] = useState(items);
    const [dragItem, setDragItem] = useState(null);
    const [dragOverItem, setDragOverItem] = useState(null);
    const [addItem, setAddItem] = useState({
        title: "Add...",
        icon: "pi-plus",
        action: "#add"
    });


    const dragStart = (e, position) => {
        if (position != addItem.index) {
            setDragItem(position);
        }
        return true;
    };

    const dragLeave = (e, position) => {
        e.target.classList.remove("surface-300");
    };

    const dragEnter = (e, position) => {
        if (position != addItem.index) {
            e.target.classList.add("surface-300");
            setDragOverItem(position);
        }
        return true;
    };

    const drop = (e) => {
        const copyListItems = [...charts];
        if (dragItem == null || dragOverItem == null || dragItem == dragOverItem) {
            return false;
        }
        const dragItemContent = copyListItems[dragItem];
        copyListItems.splice(dragItem, 1);
        copyListItems.splice(dragOverItem, 0, dragItemContent);
        setDragItem(null);
        setDragOverItem(null);

        var addItemClone = { ...addItem };
        copyListItems.forEach((chart, index) => {
            chart.index = index;
            if (chart.action == "#add") {
                addItemClone.index = index;
            }
        });
        setAddItem(addItemClone);
        setCharts(copyListItems);
    };


    useEffect(() => {
        var d = [...charts];
        d.push(addItem);
        var addItemClone = { ...addItem };
        d.forEach((chart, index) => {
            chart.index = index;
            if (chart.action == "#add") {
                addItemClone.index = index;
            }
        });
        setAddItem(addItemClone);
        setCharts(d);
    }, []);

    const gridItem = (graph) => {
        return (
            <div className="col-6 mr-4 py-2 pr-2 graph-item" key={graph.index}>
                <div className={"p-2 border-1 w-full h-full surface-border surface-card border-round  cursor-pointer flex justify-content-left align-items-center" + (graph.index == addItem.index ? " surface-900 hover:surface-800 text-white" : " hover:surface-100 active:surface-400")}
                    onClick={() => { itemClick(graph); }}
                    draggable={graph.index != addItem.index}
                    onDragStart={(e) => dragStart(e, graph.index)}
                    onDragEnter={(e) => dragEnter(e, graph.index)}
                    onDragLeave={(e) => dragLeave(e, graph.index)}
                    onDragEnd={drop}>
                    <div className="flex align-items-center justify-content-left">
                        <i className={"text-2xl mr-3 pi " + (graph.icon ?? "pi-chart-bar")}></i>
                        <div className="text-s">{graph.title}</div>
                    </div>
                </div>
            </div>
        );
    };

    const itemClick = (graph) => {
        if (graph.action == "#add") {
            // new
            setCurrentGraph(0);
        } else {
            // edit
            setCurrentGraph(graph.id);
        }
    };

    return (
        <div className="field grid" key={id}>
            <label htmlFor="helpText" className="col-fixed mb-0" style={{ width: '150px' }}>{id}</label>
            <div className="input" style={{ flex: '1', flexWrap: 'wrap' }}>
                <DataView value={charts} itemTemplate={gridItem} layout="grid" />
            </div>
        </div>
    );
}

export default ChartsComponent;