import React, { useState, useEffect, useRef } from 'react';

import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';

import ApiService from '../../../service/ApiService';
import { Toast } from 'primereact/toast';
import ModifyPageForm from './partials/ModifyPage';

const PageManager = (props) => {
    const [properties, setProperties] = useState(null);
    const [editPageVisible, setEditPageVisible] = useState(null);
    const [currentEditId, setCurrentEditId] = useState(null);
    const [modalHeader, setModalHeader] = useState(null);
    const toast = useRef(null);

    useEffect(() => {
        const apiService = new ApiService();

        //This is from a generic JSON just an example
        apiService.getAllProperties().then((properties) => {
            setProperties(properties);
        });

    }, []);

    const editData = (rowData) => {
        setModalHeader("Editing: " + nameTemplate(rowData));
        setCurrentEditId(rowData.pageIdentifier);
        setEditPageVisible(true);
    };

    const editButton = (rowData) => {
        return <div className="editButton">
            <Button type="button" icon="pi pi-pencil" label="Edit" className="p-button p-button-outlined" onClick={() => editData(rowData)} />
        </div>;
    };

    const actionButtons = (rowData) => {
        return <>
            {editButton(rowData)}
        </>
    };

    const getYesNoButton = (flag) => {
        if (flag) {
            return <Button style={{ width: "60px", background: "#689F38", border: "none" }} icon="pi pi-check" />

        } else {
            return <Button style={{ width: "60px", background: "#D32F2F", border: "none" }} icon="pi pi-times" />
        }
    };

    const refreshPage = () => {
        const apiService = new ApiService();

        //This is from a generic JSON just an example
        apiService.getAllProperties().then((properties) => {
            setProperties(properties);
        });
    };
    const yesNoTemplate = (rowData) => {
        if (rowData.pageIdentifier.startsWith("KPIS")) {
            return getYesNoButton(rowData.disableForecasting);
        } else {
            return "N/A";
        }
    };
    const typeTemplate = (rowData) => {
        var t = rowData.pageIdentifier.split("_")[0];
        switch (t) {
            case "TRENDS":
                return "Trends";
            case "KPIS":
                return "KPIs";
            case "HISTORY":
                return "Historical Values"
        }

        return t;
    };

    const nameTemplate = (rowData) => {
        var t = rowData.pageIdentifier.substring(rowData.pageIdentifier.indexOf("_") + 1).replace(/\_/g, " ").toLowerCase();
        const words = t.split(" ");

        for (let i = 0; i < words.length; i++) {
            if (words[i].length >= 1) {
                words[i] = words[i][0].toUpperCase() + words[i].substr(1);
            }
        }
        return typeTemplate(rowData) + " > " + words.join(" ");
    };


    return (
        <>
            <Toast ref={toast} position="top-left" />
            <div className="col-12">
                <div className="grid p-fluid">
                    <div className="card">
                        <div className="header-card">
                            <h3>Manage your pages</h3>

                            <div className="rhs-row-header">
                                <Button label="Help" className="p-button-outlined" />
                            </div>

                        </div>
                        <div className="dataValuesTable">

                            <div className="col-1 col-offset-11">
                                <div className="grid p-fluid">
                                    <Button label="Refresh" className="p-button-outlined" icon="pi pi-refresh" onClick={(e) => refreshPage()} />
                                </div>
                            </div>
                            <DataTable value={properties} tableStyle={{ minWidth: '50rem', width: '100%' }}>
                                <Column field="pageIdentifier" body={nameTemplate} header="Identifier"></Column>
                                <Column field="disableForecasting" body={yesNoTemplate} header="Disable Forecasting"></Column>
                                <Column field="helpText" header="Help Text"></Column>

                                <Column style={{ minWidth: '50px' }} body={actionButtons}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog header={modalHeader} visible={editPageVisible} style={{ width: '30vw', minWidth: '300px' }} onHide={() => setEditPageVisible(false)}>
                <ModifyPageForm id={currentEditId} setEditPageVisible={setEditPageVisible} toast={toast} refreshPage={refreshPage} />
            </Dialog>

        </>
    );
}

export default PageManager;