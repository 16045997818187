import React, { useEffect, useState } from 'react';
import InputTextComponent from '../../partials/form/fields/InputTextComponent';
import InputTextareaComponent from '../../partials/form/fields/InputTextareaComponent';
import ChartsComponent from '../../partials/form/fields/ChartsComponent';
import CheckboxListComponent from '../../partials/form/fields/CheckboxListComponent';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import DropdownComponent from '../../partials/form/fields/DropdownComponent';
import InputSwitchComponent from '../../partials/form/fields/InputSwitchComponent';
import GraphEditPage from './GraphEditPage';


const TestingPage = (props) => {
    const [currentGraph, setCurrentGraph] = useState(null);
    const [formData, setFormData] = useState({
        pageTitle: '',
        helpText: '',
        disableForecasting: false,
        graphs: [
            {
                title: "Aquisition vs Attrition",
                id: 1,
            },
            {
                title: "Test Chart",
                id: 2,
            },
            {
                title: "Totals",
                id: 3,
            },
            {
                title: "Testing Testing Testing",
                id: 4,
            }
        ],
        buttons: [],
        button1Enabled: false,
        button2Enabled: false,
        button3Enabled: false,
        defaultStatistic: '0',
        button1Text: "Number of Gifts",
        button2Text: "KPI",
        button3Text: "Income"
    });
    const [formErrors, setFormErrors] = useState({
        pageTitle: null

    });

    //validateForm
    const validateForm = () => {
        var errors = {};
        if (!formData.pageTitle || formData.pageTitle == '') {
            errors.pageTitle = "Page Title is required";
        }
        return errors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        var errors = validateForm();
        if (errors) {
            setFormErrors(errors);
            return;
        }
        props.onSave(formData);
    };

    const inputChanged = (value, field) => {
        var d = { ...formData };
        d[field] = value;
        setFormData(d);
    };
    const inputChangedWithIndex = (value, field, index) => {
        var d = { ...formData };
        d[field][index] = value;
        if (index == 0) {
            d.button1Enabled = value;
        } else if (index == 1) {
            d.button2Enabled = value;
        } if (index == 2) {
            d.button3Enabled = value;
        }
        setFormData(d);
    };


    return (
        <>
            <div className="card surface-card pr-6">
                <form onSubmit={handleSubmit} className="p-fluid custom-form">
                    <h6>General</h6>
                    <hr />
                    <InputTextComponent
                        id="pageTitle"
                        value={formData.pageTitle}
                        onChange={(value) => inputChanged(value, 'pageTitle')}
                    />
                    {formErrors.pageTitle && <small id="pageTitle-help" className="p-error block">{formErrors.pageTitle}</small>}

                    <InputTextareaComponent
                        id="helpText"
                        value={formData.helpText}
                        onChange={(value) => inputChanged(value, 'helpText')}
                    />

                    <InputSwitchComponent
                        id="disableForecasting"
                        checked={formData.disableForecasting}
                        onChange={(value) => inputChanged(value, 'disableForecasting')}
                    />

                    <ChartsComponent
                        id="graphs"
                        items={formData.graphs}
                        onChange={(value) => inputChanged(value, 'graphs')}
                        setCurrentGraph={setCurrentGraph}
                    />

                    <CheckboxListComponent
                        id="buttons"
                        items={[
                            { id: 'button1', label: 'Statistic 1 (No. of Gifts)', value: 0 },
                            { id: 'button2', label: 'Statistic 2 (Percentage/KPI)', value: 1 },
                            { id: 'button3', label: 'Statistic 3 (Income)', value: 2 }
                        ]}
                        selectedItems={formData.buttons}
                        onChange={(e) => {
                            inputChangedWithIndex(e.checked, 'buttons', e.value);
                        }}
                    />

                    <DropdownComponent
                        id="defaultStatistic"
                        options={[
                            { code: '0', name: 'Statistic 1' },
                            { code: '1', name: 'Statistic 2' },
                            { code: '2', name: 'Statistic 3' }
                        ]}
                        value={formData.defaultStatistic}
                        onChange={(value) => inputChanged(value, 'defaultStatistic')}
                    />
                    {formData.buttons.filter((x) => { return x; }).length > 0 ? <>
                        <h6>Button Text</h6>
                        <hr />
                        {formData.buttons[0] ?
                            <InputTextComponent
                                id="button1Text"
                                value={formData.button1Text}
                                onChange={(value) => inputChanged(value, 'button1Text')}
                            /> : <></>}

                        {formData.buttons[1] ?
                            <InputTextComponent
                                id="button2Text"
                                value={formData.button2Text}
                                onChange={(value) => inputChanged(value, 'button2Text')}
                            /> : <></>}
                        {formData.buttons[2] ?
                            <InputTextComponent
                                id="button3Text"
                                value={formData.button3Text}
                                onChange={(value) => inputChanged(value, 'button3Text')}
                            /> : <></>}
                    </> : <></>}
                    <div className="field grid">
                        <div className="col-fixed" style={{ width: '150px' }}></div>
                        <div className="input" style={{ flex: '1' }}>
                            <Button type="submit" label="Submit" className="mt-2 mb-2 btn-custom" style={{ width: '150px' }} />
                        </div>
                    </div>
                </form>
            </div>
            <Dialog header="Add/Edit Graph" visible={currentGraph != null} onHide={() => { setCurrentGraph(null); }} style={{ width: '30vw', minWidth: '300px' }}>
                <GraphEditPage items={currentGraph} />
            </Dialog>
        </>
    );
}

export default TestingPage;