import React, { useEffect, useState } from 'react';
import KpiPage from './KpiPage';

const DashboardPage = (props) => {
    const year = 2022; // Example year
    const pageId = 14; // Example page ID

    return (
        <KpiPage year={year} pageId={pageId} isDashboard={true} pageIdentifier={props.pageIdentifier} />
    );
};

export default DashboardPage;