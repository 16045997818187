import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import DropdownComponent from './DropdownComponent';
import ApiService from '../../../service/ApiService';

const GraphKPIsComponent = ({ graphKPIs, onChange }) => {
    const [selectedKPI, setSelectedKPI] = useState('');
    const [showAddKpi, setShowAddKpi] = useState(false);
    const [ddlOptions, setDDLOptions] = useState([]);
    const [formErrors, setFormErrors] = useState([]);
    const [kpiNames, setKPINames] = useState([]);
    const [editingId, setEditingId] = useState(0);

    useEffect(() => {
        var apiService = new ApiService();
        var names = [];
        apiService.getAllKpis().then((data) => {
            data.map((item) => { names[item.id] = item.name });
            setKPINames(names);
            var itms = data.map((item) => ({ name: item.name, code: item.id }));
            itms.unshift({ name: 'Please select...', code: '' });
            setDDLOptions(itms);
        });
    }, []);

    const handleEdit = (kpi) => {
        setSelectedKPI(kpi.kpiId);
        if (kpi && kpi.id) {
            setEditingId(kpi.id);
        } else {
            setEditingId(0);
        }
        setShowAddKpi(true);
    };

    const handleDelete = (kpi) => {
        const updatedKPIs = graphKPIs.filter((item) => item !== kpi);
        onChange(updatedKPIs);
    };
    const handleSelectKPISubmit = (e) => {
        e.preventDefault();
        var errs = [];
        var errors = false;
        if (selectedKPI == null || selectedKPI == '') {
            errs.ddlKPI = "Please select a KPI.";
            errors = true;
        }

        setFormErrors(errs);

        if (errors) {
            return;
        }

        // do submit
        if (editingId > 0) {
            var itmIndex = graphKPIs.findIndex((x) => x.id == editingId);
            graphKPIs[itmIndex].kpiId = selectedKPI;
            graphKPIs[itmIndex].kpiName = kpiNames[selectedKPI];
        } else {
            graphKPIs.push({ kpiName: kpiNames[selectedKPI], kpiId: selectedKPI });
            for (var i = 0; i < graphKPIs.length; i++) {
                graphKPIs[i].id = i + 1;
            } 
        }
        onChange(graphKPIs);
        setShowAddKpi(false);
    };

    const renderActionButtons = (kpi) => (
        <div className="p-grid p-justify-between">
            <Button
                icon="pi pi-pencil"
                tooltip="Edit"
                tooltipOptions={{ position: 'top' }}
                className="p-button-rounded p-button-success mr-2"
                onClick={(e) => { e.preventDefault(); handleEdit(kpi) }}
            />
            <Button
                icon="pi pi-trash"
                tooltip="Delete"
                tooltipOptions={{ position: 'top' }}
                className="p-button-rounded p-button-danger"
                onClick={(e) => { e.preventDefault(); handleDelete(kpi); }}
            />
        </div>
    );


    return (
        <div>
            <h6>Graph KPIs</h6>
            <div className="grid">
                <div className="col-3">
                    <Button
                        label="Add KPI"
                        icon="pi pi-plus"
                        onClick={(e) => { e.preventDefault(); handleEdit('') }}
                        className="p-button-rounded p-button-primary"
                    />
                </div>
            </div>
            <div className="grid">
                <div className="col-12">
                    <DataTable
                        value={graphKPIs}
                        resizableColumns
                        selectionMode="single"
                        selection={selectedKPI}
                        onSelectionChange={(e) => { setSelectedKPI(e.value); }}
                        size="small"

                    >
                        <Column field="kpiName" header="KPI Name" sortable />
                        <Column
                            header="Actions"
                            body={renderActionButtons}
                            sortable={false}
                            filter={false}
                        />
                    </DataTable>
                </div>
            </div>
            <Dialog header="Select KPI" visible={showAddKpi} onHide={() => setShowAddKpi(false)} style={{ width: '30vw', minWidth: '300px' }}>
                <div className="p-fluid custom-form">
                    <DropdownComponent
                        id="selectedKPI"
                        options={ddlOptions}
                        value={selectedKPI}
                        onChange={(value) => setSelectedKPI(value)}
                    />
                    {formErrors.ddlKPI && (
                        <small id="ddlKPI-help" className="p-error block">
                            {formErrors.ddlKPI}
                        </small>
                    )}
                    <Button
                        label="Submit"
                        className="mt-2 mb-2"
                        onClick={(e) => handleSelectKPISubmit(e)}
                        style={{ width: '150px', float: 'right' }}
                    />
                </div>
            </Dialog>
        </div>
    );
};

export default GraphKPIsComponent;