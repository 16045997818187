import React, { useState, useEffect, useRef } from 'react';

import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import { Dropdown } from 'primereact/dropdown';
import { Sidebar } from 'primereact/sidebar';

import ApiService from '../../../service/ApiService';

import ChartData from '../../report/ChartData';
import TableData from '../../report/TableData';

import { useCookies } from 'react-cookie';
import StatPanel from '../../report/StatPanel';


const KpiPage6Col = (props) => {
    const itemId = props.id;
    const pageId = props.pageId;
    const [dashboardData, setDashboardData] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [dropdownValues, setDropdownValues] = useState(null);
    const [buttonOptionsGeneric, setButtonOptionsGeneric] = useState({
        data: [
            {
                name: "Annual Comparison",
                code: "AC"
            },
            {
                name: "Industry Comparison",
                code: "IC"
            }
        ]
    });
    const [buttonOptions, setButtonOptions] = useState("IC");
    const [cookies, setCookie] = useCookies(['selected_year']);
    const [year, setSelectedYear] = useState(parseInt(cookies.selected_year ?? new Date().getFullYear() - 1));
    const apiService = new ApiService();
    const [forecastData, setForecastData] = useState({});
    const [forecastTable, setForecastTable] = useState(null);
    const [isForecasting, setIsForecasting] = useState(false);
    const [showHelpPane, setShowHelpPane] = useState(false);
    const [pageProperties, setPageProperties] = useState({
        disableForecasting: true,
        helpText: ''
    });
    const [ClientDataId] = useCookies(['ClientDataId']);



    useEffect(() => {
        apiService.getYears(ClientDataId).then(data => setDropdownValues(data));
        setSelectedYear(parseInt(cookies.selected_year ?? new Date().getFullYear() - 1));
    }, []);


    useEffect(() => {

        try {
            apiService.getReport(ClientDataId, year, pageId).then((data) => { setIsForecasting(false); setDashboardData(data); });
            apiService.getForecastFigures(ClientDataId, year).then((data) => {
                var fd = {};
                data.map(function (value, index) {
                    fd[value.id] = value.value;
                });
                setForecastData(fd);
                console.log(fd);
            });

            apiService.getPerformanceTable(ClientDataId, year).then((data) => setTableData(data));

            if (props.hideAnnualComparison) {
                setButtonOptions("IC");
                setButtonOptionsGeneric(null);
            }
        } catch (error) {
            // Handle error
            console.log(error);
        }
        setCookie("selected_year", year, { path: '/' });

    }, [year]);

    useEffect(() => {
        apiService.getPageDataProperties(props.pageIdentifier).then((data) => setPageProperties(data));
    }, [props.pageIdentifier]);


    useEffect(() => {
        updateForecastTable(forecastData);
    }, [forecastData]);

    const updateForecast = (id, value) => {
        setIsForecasting(true);
        var fd = forecastData;
        fd[id] = value;
        setForecastData(fd);
        updateForecastTable(fd);
    };

    const updateForecastTable = (fd) => {

        setForecastTable({
            tableName: (year + 1) + " Forecast Performance",
            rows: [
                {
                    cells: [
                        {
                            data: "Revenue",
                            colSpan: 1
                        },
                        {
                            data: "£" + (((fd[1] + fd[2]) * fd[4]) * fd[5]).toLocaleString("en-GB", { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                            colSpan: 1
                        },
                        {
                            "data": "",
                            "colour": "",
                            "colSpan": 1
                        },
                        {
                            "data": "",
                            "colour": "",
                            "colSpan": 1
                        }
                    ]
                }
            ]
        });

    };

    const resetForcastTable = (event) => {
        apiService.getReport(ClientDataId, year, pageId).then((data) => { setIsForecasting(false); setDashboardData(data); });
        apiService.getForecastFigures(ClientDataId, year).then((data) => {
            var fd = {};
            data.map(function (value, index) {
                fd[value.id] = value.value;
            });
            setForecastData(fd);
            updateForecastTable(fd);
        });

    };

    const getChangeDifference = () => {
        var curValue = parseFloat(tableData.rows[0].cells[1].data.replace('£', '').replace(/,/g, ""));
        var forecastValue = parseFloat(forecastTable.rows[0].cells[1].data.replace('£', '').replace(/,/g, ""));
        console.log(curValue, forecastValue);
        return ((forecastValue - curValue) >= 0 ? "+" : "-") + "£" + Math.abs((forecastValue - curValue)).toLocaleString("en-GB", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    };

    const getChangeColour = () => {
        var curValue = parseFloat(tableData.rows[0].cells[1].data.replace('£', '').replace(/,/g, ""));
        var forecastValue = parseFloat(forecastTable.rows[0].cells[1].data.replace('£', '').replace(/,/g, ""));
        return (forecastValue - curValue) > 0 ? "text-green-500" : "text-pink-700";
    };

    const getIncrease = () => {

        var curValue = parseFloat(tableData.rows[0].cells[1].data.replace('£', '').replace(/,/g, ""));
        var forecastValue = parseFloat(forecastTable.rows[0].cells[1].data.replace('£', '').replace(/,/g, ""));
        var change = ((forecastValue / curValue) * 100) - 100;
        return (<>{((change) >= 0 ? "+" : "-")}{Math.abs(change).toLocaleString("en-GB", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + "%"} {change > 0 ? getUp() : getDown()}</>);
    };
    const getUp = () => {
        return (<i className="pi pi-arrow-circle-up"></i>);
    };
    const getDown = () => {
        return (<i className="pi pi-arrow-circle-down"></i>);
    };
    const getIncreaseColour = () => {
        var curValue = parseFloat(tableData.rows[0].cells[1].data.replace('£', '').replace(/,/g, ""));
        var forecastValue = parseFloat(forecastTable.rows[0].cells[1].data.replace('£', '').replace(/,/g, ""));
        var change = ((forecastValue / curValue) * 100) - 100;
        return (change) > 0 ? "text-green-500" : "text-pink-700";
    };

    const renderHTML = (rawHTML) => React.createElement("p", { dangerouslySetInnerHTML: { __html: rawHTML } });

    return (
        <>
            {/*header*/}
            <div className="col-12">
                <div className="grid p-fluid grid-row-header">
                    <div className="card">
                        {dashboardData != null ? <h3>{dashboardData.title}</h3> : ''}

                        <div className="rhs-row-header">
                            {buttonOptionsGeneric != null ? (
                                <SelectButton
                                    value={buttonOptions}
                                    onChange={(e) => setButtonOptions(e.value)}
                                    options={buttonOptionsGeneric.data}
                                    optionLabel="name"
                                    optionValue="code"
                                />
                            ) : (
                                ''
                            )}
                            {dropdownValues != null ? (
                                <Dropdown
                                    value={year}
                                    onChange={(e) => setSelectedYear(e.value)}
                                    options={dropdownValues}
                                    optionLabel="name"
                                    placeholder="Select"
                                />
                            ) : (
                                ''
                            )}
                            {pageProperties != null && pageProperties.helpText != '' && pageProperties.helpText != null &&
                                <Button label="Help" className="p-button-outlined mr-2 mb-2" onClick={() => setShowHelpPane(true)} />
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/*row data*/}
            <div className="col-12">
                <div className="grid p-fluid grid-row-data">
                    {dashboardData != null ? (
                        dashboardData.boxes.filter(x => x.charts != null && x.charts.length > 0).map((box, index) => (
                            <ChartData key={index} boxes={box} width="6" isDashboard={props.isDashboard} buttonOptions={buttonOptions} updateForecast={updateForecast} isForecasting={isForecasting} year={year} />
                        ))
                    ) : (
                        ''
                    )}
                </div>
            </div>

            {/*tables*/}
            {!pageProperties.disableForecasting ? <>
                <div className="grid p-fluid grid-row-data">
                    {tableData ? <>
                        <div className="col-12 md:col-6 lg:col-4">
                            <StatPanel data={{
                                tableName: year + " Revenue",
                                showIncrease: false,
                                value: tableData.rows[0].cells[1].data,
                                icon: "pi-pound text-white",
                                iconBgClass: "bg-blue"
                            }} />
                        </div>

                        {isForecasting ?
                            <>
                                <div className="col-12 md:col-6 lg:col-4">
                                    <StatPanel data={{
                                        tableName: "Forecast Change",
                                        showIncrease: true,
                                        value: getChangeDifference(),
                                        valueClass: getChangeColour(),
                                        increase: getIncrease(),
                                        increaseClass: getIncreaseColour(),
                                        icon: "pi-chart-line text-white",
                                        iconBgClass: "bg-blue",
                                        showReset: true
                                    }} resetTable={resetForcastTable} />
                                </div>
                                <div className="col-12 md:col-6 lg:col-4 mr-0">
                                    <StatPanel data={{
                                        tableName: (year + 1) + " Revenue",
                                        showIncrease: false,
                                        value: forecastTable.rows[0].cells[1].data,
                                        icon: "pi-pound text-white",
                                        iconBgClass: "bg-blue"
                                    }} />
                                </div>
                            </>
                            : <></>}</>
                        : <></>}
                </div></>
                : <></>}

            {/*tables*/}
            <div className="grid p-fluid grid-row-tables">
                {dashboardData != null ? (
                    dashboardData.boxes.filter(x => x.tables != null && x.tables.length > 0).map((box, index) => (
                        <div className="col-6">
                            <div className="card">
                                <div className="table-row-top">
                                    <h3>{index == 0 ? "Gifts" : "Donors"}</h3>
                                </div>
                                <div className="line-tables">
                                    {box.tables.length > 1 ?
                                        <div className="custom-side-table">
                                            <div className="table-custom">
                                                <div className="p-datatable p-component p-datatable-responsive-stack">
                                                    <div className="p-datatable-wrapper">
                                                        <TableData key={"tableCompL_" + year} data={box.tables[0]} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="table-custom" style={{ width: "100%" }}>
                                            <div className="p-datatable p-component p-datatable-responsive-stack">
                                                <div className="p-datatable-wrapper">
                                                    <TableData key={"tableCompL_" + year} data={box.tables[0]} />
                                                </div>
                                            </div>
                                        </div>}
                                    {box.tables.length > 1 ?
                                        <div className="custom-mid-table">
                                            <div className="table-custom">
                                                <div className="p-datatable p-component p-datatable-responsive-stack">
                                                    <div className="p-datatable-wrapper">
                                                        <TableData key={"tableCompM_" + year} data={box.tables[1]} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : <></>}
                                    {box.tables.length > 2 ?
                                        <div className="custom-side-table">
                                            <div className="table-custom">
                                                <div className="p-datatable p-component p-datatable-responsive-stack">
                                                    <div className="p-datatable-wrapper">
                                                        <TableData key={"tableCompR_" + year} data={box.tables[2]} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : <></>}
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    ''
                )}
            </div>
            <Sidebar visible={showHelpPane} position="right" onHide={() => setShowHelpPane(false)} className="p-sidebar-md">
                <h2>Help</h2>
                {renderHTML(pageProperties.helpText)}
            </Sidebar>

        </>
    );
};

export default KpiPage6Col;