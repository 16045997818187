import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import InputSwitchComponent from '../../partials/form/fields/InputSwitchComponent';
import InputTextComponent from '../../partials/form/fields/InputTextComponent';
import DropdownComponent from '../../partials/form/fields/DropdownComponent';
import GraphKPIsComponent from '../../partials/form/fields/GraphKPIsComponent';
import InputTextareaComponent from '../../partials/form/fields/InputTextareaComponent';


const GraphEditPage = (items) => {
    const [formData, setFormData] = useState({
        graphTitle: '',
        chartGuide: {
            title: '',
            text: '',
            openByDefault: false,
        },
        graphKPIs: [{
            id: 1,
            kpiId: 1,
            kpiName: 'Aqcuisition Rate'
        }],
        graphType: 'Line',
        valueType: "KPIValue"
    });

    const [formErrors, setFormErrors] = useState({
        graphTitle: null,
    });

    const validateForm = () => {
        const errors = {};
        if (!formData.graphTitle || formData.graphTitle === '') {
            errors.graphTitle = 'Graph Title is required';
        }
        return errors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validateForm();
        if (Object.keys(errors).length === 0) {
            //props.onSave(formData);
            console.log(formData);
        } else {
            setFormErrors(errors);
        }
    };

    const inputChanged = (value, field) => {
        const updatedFormData = { ...formData };
        updatedFormData[field] = value;
        setFormData(updatedFormData);
    };
    const inputChangedMulti = (value, field, field2) => {
        const updatedFormData = { ...formData };
        updatedFormData[field][field2] = value;
        setFormData(updatedFormData);
    };

    // Function to handle changes in graphKPIs
    const handleGraphKPIsChange = (updatedGraphKPIs) => {
        setFormData({ ...formData, graphKPIs: updatedGraphKPIs });
    };

    const getAllowedGraphTypes = () => {
        if (!formData.graphKPIs || formData.graphKPIs.length == 0) {
            return [];
        }
        if (formData.graphKPIs.length == 1) {
            return ['Line', 'Bar', 'Pie'];
        }
        else if (formData.graphKPIs.length == 2) {
            return ['Line', 'Bar', 'BarStacked', 'Doughnut'];
        } else {
            return ['Line', 'Bar', 'BarStacked', 'Pie'];
        }
    };

    return (
        <form onSubmit={handleSubmit} className="p-fluid custom-form">
            <h6>General</h6>
            <hr />
            <InputTextComponent
                id="graphTitle"
                value={formData.graphTitle}
                onChange={(value) => inputChanged(value, 'graphTitle')}
            />
            {formErrors.graphTitle && (
                <small id="graphTitle-help" className="p-error block">
                    {formErrors.graphTitle}
                </small>
            )}

            <GraphKPIsComponent
                graphKPIs={formData.graphKPIs}
                onChange={handleGraphKPIsChange}
            />

            <DropdownComponent
                id="graphType"
                options={getAllowedGraphTypes()}
                value={formData.graphType}
                onChange={(value) => inputChanged(value, 'graphType')}
            />

            <DropdownComponent
                id="valueType"
                options={[
                    "NoOfGifts",
                    "NoOfDonors",
                    "IncomeValue",
                    "AverageGiftSize",
                    "AverageDonorSize",
                    "KPIValue",
                    "SubKPIValue",
                    "SubKPI2Value"
                ]}
                value={formData.valueType}
                onChange={(value) => inputChanged(value, 'valueType')}
            />

            <h6>Guide Text</h6>
            <hr />

            <InputTextComponent
                id="chartGuide_title"
                value={formData.chartGuide.title}
                onChange={(value) => inputChangedMulti(value, 'chartGuide', 'title')}
            />
            <InputTextareaComponent
                id="chartGuide_text"
                value={formData.chartGuide.text}
                onChange={(value) => inputChangedMulti(value, 'chartGuide', 'text')}
            />
            <InputSwitchComponent
                id="chartGuide_openByDefault"
                checked={formData.chartGuide.openByDefault}
                onChange={(value) => inputChangedMulti(value, 'chartGuide', 'openByDefault')}
            />

            <Button
                type="submit"
                label="Save"
                className="mt-2 mb-2"
                style={{ width: '150px', float: 'right' }}
            />
        </form>
    );
};

export default GraphEditPage;