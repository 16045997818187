import axios from 'axios';
import { createContext, useState, useEffect } from 'react';
const AuthContext = createContext();

const AuthService = ({ children, isAuthenticated, setIsAuthenticated, privLevel }) => {


    const login = async (username, password, rememberMe) => {
        try {
            const response = await axios.post('/api/auth/login', {
                email: username,
                password: password,
                rememberMe: rememberMe
            });

            const { data } = response;
            if (data) {
                localStorage.setItem('isAuthenticated', 'true');
                setIsAuthenticated(true);
                return true;
            }
        } catch (error) {
            localStorage.setItem('isAuthenticated', 'false');
            setIsAuthenticated(false);
            console.log(error);
            return error;
        }
    };
    const logout = async () => {
        try {
            await axios.post('/api/auth/logout');
            setIsAuthenticated(false);
            localStorage.setItem('isAuthenticated', 'false');
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                login,
                logout,
                privLevel
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};


export { AuthService, AuthContext };