import KpiPage from './KpiPage';

const AttritionPage = (props) => {
    const year = 2022; // Example year
    const pageId = 17; // Example page ID


    return (
        <KpiPage year={year} pageId={pageId} pageIdentifier={props.pageIdentifier} />
    );
};

export default AttritionPage;