import React from 'react';
import { Dropdown } from 'primereact/dropdown';

function DropdownComponent({ id, value, options, onChange, itemTemplate, valueTemplate }) {
    return (
        <div className="field grid">
            <label htmlFor={id} className="col-fixed mb-0" style={{ width: '150px' }}>{id}</label>
            <div className="input" style={{ flex: '1' }}>
                {Array.isArray(options) && typeof(options[0]) === "string" ? 
                <Dropdown
                    id={id}
                    value={value}
                    options={options}
                    onChange={(e) => onChange(e.value)}
                    className="p-component"
                    itemTemplate={itemTemplate}
                    valueTemplate={valueTemplate}
                    />
                    :
                    <Dropdown
                        id={id}
                        value={value}
                        options={options}
                        optionValue="code"
                        optionLabel="name"
                        onChange={(e) => onChange(e.value)}
                        className="p-component"
                        itemTemplate={itemTemplate}
                        valueTemplate={valueTemplate}
                    />}
            </div>
        </div>
    );
}

export default DropdownComponent;