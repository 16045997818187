import KpiPage from './KpiPage';

const ValuePage = (props) => {
    const year = 2022; // Example year
    const pageId = 18; // Example page ID


    return (
        <KpiPage year={year} pageId={pageId} pageIdentifier={props.pageIdentifier} />
    );
};

export default ValuePage;