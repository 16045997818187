import React, { useState, useRef, useContext } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { AuthContext } from '../../service/AuthService';
import { Toast } from 'primereact/toast';
import { useHistory, useLocation } from 'react-router-dom';
import Cookies from "js-cookie";

const LoginPage = () => {
    const { login } = useContext(AuthContext);
    const history = useHistory();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [checked, setChecked] = useState(false);
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const toast = useRef(null);

    var retUrl = params.get("ReturnUrl");
    console.log(retUrl);

    const handleLogin = async () => {

        // Perform validation
        if (!username || !password) {
            // Display error message for missing username or password
            toast.current.show({ severity: 'error', summary: 'Username or Password Invalid', detail: 'Please ensure you have entered a valid username and password.' });
            return;
        }

        const success = await login(username, password, checked);
        if (success == true) {
            if (retUrl != null) {
                history.push(retUrl);
            } else {
                history.push('/components/pages/report-manager/manager'); // do full page redirect
            }
        } else {
            // Handle login failure
            // For example, display an error message
            toast.current.show({ severity: 'error', summary: 'Failed to Login', detail: success.response.data });

        }
    };

    return (
        <div className="login-body">
            <Toast ref={toast} position="top-left" />
            <div className="card login-panel p-fluid">
                <div className="login-panel-content">
                    <div className="grid">
                        <div className="col-12 logo-container">
                            <h2>Login</h2>
                            <p>Please enter your login details below.</p>
                        </div>
                        <div className="col-12 username-container">
                            <label>Username</label>
                            <div className="login-input">
                                <InputText
                                    id="input"
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-12 password-container">
                            <label>Password</label>
                            <div className="login-input">
                                <InputText
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-12 sm:col-6 md:col-6 rememberme-container">
                            <Checkbox
                                inputId="remember-me"
                                name="remember-me"
                                checked={checked}
                                onChange={(e) => setChecked(e.checked)}
                            />
                            <label htmlFor="remember-me"> Remember me</label>
                        </div>

                        <div className="col-12 sm:col-6 md:col-6 forgetpassword-container">
                            <a href="/" className="forget-password">
                                Forget Password
                            </a>
                        </div>

                        <div className="col-12 sm:col-6 md:col-6">
                            <Button
                                className="btn-custom"
                                label="Sign In"
                                icon="pi pi-check"
                                onClick={handleLogin}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;