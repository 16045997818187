import React, { useState, useEffect } from 'react';

import { useCookie } from 'react-cookie';

import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { SelectButton } from 'primereact/selectbutton';
import { Fieldset } from 'primereact/fieldset';

import { Chart } from 'primereact/chart';

import ApiService from '../../service/ApiService';

import { useCookies } from 'react-cookie';

const GenericSingleGraphic = (props) => {

    const [title, setTitle] = useState(null);
    const [mainGraph, setMainGraph] = useState(null);
    const [graphOptions, setGraphOptions] = useState(null);
    const [graphOptionsArray, setGraphOptionsArray] = useState([]);
    const [buttonOptionsGeneric, setButtonOptionsGeneric] = useState(null);
    const [buttonOption, setButtonOption] = useState(props.type ?? "1");
    const [ClientDataId] = useCookies(['ClientDataId']);
    const [showHelpPane, setShowHelpPane] = useState(false);
    const apiService = new ApiService();
    const [pageProperties, setPageProperties] = useState({
        disableForecasting: true,
        helpText: '',
        chartTexts: []
    });


    const [trendLineOptions, setTrendLineOptions] = useState({
        borderColor: "#455ED1",
        borderWidth: "2",
        hoverWidth: "2",
        elements: {
            point: {
                pointStyle: 'line'
            },
            tooltip: {
                enabled: false
            }
        },
        order: 2
    });

    const [baseLineOptions, setbaseLineOptions] = useState({
        borderColor: "#ED7D31",
        borderWidth: "4",
        hoverWidth: "4",
        elements: {
            point: {
                pointStyle: 'line'
            },
            tooltip: {
                enabled: false
            }
        },
        order: 3
    });

    const renderHTML = (rawHTML) => React.createElement("p", { dangerouslySetInnerHTML: { __html: rawHTML } });

    useEffect(() => {
        apiService.getPageDataProperties(props.pageIdentifier).then((data) => setPageProperties(data));
    }, [props.pageIdentifier]);


    const getTrendLineData = (data) => {

        // Calculate the sum of x and y values
        const sumX = data.reduce((sum, _, index) => sum + index, 0);
        const sumY = data.reduce((sum, value) => sum + value, 0);

        // Calculate the mean of x and y values
        const meanX = sumX / data.length;
        const meanY = sumY / data.length;

        // Calculate the differences from the mean
        const diffX = data.map((_, index) => index - meanX);
        const diffY = data.map(value => value - meanY);

        // Calculate the slope (m)
        const numerator = diffX.reduce((sum, value, index) => sum + value * diffY[index], 0);
        const denominator = diffX.reduce((sum, value) => sum + value * value, 0);
        const slope = numerator / denominator;

        // Calculate the y-intercept (b)
        const intercept = meanY - slope * meanX;

        return data.map((_, index) => slope * index + intercept);
    };
    function hexToRgbA(hex, alpha) {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
        }
        throw new Error('Bad Hex');
    }

    const createDataSet = (items, baseLine, format, decimalPlaces, lineColour, dsTitle) => {
        var graphOptionsAry = [];
        if (format || decimalPlaces) {
            graphOptionsAry.push({
                custom: {
                    format: format,
                    dp: decimalPlaces ?? 0
                }
            });
            setGraphOptionsArray(graphOptionsAry);
        }
        var obj = {
            datasets: []
        };
        var baseLineLine = {
            ...baseLineOptions,
            data: [baseLine, ...[...Array(items.length - 2)].map(() => { return null; }), baseLine],
            fill: false,
            label: "Sector Average",
            backgroundColor: "#fff",
            tension: 0,
            spanGaps: true
        };

        if (props.dataIsMultiple) {
            if (!props.combineCharts) {
                var multipleObjs = [];
                for (var i = 0; i < items.length; i++) {

                    graphOptionsAry.push({
                        custom: {
                            format: items[i].format,
                            dp: items[i].decimalPlaces ?? 0,
                        }
                    });

                    var tlD = getTrendLineData(items[i].items.map(a => a.value));
                    baseLineLine = {
                        ...baseLineOptions,
                        data: [items[i].baseLine, ...[...Array(items[i].items.length - 2)].map(() => { return null; }), items[i].baseLine],
                        fill: false,
                        label: "Sector Average",
                        backgroundColor: "#fff",
                        tension: 0,
                        spanGaps: true
                    };
                    var trendLine = {};
                    if (!props.hideTrendline) {
                        trendLine = {
                            ...trendLineOptions,
                            label: "Trend Line",
                            data: tlD,
                            fill: false,
                            backgroundColor: "#fff",
                            tension: 0
                        };
                    }
                    var ds = [];
                    if (!props.hideTrendline) {
                        ds.push(trendLine);
                    }
                    if (items[i].baseLine != null && items[i].baseLine != 0) {
                        ds.push(baseLineLine);
                    }
                    ds.push({
                        data: items[i].items.map(a => a.value),
                        borderColor: items[i].lineColour ?? "#B2BEB5",
                        label: items[i].title ?? dsTitle,
                        tension: .4,

                        pointRadius: 5,
                        pointHoverRadius: 9,
                        order: 1,
                    });
                    multipleObjs.push({
                        name: items[i].title ?? dsTitle,
                        chart: {
                            datasets: ds,
                            labels: items[i].items.map(a => a.text)
                        }
                    });
                }
                setGraphOptionsArray(graphOptionsAry);
                return multipleObjs;
            }

            var labels = [];
            for (var i = 0; i < items.length; i++) {
                var data = [];
                for (var j = 0; j < items[i].items.length; j++) {
                    var yr = parseInt(items[i].items[j].text);
                    if (!labels.includes(yr)) {
                        labels.push(yr);
                    }
                    data.push(items[i].items[j].value);
                }

                baseLineLine = {
                    ...baseLineOptions,
                    data: [items[i].baseLine, ...[...Array(items[i].items.length - 2)].map(() => { return null; }), items[i].baseLine],
                    fill: false,
                    label: "Sector Average",
                    backgroundColor: "#fff",
                    tension: 0,
                    spanGaps: true
                };
                if (items[i].baseLine != null && items[i].baseLine != 0) {
                    obj.datasets.push(baseLineLine);
                }

                obj.datasets.push({
                    data: data,
                    borderColor: lineColour,
                    label: items[i].title ?? dsTitle,
                    tension: .4,
            pointRadius: 5,
                    pointHoverRadius: 9,
                });
            }
            obj.labels = labels.sort();
            return obj;
        } else {
            var labels = [];
            var data = [];
            for (var i = 0; i < items.length; i++) {
                labels.push(items[i].text);
                data.push(items[i].value);
            }
            var trendLine = {};
            if (!props.hideTrendline) {
                trendLine = {
                    ...trendLineOptions,
                    label: "Trend Line",
                    data: getTrendLineData(data),
                    fill: false,
                    backgroundColor: "#fff",
                    tension: 0
                };
            }
            if (baseLine != null && baseLine != 0) {
                baseLineLine = {
                    ...baseLineOptions,
                    data: [baseLine, ...[...Array(items.length - 2)].map(() => { return null; }), baseLine],
                    fill: false,
                    label: "Sector Average",
                    backgroundColor: "#fff",
                    tension: 0,
                    spanGaps: true
                };
                return {
                    labels: labels,
                    datasets: [{
                        data: data,
                        borderColor: lineColour,
                        label: title ?? dsTitle,
                        tension: .4,

                        pointRadius: 5,
                        pointHoverRadius: 9,
                    }, trendLine,
                        baseLineLine]
                };

            } else {
                return {
                    labels: labels,
                    datasets: [{
                        data: data,
                        borderColor: lineColour,
                        label: title ?? dsTitle,
                        tension: .4,

                        pointRadius: 5,
                        pointHoverRadius: 9,
                    }, trendLine]
                };

            }

        }
    };

    function initButtons() {
        if (props.buttonOptions) {
            setButtonOptionsGeneric(props.buttonOptions);
            if (buttonOption == null || props.buttonOptions.filter(x => x.value == buttonOption).length == 0) {
                setButtonOption(props.buttonOptions[0].value);
            }
        }
    }

    useEffect(async () => {


        var apiService = new ApiService();
        setMainGraph(null);
        if (!props.dataIsMultiple) {

            apiService.getTrendData(ClientDataId, props.chartTypeId).then(td => {
                setTitle(td.title);
                setMainGraph(createDataSet(td.items, td.baseLine, td.format, td.decimalPlaces, td.lineColour ?? "#B2BEB5", td.title));
                initButtons();
            });


        } else {
            if (props.useGiftValue) {
                apiService.getTrendDataFromGiftValue(ClientDataId, props.chartTypeId).then(td => {
                    setTitle(props.title);
                    setMainGraph(createDataSet(td));
                    initButtons();
                });

            } else {
                apiService.getTrendDataFromReport(ClientDataId, props.reportPageId, buttonOption).then(td => {
                    setTitle(props.title);
                    setMainGraph(createDataSet(td));
                    initButtons();
                });
            }
        }

        setGraphOptions({
            maintainAspectRatio: true,
            aspectRatio: 1.77,
            borderWidth: 4,
            pointBackgroundColor: (context) => {
                return context.dataset.borderColor;
            },
            //backgroundColor: (context) => {
            //    const ctx = context.chart.ctx;
            //    const gradient = ctx.createLinearGradient(0, 0, 0, 500);
            //    gradient.addColorStop(0, hexToRgbA(context.dataset.borderColor, 0.8));
            //    gradient.addColorStop(1, hexToRgbA(context.dataset.borderColor, 0));
            //    return gradient;
            //},
            backgroundColor: (context) => {
                const ctx = context.chart.ctx;
                const gradient = ctx.createLinearGradient(0, 0, 0, 500);
                gradient.addColorStop(0, hexToRgbA("#fff", 0));
                gradient.addColorStop(1, hexToRgbA("#fff", 0));
                return gradient;
            },
            fill: true,
            plugins: {
                tooltips: {
                },
                tooltip: {
                    filter: function (tooltipItem) {
                        return tooltipItem.dataset.label != "Sector Average" && tooltipItem.dataset.label != "Trend Line";
                    },
                    callbacks: {
                        label: function (context) {
                            if (!this._chart.config._config.options) {
                                return label;
                            }
                            var item = this._chart.config._config.options.custom;
                            if (!item) {
                                return label;
                            }

                            let label = "" + context.dataset.label || '';

                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed.y !== null) {
                                switch (item.format?.toLowerCase()) {
                                    case "percentage":
                                        label += context.parsed.y.toLocaleString('en-GB', { maximumFractionDigits: 2 }) + '%';
                                        break;
                                    case "currency":
                                        label += "£" + context.parsed.y.toLocaleString('en-GB', { maximumFractionDigits: 2 });
                                        break;
                                    default:
                                        label += context.parsed.y.toLocaleString('en-GB', { maximumFractionDigits: 2 });
                                        break;


                                }
                            }
                            return label;
                        }
                    }
                }
            },
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        callback: function (label, index, labels) {
                            if (!this.chart.config._config.options) {
                                return label;
                            }
                            var item = this.chart.config._config.options.custom;
                            if (!item) {
                                return label;
                            }
                            switch (item.format?.toLowerCase()) {
                                case "percentage":
                                    return label.toFixed(item.dp ?? 0) + '%';
                                case "currency":
                                    if (label >= 1000000) {
                                        return "£" + (label / 1000000).toLocaleString('en-GB', { maximumFractionDigits: 3 }) + "m";
                                    }
                                    if (label >= 1000) {
                                        return "£" + (label / 1000).toLocaleString('en-GB', { maximumFractionDigits: 3 }) + "k";
                                    }
                                    return "£" + label.toLocaleString('en-GB', { maximumFractionDigits: item.dp });

                            }
                            return label.toLocaleString('en-GB', { maximumFractionDigits: item.dp });
                        }
                    }
                }
            }
        });

    }, [buttonOption]);


    return (
        <>
            {/*header*/}
            <div className="col-12">
                <div className="grid p-fluid grid-row-header">
                    <div className="card">
                        {
                            title != null ?
                                <h3>{title}</h3>
                                : ""
                        }

                        <div className="rhs-row-header">
                            {buttonOptionsGeneric != null ? (
                                <SelectButton
                                    value={buttonOption}
                                    onChange={(e) => setButtonOption(e.value)}
                                    options={buttonOptionsGeneric}
                                    optionLabel="name"
                                />
                            ) : (
                                ''
                            )}
                            {pageProperties != null && pageProperties.helpText != '' && pageProperties.helpText != null &&
                                <Button label="Help" className="p-button-outlined mr-2 mb-2" onClick={() => setShowHelpPane(true)} />
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/*row data*/}
            {
                mainGraph != null ?
                    props.dataIsMultiple ?
                        !props.combineCharts ?
                            <>
                                {mainGraph.map((item, index) => {
                                    return <div key={index}>
                                        <div className="col-12">
                                            <div className="grid">
                                                <div className="col-6">
                                                    <div className="grid">
                                                        {pageProperties != null && pageProperties.chartTexts != null && pageProperties.chartTexts.length > index ?
                                                            <Fieldset legend={pageProperties.chartTexts[index].title} toggleable collapsed={!pageProperties.chartTexts[index].openByDefault}>
                                                                {renderHTML(pageProperties.chartTexts[index].text)}
                                                            </Fieldset>
                                                            : <></>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="grid">
                                                <div className="col-6">
                                                    <div className="grid p-fluid data-graphic">
                                                        <div className="card">
                                                            <h4><strong>{item.name}</strong></h4>
                                                            <Chart type="line" key={"graph_" + props.chartTypeId + "_" + props.reportPageId + "_" + index} options={{ ...graphOptions, ...graphOptionsArray[index] }} data={item.chart} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </> :
                            <>
                                <div className="col-12">
                                    <div className="grid">
                                        <div className="col-6">
                                            <div className="grid">
                                                {pageProperties != null && pageProperties.chartTexts != null && pageProperties.chartTexts.length > 0 ?
                                                    <Fieldset legend={pageProperties.chartTexts[0].title} toggleable collapsed={!pageProperties.chartTexts[0].openByDefault}>
                                                        {renderHTML(pageProperties.chartTexts[0].text)}
                                                    </Fieldset>
                                                    : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="grid">
                                        <div className="col-6">
                                            <div className="grid p-fluid data-graphic">
                                                <div className="card">
                                                    <h4><strong>{title}</strong></h4>
                                                    <Chart type="line" key={"graph_" + props.chartTypeId + "_" + props.reportPageId} options={{ ...graphOptions, ...graphOptionsArray[0] }} data={mainGraph} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </> :
                        <>
                            <div className="col-12">
                                <div className="grid">
                                    <div className="col-6">
                                        <div className="grid">
                                            {pageProperties != null && pageProperties.chartTexts != null && pageProperties.chartTexts.length > 0 ?
                                                <Fieldset legend={pageProperties.chartTexts[0].title} toggleable collapsed={!pageProperties.chartTexts[0].openByDefault}>
                                                    {renderHTML(pageProperties.chartTexts[0].text)}
                                                </Fieldset>
                                                : <></>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="grid">
                                    <div className="col-6">
                                        <div className="grid p-fluid data-graphic">
                                            <div className="card">
                                                <h4><strong>{title}</strong></h4>
                                                <Chart type="line" key={"graph_" + props.chartTypeId + "_" + props.reportPageId} options={{ ...graphOptions, ...graphOptionsArray[0] }} data={mainGraph} />
                                            </div>
                                        </div>
                                    </div>
                                </div>          
                             </div>
                        </>
                    : <></>
            }
            <Sidebar visible={showHelpPane} position="right" onHide={() => setShowHelpPane(false)} className="p-sidebar-md">
                <h2>Help</h2>
                {renderHTML(pageProperties.helpText)}
            </Sidebar>
        </>
    );
}

export default GenericSingleGraphic;