/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import { useCookie } from 'react-cookie';

import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { SelectButton } from 'primereact/selectbutton';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';

import { Chart } from 'primereact/chart';

import ApiService from '../../service/ApiService';

import { useCookies } from 'react-cookie';

const PropTrendsReport = (props) => {

    const [title, setTitle] = useState("Proportional Income Trends");
    const [mainGraph, setMainGraph] = useState(null);
    const [graphOptions, setGraphOptions] = useState(null);
    const [graphOptionsArray, setGraphOptionsArray] = useState([]);
    const [ClientDataId] = useCookies(['ClientDataId']);
    const [showHelpPane, setShowHelpPane] = useState(false);
    const apiService = new ApiService();
    const [pageProperties, setPageProperties] = useState({
        disableForecasting: true,
        helpText: '',
        chartTexts: []
    });
    const [trendLineOptions, setTrendLineOptions] = useState({
        borderColor: "#455ED1",
        borderWidth: "2",
        hoverWidth: "2",
        elements: {
            point: {
                pointStyle: 'line'
            },
            tooltip: {
                enabled: false
            }
        },
        order: 2
    });

    const [baseLineOptions, setbaseLineOptions] = useState({
        borderColor: "#ED7D31",
        borderWidth: "4",
        hoverWidth: "4",
        elements: {
            point: {
                pointStyle: 'line'
            },
            tooltip: {
                enabled: false
            }
        },
        order: 3
    });

    const renderHTML = (rawHTML) => React.createElement("p", { dangerouslySetInnerHTML: { __html: rawHTML } });

    useEffect(() => {

        apiService.getPageDataProperties(props.pageIdentifier).then((data) => setPageProperties(data));
    }, [props.pageIdentifier]);

    const getTrendLineData = (data) => {

        // Calculate the sum of x and y values
        const sumX = data.reduce((sum, _, index) => sum + index, 0);
        const sumY = data.reduce((sum, value) => sum + value, 0);

        // Calculate the mean of x and y values
        const meanX = sumX / data.length;
        const meanY = sumY / data.length;

        // Calculate the differences from the mean
        const diffX = data.map((_, index) => index - meanX);
        const diffY = data.map(value => value - meanY);

        // Calculate the slope (m)
        const numerator = diffX.reduce((sum, value, index) => sum + value * diffY[index], 0);
        const denominator = diffX.reduce((sum, value) => sum + value * value, 0);
        const slope = numerator / denominator;

        // Calculate the y-intercept (b)
        const intercept = meanY - slope * meanX;

        return data.map((_, index) => slope * index + intercept);
    };
    function hexToRgbA(hex, alpha) {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
        }
        throw new Error('Bad Hex');
    }

    const createDataSet = (items, dsTitle, graphOptions) => {
        var graphOptionsAry = graphOptions || [];
        var baseLineLine = {};
        var multipleObjs = [];
        for (var i = 0; i < items.length; i++) {

            graphOptionsAry.push({
                custom: {
                    format: items[i].format,
                    dp: items[i].decimalPlaces ?? 0,
                }
            });

            var tlD = getTrendLineData(items[i].items.map(a => a.value));
            baseLineLine = {
                ...baseLineOptions,
                data: [items[i].baseLine, ...[...Array(items[i].items.length - 2)].map(() => { return null; }), items[i].baseLine],
                fill: false,
                label: "Sector Average",
                backgroundColor: "#fff",
                tension: 0,
                spanGaps: true
            };
            var trendLine = {};
            if (!props.hideTrendline) {
                trendLine = {
                    ...trendLineOptions,
                    label: "Trend Line",
                    data: tlD,
                    fill: false,
                    backgroundColor: "#fff",
                    tension: 0
                };
            }
            var ds = [];
            if (!props.hideTrendline) {
                ds.push(trendLine);
            }
            if (items[i].baseLine != null && items[i].baseLine != 0) {
                ds.push(baseLineLine);
            }
            ds.push({
                data: items[i].items.map(a => a.value),
                borderColor: items[i].lineColour ?? "#B2BEB5",
                label: items[i].title ?? dsTitle,
                tension: .4,

                pointRadius: 5,
                pointHoverRadius: 9,
                order: 1,
            });
            multipleObjs.push({
                name: dsTitle,
                chart: {
                    datasets: ds,
                    labels: items[i].items.map(a => a.text)
                }
            });
        }
        setGraphOptionsArray(graphOptionsAry);
        graphOptions = graphOptionsAry;
        return multipleObjs;
    };


    useEffect(async () => {


        var apiService = new ApiService();

        var newDonorIncomeOfTotal = await apiService.getTrendData(ClientDataId, 8);
        var recapturedDonorIncomeOfTotal = await apiService.getTrendData(ClientDataId, 10);

        var combinedDataAq = [];

        newDonorIncomeOfTotal.items.map((x, i) => {
            combinedDataAq.push(x.value + recapturedDonorIncomeOfTotal.items[i].value);
        });

        var newDonorsRetainedIncome = await apiService.getTrendData(ClientDataId, 13);
        var repeatDonorsRetainedIncome = await apiService.getTrendData(ClientDataId, 14);

        var combinedDataRet = [];

        newDonorsRetainedIncome.items.map((x, i) => {
            combinedDataRet.push(x.value + repeatDonorsRetainedIncome.items[i].value);
        });


        var barOptions = {
            custom: {
                format: newDonorIncomeOfTotal.format,
                dp: newDonorIncomeOfTotal.decimalPlaces ?? 0,
            },
            scales: {
                x: {
                    stacked: true
                },
                y: {
                    max: 100,
                    stacked: true,
                    beginAtZero: true,
                    ticks: {
                        callback: function (label, index, labels) {
                            if (!this.chart.config._config.options) {
                                return label;
                            }
                            var item = this.chart.config._config.options.custom;
                            if (!item) {
                                return label;
                            }
                            switch (item.format?.toLowerCase()) {
                                case "percentage":
                                    return label.toFixed(item.dp ?? 0) + '%';
                                case "currency":
                                    if (label >= 1000000) {
                                        return "£" + (label / 1000000).toLocaleString('en-GB', { maximumFractionDigits: 3 }) + "m";
                                    }
                                    if (label >= 1000) {
                                        return "£" + (label / 1000).toLocaleString('en-GB', { maximumFractionDigits: 3 }) + "k";
                                    }
                                    return "£" + label.toLocaleString('en-GB', { maximumFractionDigits: item.dp });
                                default:
                                    return label.toLocaleString('en-GB', { maximumFractionDigits: item.dp });
                            }
                        }
                    }
                }
            },
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                tooltip: {
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        label: function (context) {
                            if (!this._chart.config._config.options) {
                                return label;
                            }
                            var item = this._chart.config._config.options.custom;
                            if (!item) {
                                return label;
                            }

                            let label = "" + context.dataset.label || '';

                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed.y !== null) {
                                switch (item.format?.toLowerCase()) {
                                    case "percentage":
                                        label += context.parsed.y.toLocaleString('en-GB', { maximumFractionDigits: 2 }) + '%';
                                        break;
                                    case "currency":
                                        label += "£" + context.parsed.y.toLocaleString('en-GB', { maximumFractionDigits: 2 });
                                        break;
                                    default:
                                        label += context.parsed.y.toLocaleString('en-GB', { maximumFractionDigits: 2 });
                                        break;


                                }
                            }
                            return label;
                        }
                    }
                }
            }
        };
        var graphOptions = [barOptions, barOptions];


        var dsBasic = [{
            type: 'bar',
            data: combinedDataAq,
            backgroundColor: "#4caf50",
            label: "Acquisition Income (Combined)",
            borderWidth: 0
        },
        {
            type: 'bar',
            data: combinedDataRet,
            backgroundColor: "#443ecb",
            label: "Retention Income (Combined)",
            borderWidth: 0
        }];

        var dsDetailed = [{
            type: 'bar',
            data: newDonorIncomeOfTotal.items.map(x => x.value.toFixed(1)),
            backgroundColor: "#4caf50",
            label: "New Donor Income",
            borderWidth: 0
        },
        {
            type: 'bar',
            data: recapturedDonorIncomeOfTotal.items.map(x => x.value.toFixed(1)),
            backgroundColor: "#78c47b",
            label: "Recaptured Income",
            borderWidth: 0
        },
        {
            type: 'bar',
            data: newDonorsRetainedIncome.items.map(x => x.value.toFixed(1)),
            backgroundColor: "#93d095",
            label: "New Retained Income",
            borderWidth: 0
        },
        {
            type: 'bar',
            data: repeatDonorsRetainedIncome.items.map(x => x.value.toFixed(1)),
            backgroundColor: "#b7e0b8",
            label: "Repeat Retained Income",
            borderWidth: 0
        }];

        setMainGraph([{
            name: "Income Trends: Basic",
            chart: {
                datasets: dsBasic,
                labels: newDonorIncomeOfTotal.items.map(a => a.text)
            }
        },
        {
            name: "Income Trends: Detailed",
            chart: {
                datasets: dsDetailed,
                labels: newDonorIncomeOfTotal.items.map(a => a.text)
            }
            },
            createDataSet([newDonorIncomeOfTotal], "Annual Income from New Donors", graphOptions)[0],
            createDataSet([recapturedDonorIncomeOfTotal], "Annual Income from Recaptured Donors", graphOptions)[0],
            createDataSet([newDonorsRetainedIncome], "Annual Income from Newly Retained Donors", graphOptions)[0],
            createDataSet([repeatDonorsRetainedIncome], "Annual Income from Repeat Retained Donors", graphOptions)[0],
        ]);


        setGraphOptions({
            maintainAspectRatio: true,
            aspectRatio: 1.77,
            borderWidth: 4,
            pointBackgroundColor: (context) => {
                return context.dataset.borderColor;
            },
            //backgroundColor: (context) => {
            //    const ctx = context.chart.ctx;
            //    const gradient = ctx.createLinearGradient(0, 0, 0, 500);
            //    gradient.addColorStop(0, hexToRgbA(context.dataset.borderColor, 0.8));
            //    gradient.addColorStop(1, hexToRgbA(context.dataset.borderColor, 0));
            //    return gradient;
            //},
            backgroundColor: (context) => {
                const ctx = context.chart.ctx;
                const gradient = ctx.createLinearGradient(0, 0, 0, 500);
                gradient.addColorStop(0, hexToRgbA("#fff", 0));
                gradient.addColorStop(1, hexToRgbA("#fff", 0));
                return gradient;
            },
            fill: true,
            plugins: {
                tooltips: {
                },
                tooltip: {
                    filter: function (tooltipItem) {
                        return tooltipItem.dataset.label != "Sector Average" && tooltipItem.dataset.label != "Trend Line";
                    },
                    callbacks: {
                        label: function (context) {
                            if (!this._chart.config._config.options) {
                                return label;
                            }
                            var item = this._chart.config._config.options.custom;
                            if (!item) {
                                return label;
                            }

                            let label = "" + context.dataset.label || '';

                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed.y !== null) {
                                switch (item.format?.toLowerCase()) {
                                    case "percentage":
                                        label += context.parsed.y.toLocaleString('en-GB', { maximumFractionDigits: 2 }) + '%';
                                        break;
                                    case "currency":
                                        label += "£" + context.parsed.y.toLocaleString('en-GB', { maximumFractionDigits: 2 });
                                        break;
                                    default:
                                        label += context.parsed.y.toLocaleString('en-GB', { maximumFractionDigits: 2 });
                                        break;


                                }
                            }
                            return label;
                        }
                    }
                }
            },
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        callback: function (label, index, labels) {
                            if (!this.chart.config._config.options) {
                                return label;
                            }
                            var item = this.chart.config._config.options.custom;
                            if (!item) {
                                return label;
                            }
                            switch (item.format?.toLowerCase()) {
                                case "percentage":
                                    return label.toFixed(item.dp ?? 0) + '%';
                                case "currency":
                                    if (label >= 1000000) {
                                        return "£" + (label / 1000000).toLocaleString('en-GB', { maximumFractionDigits: 3 }) + "m";
                                    }
                                    if (label >= 1000) {
                                        return "£" + (label / 1000).toLocaleString('en-GB', { maximumFractionDigits: 3 }) + "k";
                                    }
                                    return "£" + label.toLocaleString('en-GB', { maximumFractionDigits: item.dp });
                                default:
                                    return label.toLocaleString('en-GB', { maximumFractionDigits: item.dp });
                            }
                        }
                    }
                }
            }
        });

    }, [ClientDataId]);


    return (
        <>
            {/*header*/}
            <div className="col-12">
                <div className="grid p-fluid grid-row-header">
                    <div className="card">
                        {
                            title != null ?
                                <h3>{title}</h3>
                                : ""
                        }

                        <div className="rhs-row-header">
                            {pageProperties != null && pageProperties.helpText != '' && pageProperties.helpText != null &&
                                <Button label="Help" className="p-button-outlined mr-2 mb-2" onClick={() => setShowHelpPane(true)} />
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/*row data*/}
            {
                mainGraph != null ?
                    mainGraph.map((item, index) => {
                        return <div key={index}>
                            <div className="col-12">
                                <div className="grid">
                                    <div className="col-6">
                                        <div className="grid">
                                            {pageProperties != null && pageProperties.chartTexts != null && pageProperties.chartTexts.length > index ?
                                                <Fieldset legend={pageProperties.chartTexts[index].title} toggleable collapsed={!pageProperties.chartTexts[index].openByDefault}>
                                                    {renderHTML(pageProperties.chartTexts[index].text)}
                                                </Fieldset>
                                                : <></>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="grid">
                                    <div className="col-6">
                                        <div className="grid p-fluid data-graphic">
                                            <div className="card">
                                                <h4><strong>{item.name}</strong></h4>
                                                <Chart type="line" key={"graph_" + props.chartTypeId + "_" + props.reportPageId + "_" + index} options={{ ...graphOptions, ...graphOptionsArray[index] }} data={item.chart} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }) : <></>
            }
            <Sidebar visible={showHelpPane} position="right" onHide={() => setShowHelpPane(false)} className="p-sidebar-md">
                <h2>Help</h2>
                {renderHTML(pageProperties.helpText)}
            </Sidebar>
        </>
    );
}

export default PropTrendsReport;