import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import ApiService from './service/ApiService';
import { AuthContext } from './service/AuthService';
import { useCookies } from 'react-cookie';

import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';

const AppTopbar = () => {
    const [userData, setUserData] = useState(null);
    const history = useHistory();
    const [ClientDataId] = useCookies(['ClientDataId']);
    const { logout } = useContext(AuthContext);

    useEffect(() => {
        const apiService = new ApiService();

        apiService.getTopBar(ClientDataId).then((userData) => {
            setUserData(userData);
        });
    }, []);

    return (
        <>
            {
                userData != null ?
                    <div className="layout-topbar">
                        <button type="button" className="p-link layout-right-panel-button layout-topbar-icon" onClick={async () => {
                            confirmDialog({
                                message: 'Are you sure you want to sign out?',
                                header: 'Confirmation',
                                icon: 'pi pi-exclamation-triangle',
                                accept: async () => {
                                    await logout();
                                    history.push('/account/login');
                                },
                                reject: () => {
                                }
                            });

                        }}>
                            <i className="pi pi-sign-out"></i>
                        </button>
                        <ConfirmDialog />

                        <button type="button" className="p-link layout-topbar-logo" onClick={() => history.push('/')}>
                            <img id="topbar-logo" src="/assets/layout/images/FFT-LOGO.png" alt="FFT V2" />
                        </button>

                        <ul className="topbar-menu">
                            <li className="user-profile">
                                <button type="button" className="p-link" onClick={() => history.push('/')}>
                                    <div className="layout-profile-userinfo">
                                        <span className="layout-profile-name">{userData[0].name}{userData[0].organisationName ? " | " + userData[0].organisationName : ""}</span>
                                    </div>
                                </button>
                            </li>
                        </ul>
                    </div>
                    : ""
            }
        </>
    )
}

export default AppTopbar;