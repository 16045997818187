import React, { useState, useEffect } from 'react';

import { useCookie } from 'react-cookie';

import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { SelectButton } from 'primereact/selectbutton';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';

import { Chart } from 'primereact/chart';

import ApiService from '../../../service/ApiService';

import { useCookies } from 'react-cookie';

const MonthlyRevenue = (props) => {

    const [title, setTitle] = useState("Monthly Giving Trends");
    const [mainGraph, setMainGraph] = useState(null);
    const [graphOptions, setGraphOptions] = useState(null);
    const [graphOptionsArray, setGraphOptionsArray] = useState([]);
    const [buttonOptionsGeneric, setButtonOptionsGeneric] = useState([
        { name: "Combined/total", value: "40" },
        { name: "Gift less than £100", value: "41" },
        { name: "Gifts between £100 and £250", value: "42" },
        { name: "Gifts between £250 and £1,000", value: "43" },
        { name: "Gifts between £1,000 and £5,000", value: "44" },
        { name: "Gifts more than £5,000", value: "45" },
    ]);
    const [buttonOption, setButtonOption] = useState("40");
    const [ClientDataId] = useCookies(['ClientDataId']);
    const [showHelpPane, setShowHelpPane] = useState(false);
    const [cookies, setCookie] = useCookies(['selected_year']);
    const [year, setSelectedYear] = useState(parseInt(cookies.selected_year ?? new Date().getFullYear() - 1));
    const apiService = new ApiService();
    const [dropdownValues, setDropdownValues] = useState({});
    const [pageProperties, setPageProperties] = useState({
        disableForecasting: true,
        helpText: '',
        chartTexts: []
    });    

    const renderHTML = (rawHTML) => React.createElement("p", { dangerouslySetInnerHTML: { __html: rawHTML } });



    useEffect(() => {
        apiService.getYears(ClientDataId)
            .then(data => data.map(x => { x.name = x.name.replace(" vs previous year", "").replace("Year End: ", ""); return x; }))
            .then(data => {
                setDropdownValues(data);
                setSelectedYear(parseInt(cookies.selected_year ?? data[0].value));
            });

        apiService.getPageDataProperties(props.pageIdentifier).then((data) => setPageProperties(data));
    }, [props.pageIdentifier]);
    
    function hexToRgbA(hex, alpha) {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
        }
        throw new Error('Bad Hex');
    }

    const createDataSet = (items) => {
        var graphOptionsAry = [];
        var obj = {
            datasets: []
        };

        var multipleObjs = [];
        for (var i = 0; i < items.length; i++) {

            graphOptionsAry.push({
                custom: {
                    format: items[i].format,
                    dp: items[i].decimalPlaces ?? 0,
                }
            });

            var ds = [];
            ds.push({
                data: items[i].items.map(a => a.value),
                borderColor: items[i].lineColour ?? "#B2BEB5",
                label: items[i].title ?? "",
                tension: .4,

                pointRadius: 5,
                pointHoverRadius: 9,
                order: 1,
            });
            multipleObjs.push({
                name: items[i].title ?? "",
                chart: {
                    datasets: ds,
                    labels: items[i].items.map(a => a.text)
                }
            });
        }
        setGraphOptionsArray(graphOptionsAry);
        return multipleObjs;
    };
    

    useEffect(async () => {


        var apiService = new ApiService();

        if (!buttonOption) {
            setButtonOption("40");
            return;
        }

        if (!year) {
            setSelectedYear("40");
            return;
        }
        var tdGifts = await apiService.getMonthlyTrendData(ClientDataId, buttonOption, 0, year);
        var tdIncome = await apiService.getMonthlyTrendData(ClientDataId, buttonOption, 2, year);

        tdGifts.title = "Total Gifts Processed (" + buttonOptionsGeneric.filter(x => x.value == buttonOption)[0].name + ")";
        tdIncome.title = "Total Income Recieved (" + buttonOptionsGeneric.filter(x => x.value == buttonOption)[0].name + ")";
        var cData = [
            tdGifts,
            tdIncome
        ];

        setMainGraph(createDataSet(cData)); 


        setGraphOptions({
            maintainAspectRatio: true,
            aspectRatio: 1.77,
            borderWidth: 4,
            pointBackgroundColor: (context) => {
                return context.dataset.borderColor;
            },
            //backgroundColor: (context) => {
            //    const ctx = context.chart.ctx;
            //    const gradient = ctx.createLinearGradient(0, 0, 0, 500);
            //    gradient.addColorStop(0, hexToRgbA(context.dataset.borderColor, 0.8));
            //    gradient.addColorStop(1, hexToRgbA(context.dataset.borderColor, 0));
            //    return gradient;
            //},
            backgroundColor: (context) => {
                const ctx = context.chart.ctx;
                const gradient = ctx.createLinearGradient(0, 0, 0, 500);
                gradient.addColorStop(0, hexToRgbA("#fff", 0));
                gradient.addColorStop(1, hexToRgbA("#fff", 0));
                return gradient;
            },
            fill: true,
            plugins: {
                tooltips: {
                },
                tooltip: {
                    filter: function (tooltipItem) {
                        return tooltipItem.dataset.label != "Sector Average" && tooltipItem.dataset.label != "Trend Line";
                    },
                    callbacks: {
                        label: function (context) {
                            if (!this._chart.config._config.options) {
                                return label;
                            }
                            var item = this._chart.config._config.options.custom;
                            if (!item) {
                                return label;
                            }

                            let label = "" + context.dataset.label || '';

                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed.y !== null) {
                                switch (item.format?.toLowerCase()) {
                                    case "percentage":
                                        label += context.parsed.y.toLocaleString('en-GB', { maximumFractionDigits: 2 }) + '%';
                                        break;
                                    case "currency":
                                        label += "£" + context.parsed.y.toLocaleString('en-GB', { maximumFractionDigits: 2 });
                                        break;
                                    default:
                                        label += context.parsed.y.toLocaleString('en-GB', { maximumFractionDigits: 2 });
                                        break;


                                }
                            }
                            return label;
                        }
                    }
                }
            },
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        callback: function (label, index, labels) {
                            if (!this.chart.config._config.options) {
                                return label;
                            }
                            var item = this.chart.config._config.options.custom;
                            if (!item) {
                                return label;
                            }
                            switch (item.format?.toLowerCase()) {
                                case "percentage":
                                    return label.toFixed(item.dp ?? 0) + '%';
                                case "currency":
                                    if (label >= 1000000) {
                                        return "£" + (label / 1000000).toLocaleString('en-GB', { maximumFractionDigits: 3 }) + "m";
                                    }
                                    if (label >= 1000) {
                                        return "£" + (label / 1000).toLocaleString('en-GB', { maximumFractionDigits: 3 }) + "k";
                                    }
                                    return "£" + label.toLocaleString('en-GB', { maximumFractionDigits: item.dp });
                                default:
                                    return label.toLocaleString('en-GB', { maximumFractionDigits: item.dp });
                            }
                        }
                    }
                }
            }
        });

        setCookie("selected_year", year, { path: '/' });
    }, [buttonOption, ClientDataId, year]);


    return (
        <>
            {/*header*/}
            <div className="col-12">
                <div className="grid p-fluid grid-row-header">
                    <div className="card">
                        {
                            title != null ?
                                <h3>{title}</h3>
                                : ""
                        }

                        <div className="rhs-row-header">
                            <span style={{ marginRight: "10px", fontWeight: "bold" }}>Donor Classification: </span>
                            {buttonOptionsGeneric != null ? (
                                <Dropdown
                                    value={buttonOption}
                                    onChange={(e) => setButtonOption(e.value)}
                                    options={buttonOptionsGeneric}
                                    optionLabel="name"
                                />
                            ) : (
                                ''
                            )}
                            <span style={{ marginRight: "10px", fontWeight: "bold" }}>Year End: </span>
                            {dropdownValues != null ? (
                                <Dropdown
                                    value={year}
                                    onChange={(e) => setSelectedYear(e.value)}
                                    options={dropdownValues}
                                    optionLabel="name"
                                    placeholder="Select"
                                />
                            ) : (
                                ''
                            )}
                            {pageProperties != null && pageProperties.helpText != '' && pageProperties.helpText != null &&
                                <Button label="Help" className="p-button-outlined mr-2 mb-2" onClick={() => setShowHelpPane(true)} />
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/*row data*/}
            {
                mainGraph != null ?                
                    mainGraph.map((item, index) => {
                        return <div key={index}>
                            <div className="col-12">
                                <div className="grid">
                                    <div className="col-6">
                                        <div className="grid">
                                            {pageProperties != null && pageProperties.chartTexts != null && pageProperties.chartTexts.length > index ?
                                                <Fieldset legend={pageProperties.chartTexts[index].title} toggleable collapsed={!pageProperties.chartTexts[index].openByDefault}>
                                                    {renderHTML(pageProperties.chartTexts[index].text)}
                                                </Fieldset>
                                                : <></>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="grid">
                                    <div className="col-6">
                                        <div className="grid p-fluid data-graphic">
                                            <div className="card">
                                                <h4><strong>{item.name}</strong></h4>
                                                <Chart type="line" key={"graph_" + props.chartTypeId + "_" + props.reportPageId + "_" + index} options={{ ...graphOptions, ...graphOptionsArray[index] }} data={item.chart} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }) : <></>
            }
            <Sidebar visible={showHelpPane} position="right" onHide={() => setShowHelpPane(false)} className="p-sidebar-md">
                <h2>Help</h2>
                {renderHTML(pageProperties.helpText)}
            </Sidebar>
        </>
    );
}

export default MonthlyRevenue;