import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import ApiService from '../../../../service/ApiService';

import InputTextComponent from '../../../../partials/form/fields/InputTextComponent';
import InputTextareaComponent from '../../../../partials/form/fields/InputTextareaComponent';
import InputSwitchComponent from '../../../../partials/form/fields/InputSwitchComponent';

const ModifyPageForm = (props) => {
    const [performValidation, setPerformValidation] = useState(false);
    const [formData, setFormData] = useState({
        id: 0,
        pageIdentifier: '',
        helpText: '',
        chartCount: 0,
        chartTexts: [],
        disableForecasting: false
    });
    const [formErrors, setFormErrors] = useState({
        name: ''
    });


    useEffect(() => {
        if (props.id) {
            var apiService = new ApiService();
            apiService.getPageDataProperties(props.id).then((data) => {
                var d = { ...data };
                if (d.helpText == null) {
                    d.helpText = '';
                }
                d.helpText = d.helpText.replace(/<br\s*\/?>/gi, "\n");
                if (d.chartTexts == null) {
                    d.chartTexts = [];
                }
                if (d.chartTexts.length < d.chartCount) {
                    for (var i = 0; i < d.chartCount; i++) {
                        if (d.chartTexts[i] == null) {
                            d.chartTexts[i] = {};
                        }
                    }

                }
                setFormData(d);
            });
        }
    }, [props.id]);

    useEffect(() => {
        if (performValidation) {
            validateForm();
        }

    }, [performValidation, formData]);


    const handleSubmit = (event) => {
        event.preventDefault();
        setPerformValidation(true);
        if (validateForm()) {
            var apiService = new ApiService();

            var d = { ...formData };
            if (!formData.helpText || formData.helpText === '') {
                delete d["helpText"];
                setFormData(d);
            }


            apiService.updateProperty(d).then((data) => {
                if (data.success) {
                    props.refreshPage();
                    props.setEditPageVisible(false);
                    props.toast.current.show({ severity: 'success', summary: 'Property Updated Successfully.', detail: '' });
                } else {
                    props.toast.current.show({ severity: 'error', summary: 'Property Update Failed.', detail: data.message });
                }
            });
        }
    };

    const inputChanged = (value, field) => {
        var d = { ...formData };
        d[field] = value;
        setFormData(d);
    };
    const inputChangedWithIndex = (value, field, index, field2) => {
        var d = { ...formData };
        d[field][index][field2] = value;
        setFormData(d);
    };

    const validateForm = () => {
        let isValid = true;
        const errors = {
        };

        setFormErrors(errors);
        return isValid;
    };

    return (
        <form onSubmit={handleSubmit} className="p-fluid custom-form">
            <h6>General</h6>
            <hr />

            <InputTextareaComponent
                id="helpText"
                value={formData.helpText}
                onChange={(value) => inputChanged(value, 'helpText')}
                rows={4}
                label="Help Text"
                error={formErrors.helpText}
            />

            {formData.pageIdentifier.startsWith("KPIS") &&
                <InputSwitchComponent
                    id="disableForecasting"
                    checked={formData.disableForecasting}
                    onChange={(value) => inputChanged(value, 'disableForecasting')}
                    label="Disable Forecasting"
                />
            }

            {formData.chartCount > 0 ?
                <>
                    <h6>Chart Help Text</h6>
                    <hr />
                    {[...Array(formData.chartCount)].map((x, i) => {
                        return (
                            <div key={i}>
                                <InputTextComponent
                                    id={"chartTextsTitle_" + i}
                                    value={formData.chartTexts[i].title}
                                    onChange={(value) => inputChangedWithIndex(value, 'chartTexts', i, 'title')}
                                    label={`Chart ${i + 1} Title`}
                                />

                                <InputTextareaComponent
                                    id={"chartTextsText_" + i}
                                    value={formData.chartTexts[i].text}
                                    onChange={(value) => inputChangedWithIndex(value, 'chartTexts', i, 'text')}
                                    rows={4}
                                    label={`Chart ${i + 1} Text`}
                                />

                                <InputSwitchComponent
                                    id={"chartTextsOpen_" + i}
                                    checked={formData.chartTexts[i].openByDefault}
                                    onChange={(value) => inputChangedWithIndex(value, 'chartTexts', i, 'openByDefault')}
                                    label={`Chart ${i + 1} Open`}
                                />
                            </div>
                        );
                    })}
                </>
                : <></>
            }
            <div className="field grid">
                <div className="col-fixed" style={{ width: '150px' }}></div>
                <div className="input" style={{ flex: '1' }}>
                    <Button type="submit" label="Submit" className="mt-2 mb-2 btn-custom" style={{ width: '150px', float: 'right' }} />
                </div>
            </div>
        </form>
    );
};

export default ModifyPageForm;