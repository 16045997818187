import React from 'react';
import { Password } from 'primereact/password';

function PasswordComponent({ id, value, onChange, passwordRequired, formErrors }) {
    return (
        <div className="field grid">
            <label htmlFor={id} className={passwordRequired ? "col-fixed mb-0 required" : "col-fixed mb-0"} style={{ width: '150px' }}>{id}</label>
            <div className="input" style={{ flex: '1' }}>
                <Password
                    id={id}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    aria-describedby={`${id}-help`}
                    autoComplete="new-password"
                    toggleMask
                    className={formErrors[id] ? "p-invalid block" : "block"}
                />
                {formErrors[id] && <small id={`${id}-help`} className="p-error block">{formErrors[id]}</small>}
            </div>
        </div>
    );
}

export default PasswordComponent;