import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AuthContext } from '../../service/AuthService';

const AdminRoute = ({ children, ...rest }) => {
    const { isAuthenticated, privLevel } = useContext(AuthContext);

    return (
        <Route
            {...rest}
            render={() =>
                isAuthenticated && privLevel == "GLOBAL_ADMIN" ? (
                    children
                ) : (
                        <Redirect to="/" />
                )
            }
        />
    );
};

export default AdminRoute;