import React from 'react';
import { Checkbox } from 'primereact/checkbox';

function CheckboxListComponent({ id, items, selectedItems, onChange }) {

    return (
        <div className="field grid">
            <label htmlFor="buttons" className="col-fixed mb-0" style={{ width: '150px' }}>{id}</label>
            <div className="input" style={{ flex: '1' }}>
                {items.map((item) => (
                    <div key={item.id} className="flex align-items-center mb-2">
                        <Checkbox
                            inputId={item.id}
                            name={item.id}
                            value={item.value ?? item.label}
                            checked={selectedItems[item.value ?? item.label]}
                            onChange={(e) => onChange(e)}
                        />
                        <label htmlFor={item.id} className="ml-2">{item.label}</label>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default CheckboxListComponent;