import KpiPage6Col from './KpiPage6Col';

const GainAndLossPage = (props) => {
    const year = 2022; // Example year
    const pageId = 73; // Example page ID


    return (
        <KpiPage6Col year={year} pageId={pageId} pageIdentifier={props.pageIdentifier} />
    );
};

export default GainAndLossPage;