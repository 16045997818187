import React from 'react';
import { InputText } from 'primereact/inputtext';

function InputTextComponent({ id, value, onChange }) {
    return (
        <div className="field grid">
            <label htmlFor={id} className="col-fixed mb-0" style={{ width: '150px' }}>{id}</label>
            <div className="input" style={{ flex: '1' }}>
                <InputText
                    id={id}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    className="p-inputtext p-component"
                />
            </div>
        </div>
    );
}

export default InputTextComponent;