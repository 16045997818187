import React from 'react';
import { ColorPicker } from 'primereact/colorpicker';

function ColorPickerComponent({ id, value, onChange }) {
    return (
        <div className="field grid">
            <label htmlFor={id} className="col-fixed mb-0" style={{ width: '150px' }}>{id}</label>
            <div className="input" style={{ flex: '1' }}>
                <ColorPicker
                    id={id}
                    value={value}
                    onChange={(e) => onChange(e.value)}
                    className="p-inputtext p-component"
                />
            </div>
        </div>
    );
}

export default ColorPickerComponent;