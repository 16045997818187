import React, { useState, useEffect, useRef } from 'react';
import { Chart } from 'primereact/chart';
import { Slider } from 'primereact/slider';
import { SelectButton } from 'primereact/selectbutton';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import AverageValueCustomChart from '../custom-charts/AverageValueCustomChart';

const ChartData = (props) => {
    const [sliderMinValue, setSliderMinValue] = useState([]);
    const [sliderMaxValue, setSliderMaxValue] = useState([]);
    const [sliderValue, setSliderValue] = useState([]);
    const [dialValue, setDialValue] = useState([]);
    const [data, setData] = useState({});
    const [chartAltOption, setChartAltOption] = useState(props.boxes.charts[0].chartName);
    const [showAlt, setShowAlt] = useState(false);
    const [dialogsOpen, setDialogsOpen] = useState([]);

    const container = useRef(null);
    const [options, setOptions] = useState({
        maintainAspectRatio: false,
        aspectRatio: 1,
        animation: {
            animateScale: false
        },
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                display: false
            }
        },
        cutout: '60%'
    });
    const getColour = (dialValue, lower, upper, reverse) => {
        if (reverse) {
            if (dialValue <= upper) {
                return "#82e077";
            } else if (dialValue >= lower) {
                return "#de3e4b";
            }

        } else {
            if (dialValue >= upper) {
                return "#82e077";
            } else if (dialValue <= lower) {
                return "#de3e4b";
            }
        }

        return "#f17f39";
    };


    useEffect(() => {
        var newData = [];
        var sliderData = [];
        var sliderMaxData = [];
        var sliderMinData = [];
        var dialData = [];

        for (var i = 0; i < props.boxes.charts.length; i++) {
            var newDataToAdd = {};
            if (showAlt) {
                if (props.boxes.charts[i].dialValueType == "percentage") {
                    newDataToAdd = {
                        datasets: [
                            {
                                data: [props.boxes.charts[i].altDialValue, Math.max(0, 100 - props.boxes.charts[i].altDialValue)],
                                backgroundColor: [getColour(props.boxes.charts[0].altDialValue, props.boxes.charts[0].altLowerBand, props.boxes.charts[0].altUpperBand, props.boxes.charts[0].negativeKPI), '#CED4DA']
                            }
                        ]
                    };
                }
                else {
                    newDataToAdd = {
                        datasets: [
                            {
                                data: [props.boxes.charts[i].altDialValue, Math.max(0, (props.boxes.charts[i].altMaximumValue ?? 100) - props.boxes.charts[i].altDialValue)],
                                backgroundColor: [getColour(props.boxes.charts[0].altDialValue, props.boxes.charts[0].altLowerBand, props.boxes.charts[0].altUpperBand, props.boxes.charts[0].negativeKPI), '#CED4DA']
                            }
                        ]
                    };
                }

                newData.push(newDataToAdd);
                sliderData.push(props.boxes.charts[i].altActualValue);
                sliderMaxData.push(props.boxes.charts[i].sliderMax ?? props.boxes.charts[i].altMaximumValue);
                sliderMinData.push(props.boxes.charts[i].sliderMin);
                dialData.push(props.boxes.charts[i].altDialValue);
            } else {
                if (props.boxes.charts[i].dialValueType == "percentage") {
                    newDataToAdd = {
                        datasets: [
                            {
                                data: [props.boxes.charts[i].dialValue, Math.max(0, 100 - props.boxes.charts[i].dialValue)],
                                backgroundColor: [getColour(props.boxes.charts[0].dialValue, props.boxes.charts[0].lowerBand, props.boxes.charts[0].upperBand, props.boxes.charts[0].negativeKPI), '#CED4DA']
                            }
                        ]
                    };
                }
                else {
                    newDataToAdd = {
                        datasets: [
                            {
                                data: [props.boxes.charts[i].dialValue, Math.max(0, (props.boxes.charts[i].sliderMax ?? 100) - props.boxes.charts[i].dialValue)],
                                backgroundColor: [getColour(props.boxes.charts[0].dialValue, props.boxes.charts[0].lowerBand, props.boxes.charts[0].upperBand, props.boxes.charts[0].negativeKPI), '#CED4DA']
                            }
                        ]
                    };
                }

                newData.push(newDataToAdd);
                sliderData.push(props.boxes.charts[i].actualValue);
                sliderMaxData.push(props.boxes.charts[i].sliderMax ?? props.boxes.charts[i].maximumValue);
                sliderMinData.push(props.boxes.charts[i].sliderMin);
                dialData.push(props.boxes.charts[i].dialValue);
            }
        }

        setData(newData);
        setSliderValue(sliderData);
        setSliderMaxValue(sliderMaxData);
        setSliderMinValue(sliderMinData);
        setDialValue(dialData);


    }, [props.boxes, showAlt]);


    function getFormattedTopValue(v) {
        return v != null ? (v > 0 ? "+" : "") + `${v.toFixed(0)}%` : '';
    }

    function getFormattedValue(v, type, dp) {
        if (!dp) {
            dp = 0;
        }
        switch (type) {
            case 'percentage':
                return v !== null && !isNaN(v) ? `${v.toLocaleString('en-GB', { maximumFractionDigits: dp })}%` : '';
            case 'currency':
                return v !== null && !isNaN(v) ? `£${v.toLocaleString('en-GB', { maximumFractionDigits: dp })}` : '';
            default:
                return v !== null && !isNaN(v) ? `${v.toLocaleString('en-GB', { maximumFractionDigits: dp })}` : '';

        }
    }

    function getTopValue(chart) {

        if (showAlt) {
            switch (props.buttonOptions) {
                case "IC":
                    return getFormattedTopValue(chart.comparison.altIndustryComparisonValue);
            }
            return getFormattedTopValue(chart.comparison.altAnnualComparisonValue);
        }

        switch (props.buttonOptions) {
            case "IC":
                return getFormattedTopValue(chart.comparison.industryComparisonValue);
        }
        return getFormattedTopValue(chart.comparison.annualComparisonValue);
    }

    function getTopValueColour(chart) {

        if (showAlt) {
            switch (props.buttonOptions) {
                case "IC":
                    return chart.comparison.altIndustryComparisonColour;
            }
            return chart.comparison.altAnnualComparisonColour;
        }
        switch (props.buttonOptions) {
            case "IC":
                return chart.comparison.industryComparisonColour;
        }
        return chart.comparison.annualComparisonColour;
    }
    function handleSlideChange(value, chart) {
        var maximum = chart.maximumValue;
        var newOptionsData = { ...options };
        newOptionsData.animation = false;
        setOptions(newOptionsData);

        var newSliderData = [...sliderValue];
        newSliderData[0] = value;
        if (newSliderData.length > 1) {
            newSliderData[1] = sliderMaxValue[0] - value;
        }

        props.updateForecast(chart.id, value);

        var newDialData = [...dialValue];

        if (maximum) {
            newDialData[0] = (value / maximum) * 100;
            if (newDialData.length > 1) {
                newDialData[1] = 100 - (value / maximum) * 100;
            }
        }
        else {
            newDialData[0] = value;
        }


        var newDataData = { ...data };

        newDataData[0].datasets[0].data[0] = newDialData[0];
        if (chart.dialValueType == "percentage") {
            newDataData[0].datasets[0].data[1] = Math.max(0, 100 - newDialData[0]);
        } else {
            newDataData[0].datasets[0].data[1] = Math.max(0, (chart.sliderMax ?? 100) - newDialData[0]);

        }
        newDataData[0].datasets[0].backgroundColor = [getColour(newDialData[0], chart.lowerBand, chart.upperBand, chart.negativeKPI), '#CED4DA'];

        if (newDialData.length > 1) {
            newDataData[1].datasets[0].data[0] = newDialData[1];
            newDataData[1].datasets[0].data[1] = 100 - newDialData[1];
            newDataData[1].datasets[0].backgroundColor = [getColour(newDialData[0], chart.lowerBand, chart.upperBand, chart.negativeKPI), '#CED4DA'];
        }
        setData(newDataData);
        setSliderValue(newSliderData);
        setDialValue(newDialData);
    }

    const footerContent = (
        <div>
            <Button label="Dismiss" icon="pi pi-tick" className="btn-custom" onClick={() => setDialogsOpen([])} />
        </div>
    );

    function enterMouseHandler(event) {
        const elements = document.querySelectorAll('.tooltip-visible');

        if (event.target.parentNode.classList.contains("tooltip-visible")) {
            elements.forEach((element) => {
                element.classList.remove('tooltip-visible');
            });
        }
        else {
            elements.forEach((element) => {
                element.classList.remove('tooltip-visible');
            });
            event.target.parentNode.classList.add("tooltip-visible");
        }
    }

    function leaveMouseHandler(event) {
        event.target.parentNode.classList.remove("tooltip-visible");
    }

    if (props.boxes.charts[0].id == 4 && !props.isDashboard) {
        return <></>;
    }

    return (
        <>
            <div className={(props.boxes.charts.length !== 1 ? "custom-col large" : "custom-col") + " w-" + props.width}>
                <div className="card" style={{
                    border: props.boxes.charts[0].id <= 5 && !props.isDashboard ? "solid 2px #5e56c4" : ""
                }}>
                    <div className="flex justify-content-center">
                        <div className="inr-card-wrp">
                            <div className="inr-card-wrp-top">
                                {

                                    props.boxes.charts.map((item, index) => (

                                        <div className={props.boxes.charts.length !== 1 ? "inr-card" : "inr-card normal"} key={index}>
                                            {item.altEnabled ? <>
                                                <SelectButton options={[item.chartName, item.altChartName]} value={chartAltOption} onChange={(e) => { setChartAltOption(e.value ?? item.chartName); setShowAlt(e.value == item.altChartName) }} />
                                                <br />
                                            </> : <></>}
                                            <div className="inr-card-top">
                                                <div>
                                                    <span className="help-icon" onMouseEnter={(e) => enterMouseHandler(e)} onMouseLeave={(e) => leaveMouseHandler(e)}>
                                                        <i className="pi pi-question-circle icon-color-custom"></i>
                                                    </span>
                                                    <div className="tooltip-data-chart">
                                                        <p>{showAlt ? item.altTooltip : item.tooltip}</p>
                                                    </div>
                                                </div>
                                                {(((item.comparison.industryComparisonValue < 0 && !item.negativeKPI) || (item.comparison.industryComparisonValue > 0 && item.negativeKPI)) && !showAlt) || (item.comparison.altIndustryComparisonValue < 0 && showAlt) ?
                                                    <div>
                                                        <span className="help-icon-insight" onClick={() => setDialogsOpen([index + "insight"])}>
                                                            <span className=" pi pi-bell"><span className="dot"></span></span>
                                                        </span>
                                                        <Dialog header="Insights" visible={dialogsOpen.includes(index + "insight")} style={{ width: '20vw', minWidth: '200px' }} onHide={() => setDialogsOpen([])} footer={footerContent}>
                                                            <p>{showAlt ? item.altInsight : item.insight}</p>
                                                        </Dialog>
                                                    </div> : <></>}
                                                <span className={"value " + getTopValueColour(item)}>
                                                    {getTopValue(item) + " "}
                                                    {
                                                        getTopValueColour(item) == "green" ? 
                                                        <i className="pi pi-arrow-circle-up"></i>
                                                            : getTopValueColour(item) == "red" ?
                                                        <i className="pi pi-arrow-circle-down"></i>
                                                            : ""
                                                    }
                                                </span>
                                            </div>
                                            <div className="inr-card-chart">
                                                <span className="value-percentage">{getFormattedValue(dialValue[index], showAlt ? item.altDialValueType : item.dialValueType, showAlt ? item.altDialValueDP : item.dialValueDP)}</span>

                                                <div className="chart-container-custom" style={{ maxHeight: "150px" }} ref={container}>
                                                    {data !== null ?
                                                        (
                                                            /*Average Value, custom chart*/
                                                            item.id == 4 ? <>
                                                                <AverageValueCustomChart year={props.year} options={options} />
                                                            </> :
                                                                <>
                                                                    <Chart style={{ position: 'relative', width: '95%', maxWidth: "150px", height: "100%" }} options={options} type="doughnut" data={data[index]} />
                                                                    {props.isForecasting ?
                                                                        <div className="fake-line-container">
                                                                            <span className="chart-line-custom" style={{
                                                                                '--rot': Math.round((Math.round(item.actualValue) / (item.sliderMax ?? item.maximumValue)) * 360) + "deg",
                                                                                '--container-size': container.current ? ((container.current.clientWidth - 11) / 2) + "px" : 0
                                                                            }}></span>
                                                                        </div>
                                                                        : <></>}
                                                                </>
                                                        )
                                                        : ""}
                                                </div>

                                            </div>
                                            <h4><strong>{item.title}</strong></h4>
                                            <span className="value-final">{getFormattedValue(sliderValue[index], showAlt ? item.altActualValueType : item.actualValueType, showAlt ? item.altActualValueDP : item.actualValueDP)}{(props.isDashboard ? (showAlt ? item.altActualValueFormatDashboard : item.actualValueFormatDashboard) : (showAlt ? item.altActualValueFormat : item.actualValueFormat)) && " " + (props.isDashboard ? (showAlt ? item.altActualValueFormatDashboard : item.actualValueFormatDashboard) : (showAlt ? item.altActualValueFormat : item.actualValueFormat)) + " " + getFormattedValue(showAlt ? item.altMaximumValue : item.maximumValue, showAlt ? item.altActualValueType : item.actualValueType, showAlt ? item.altActualValueDP : item.actualValueDP)}</span>
                                            <div className="icon-boxes">
                                                {((!showAlt && item.actualValue2 != null) || (showAlt && item.altActualValue2 != null)) ?
                                                    <div>
                                                        <i className={"pi " + (showAlt ? item.altSubKPI1Icon : item.subKPI1Icon)}></i>
                                                        {((!showAlt && item.actualValue2 != null) || (showAlt && item.altActualValue2 != null)) ? getFormattedValue(showAlt ? item.altActualValue2 : item.actualValue2, showAlt ? item.altActualValueType : item.actualValueType, showAlt ? item.altActualValueDP : item.actualValueDP) : ""}
                                                    </div> : <></>}
                                                {((!showAlt && item.actualValue3 != null) || (showAlt && item.altActualValue3 != null)) ?
                                                    <div>
                                                        <i className={"pi " + (showAlt ? item.altSubKPI2Icon : item.subKPI2Icon)}></i>
                                                        {((!showAlt && item.actualValue3 != null) || (showAlt && item.altActualValue3 != null)) ? getFormattedValue(showAlt ? item.altActualValue3 : item.actualValue3, showAlt ? item.altActualValueType : item.actualValueType, showAlt ? item.altActualValueDP : item.actualValueDP) : ""}
                                                    </div> : <></>}
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    sliderValue != null && props.boxes.charts[0].addSlider && !showAlt && props.isDashboard ?
                                        <Slider value={sliderValue[0]} max={sliderMaxValue[0]} min={sliderMinValue[0]} onChange={(e) => handleSlideChange(e.value, props.boxes.charts[0])} />
                                        : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChartData;