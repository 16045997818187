import React, { useState, useEffect } from 'react';

import { Button } from 'primereact/button';

import { Fieldset } from 'primereact/fieldset';

import { Chart } from 'primereact/chart';

import ApiService from '../../../service/ApiService';

import { Sidebar } from 'primereact/sidebar';
import { useCookies } from 'react-cookie';

const AcquisitionVsAttritionPage = (props) => {

    const [title, setTitle] = useState(null);
    const [lineData, setMainGraph] = useState(null);
    const [lineOption, setGraphOptions] = useState(null);
    const [difference, setDifference] = useState("");
    const apiService = new ApiService();

    const [barData, setBarGraph] = useState(null);
    const [barOption, setBarOptions] = useState(null);

    const [ClientDataId] = useCookies(['ClientDataId']);

    const [showHelpPane, setShowHelpPane] = useState(false);
    const [pageProperties, setPageProperties] = useState({
        disableForecasting: true,
        helpText: '',
        chartTexts: []
    });
    const renderHTML = (rawHTML) => React.createElement("p", { dangerouslySetInnerHTML: { __html: rawHTML } });


    useEffect(() => {
        apiService.getPageDataProperties(props.pageIdentifier).then((data) => setPageProperties(data));
    }, [props.pageIdentifier]);

    const getTrendLineData = (data) => {

        // Calculate the sum of x and y values
        const sumX = data.reduce((sum, _, index) => sum + index, 0);
        const sumY = data.reduce((sum, value) => sum + value, 0);

        // Calculate the mean of x and y values
        const meanX = sumX / data.length;
        const meanY = sumY / data.length;

        // Calculate the differences from the mean
        const diffX = data.map((_, index) => index - meanX);
        const diffY = data.map(value => value - meanY);

        // Calculate the slope (m)
        const numerator = diffX.reduce((sum, value, index) => sum + value * diffY[index], 0);
        const denominator = diffX.reduce((sum, value) => sum + value * value, 0);
        const slope = numerator / denominator;

        // Calculate the y-intercept (b)
        const intercept = meanY - slope * meanX;

        return data.map((_, index) => slope * index + intercept);
    };

    const createDataSet = (items, col1, items2, col2) => {
        var labels = [];
        var data = [];
        var data2 = [];
        for (var i = 0; i < items.length; i++) {
            labels.push(items[i].text);
            data.push(items[i].value);
            data2.push(items2[i].value);
        }
        /*
        var trendLine = {};
        if (!props.hideTrendline) {
            trendLine = {
                label: "Trend Line",
                data: getTrendLineData(data),
                fill: false,
                backgroundColor: "#fff",
                borderColor: "#455ED1",
                borderDash: [3, 3],
                tension: 0
            };
        }*/

        return {
            labels: labels,
            datasets: [{
                data: data,
                borderColor: col1,
                label: 'Acquisition',
                tension: .4
            },
            {
                data: data2,
                borderColor: col2,
                label: 'Attrition',
                tension: .4
            }]
        };
    };

    const createBarDataSet = (items, col1, items2, col2) => {

        var avg1 = 0;
        var c = 0;
        for (var i = 0; i < items.length; i++) {
            avg1 += items[i].value;
            c++;
        }
        avg1 /= c;

        var avg2 = 0;
        c = 0;
        for (var i = 0; i < items2.length; i++) {

            avg2 += items2[i].value;
            c++;
        }
        avg2 /= c;

        var diff = Math.round(avg1 - avg2);
        setDifference((diff > 0 ? "+" : "") + diff + "%");

        return {
            labels: ["Acquisition", "Attrition"],
            datasets: [
                {
                    backgroundColor: [col1, col2],
                    data: [avg1, avg2]
                }
            ]
        };
    };

    function hexToRgbA(hex, alpha) {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
        }
        throw new Error('Bad Hex');
    }
    useEffect(async () => {
        setGraphOptions({
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            borderWidth: 4,
            pointRadius: 5,
            hoverRadius: 9,
            pointBackgroundColor: (context) => {
                return context.dataset.borderColor;
            },
            backgroundColor: (context) => {
                const ctx = context.chart.ctx;
                const gradient = ctx.createLinearGradient(0, 0, 0, 500);
                //gradient.addColorStop(0, hexToRgbA(context.dataset.borderColor, 0.8));
                //gradient.addColorStop(1, hexToRgbA(context.dataset.borderColor, 0));
                gradient.addColorStop(0, hexToRgbA("#fff", 0));
                gradient.addColorStop(1, hexToRgbA("#fff", 0));
                return gradient;
            },
            fill: true,
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (context) {

                            let label = "" + context.dataset.label || '';

                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed.y !== null) {
                                label += context.parsed.y.toLocaleString('en-GB', { maximumFractionDigits: 2 }) + '%';
                            }
                            return label;
                        }
                    }
                }
            },
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        callback: function (label, index, labels) {
                            return label.toFixed(0) + '%';
                        }
                    }
                }
            }
        });
        setBarOptions({
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (context) {

                            let label = "" + context.label || '';

                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed !== null) {
                                label += context.parsed.toLocaleString('en-GB', { maximumFractionDigits: 2 }) + '%';
                            }
                            return label;
                        }
                    }
                }
            }
        });

        var apiService = new ApiService();

        apiService.getTrendData(ClientDataId, 1).then(tdAq => {
            apiService.getTrendData(ClientDataId, 3).then(tdAtt => {
                setMainGraph(createDataSet(tdAq.items, tdAq.lineColour ?? "#B2BEB5", tdAtt.items, tdAtt.lineColour ?? "#B2BEB5"));
                setBarGraph(createBarDataSet(tdAq.items, tdAq.lineColour ?? "#B2BEB5", tdAtt.items, tdAtt.lineColour ?? "#B2BEB5"));
            });
        });

        setTitle("Acquisition Vs Attrition");

    }, []);

    return (
        <>
            {/*header*/}
            <div className="col-12">
                <div className="grid p-fluid grid-row-header">
                    <div className="card">
                        <h3>{title}</h3>

                        <div className="rhs-row-header">
                            {pageProperties != null && pageProperties.helpText != '' && pageProperties.helpText != null &&
                                <Button label="Help" className="p-button-outlined mr-2 mb-2" onClick={() => setShowHelpPane(true)} />
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/*row data*/}
            <div className="grid">
                <div className="col-6">
                    {pageProperties != null && pageProperties.chartTexts != null && pageProperties.chartTexts.length > 0 ?
                        <Fieldset legend={pageProperties.chartTexts[0].title} toggleable collapsed={!pageProperties.chartTexts[0].openByDefault}>
                            {renderHTML(pageProperties.chartTexts[0].text)}
                        </Fieldset>
                        : <></>}
                </div>
            </div>
            <div className="col-6">
                {lineData ?
                    <div className="grid p-fluid data-graphic">
                        <div className="card">
                            <h4><strong>Acquisition Vs Attrition</strong></h4>
                            <Chart type="line" options={lineOption} data={lineData} />
                        </div>
                    </div> : <></>}
            </div>

            <div className="grid">
                <div className="col-6">
                    {pageProperties != null && pageProperties.chartTexts != null && pageProperties.chartTexts.length > 1 ?
                        <Fieldset legend={pageProperties.chartTexts[1].title} toggleable collapsed={!pageProperties.chartTexts[1].openByDefault}>
                            {renderHTML(pageProperties.chartTexts[1].text)}
                        </Fieldset>
                        : <></>}
                </div>
            </div>

            <div className="col-6">
                {barData ?
                    <div className="grid p-fluid data-graphic">
                        <div className="card">
                            <h4><strong>Acquisition Vs Attrition Totals</strong></h4>
                            <div className="inr-card-chart">
                                <span className="value-percentage" style={{ marginTop: "18px", fontSize: "40px" }}>{difference}</span>
                                <Chart type="doughnut" options={barOption} data={barData} />

                            </div>
                        </div>
                    </div>
                    : <></>}
            </div>
            <Sidebar visible={showHelpPane} position="right" onHide={() => setShowHelpPane(false)} className="p-sidebar-md">
                <h2>Help</h2>
                {renderHTML(pageProperties.helpText)}
            </Sidebar>
        </>
    );
}

export default AcquisitionVsAttritionPage;